import React from 'react';

import styled from '@emotion/styled';

import { IFacilityViewerContainer } from './FacilityViewer.types';

import { Colors, Typography } from '@/shared/styles';

export const FacilityViewerContainer = styled.div<IFacilityViewerContainer>`
  display: flex;
  width: 100%;
  gap: 48px 16px;
  flex-wrap: wrap;
  padding: ${(props) => (props.iconLength > 4 ? '64px 32px' : '40px 0 0 0')};
`;

export const FacilityIconItem = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-basis: calc(25% - 13px);
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  & > svg {
    cursor: pointer;
  }
  & > span {
    ${Typography.body_26};
    color: ${Colors.gray9};
    text-align: center;
    word-break: keep-all;
  }
`;
