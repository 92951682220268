import React, { useEffect, useId, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import Address from '@/components/Address/Address';
import BusinessHours from '@/components/BusinessHours/BusinessHours';
import Button from '@/components/Button/Button';
import CarouselViewer from '@/components/CarouselViewer/CarouselViewer';
import { useStateCarouselViewer } from '@/components/CarouselViewer/CarouselViewer.hooks';
import CheckBox from '@/components/CheckBox/CheckBox';
import DepositInfo from '@/components/DepositInfo/DepositInfo';
import { useQueryChangeUserTerms, useQueryUserTerms } from '@/components/EditProfile/AgreeTerms/AgreeTerms.hooks';
import ExpressInfo from '@/components/ExpressInfo/ExpressInfo';
import Facility from '@/components/Facility/Facility';
import FacilityViewer from '@/components/FacilityViewer/FacilityViewer';
import { useStateFacilityViewer } from '@/components/FacilityViewer/FacilityViewer.hooks';
import { TIcon } from '@/components/FacilityViewer/FacilityViewer.types';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel';
import { ImageMainCarouselItem } from '@/components/ImageCarousel/ImageCarousel.styles';
import { ICarouselItem } from '@/components/ImageCarousel/ImageCarousel.types';
import MallDescription from '@/components/MallDescription/MallDescription';
import MallTitle from '@/components/MallTitle/MallTitle';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import MenuList from '@/components/MenuList/MenuList';
import MenuListViewer from '@/components/MenuListViewer/MenuListViewer';
import { useStateMenuListViewer } from '@/components/MenuListViewer/MenuListViewer.hooks';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Notice from '@/components/Notice/Notice';
import Seperator from '@/components/Seperator/Seperator';
import ToastPortal from '@/components/ToastPortal/ToastPortal';
import { useMutationUpdateMallNotification, useQueryFetchMallNotification } from '@/hooks/mallNotification.hook';
import useQueryMallUri from '@/hooks/storeUri.hook';
import { LocalStorage } from '@/shared/configs/storage';
import { DetailAdditionalInformation, DetailContainer } from '@/shared/styles/pages/detail';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { MallMenu, useBookingStore } from '@/stores/useBookingStore';
import { useInfoStore } from '@/stores/useInfoStore';

interface IAddressData {
  latitude: number;
  longitude: number;
  address: string;
  pastAddress: string;
  phone: string;
}

const Detail = (): React.ReactElement => {
  const headerState = useHeaderStore((store) => store.headerState);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const [mall, setMall, setStoreUri] = useBookingStore((store) => [store.mall, store.setMall, store.setStoreUri]);
  const { storeInformation } = useBookingStore().mall;
  const { setAlertModal, toastList, setToastList, setOpenGlobalErrorModal, setOpenGlobalConfirmModal } = useModalStore();
  const alarmOnModalKey = useId();
  const alarmOffModalKey = useId();
  const adAgreementKey = useId();
  const { storeAddress } = useBookingStore().mall;
  const [addressData, setAddressData] = useState({});
  const { dispatchBookingInfo } = useBookingInfoStore();
  const { clear } = useInfoStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isCheckedLateNightMarketing, setIsCheckedLateNightMarketing] = useState(false);

  const [isFacilityViewAll, setIsFacilityViewAll] = useStateFacilityViewer(navigate);
  const [isCarouselViewAll, setIsCarouselViewAll] = useStateCarouselViewer(navigate);
  const [isMenuListViewAll, setIsMenuListViewAll] = useStateMenuListViewer(navigate);

  const { data, isLoading, isFetching, isError, isSuccess } = useQueryMallUri();
  const { data: notificationData, isLoading: notificaitonLoading, refetch: notificationRefetch } = useQueryFetchMallNotification('02');
  const _ = useUserMembership();

  const {
    data: notificationUpdateData,
    isLoading: notificationUpdateLoading,
    mutate,
    isSuccess: notificationUpdateSuccess,
  } = useMutationUpdateMallNotification('02', !headerState.alarmOn);
  const { mutate: changeUserTerms } = useQueryChangeUserTerms();
  const { data: userMarketingTerms } = useQueryUserTerms('MARKETING');

  const carouselItems: ICarouselItem[] = useMemo(() => {
    return mall.storeImages.map((item, index) => ({
      key: `carousel-${index}`,
      name: `carousel-${index}`,
      render: () => <ImageMainCarouselItem background={item}></ImageMainCarouselItem>,
    }));
  }, [mall.storeImages]);

  const facilityItems: TIcon[] = useMemo(() => {
    return mall.storeFacilities;
  }, [mall.storeFacilities]);

  const menuItems: MallMenu[] = useMemo(() => {
    return mall.storeMenus;
  }, [mall.storeMenus]);

  function setMallAlarm() {
    if (LocalStorage.getItem('loginType') === 'none') {
      setOpenGlobalConfirmModal({
        visible: true,
        title: '알림',
        message: '로그인 해야 사용할 수 있는 기능입니다. 에그에 로그인하시겠어요?',
        closeText: '닫기',
        okayText: '로그인',
        callback: () => {
          navigate('/auth/signin');
        },
      });
    } else {
      setAlertModal({ visible: true, key: alarmOnModalKey });
    }
  }

  function setMallAlarmOff() {
    if (LocalStorage.getItem('loginType') === 'none') {
      setOpenGlobalConfirmModal({
        visible: true,
        title: '알림',
        message: '로그인 해야 사용할 수 있는 기능입니다. 에그에 로그인하시겠어요?',
        closeText: '닫기',
        okayText: '로그인',
        callback: () => {
          navigate('/auth/signin');
        },
      });
    } else {
      setAlertModal({ visible: true, key: alarmOffModalKey });
    }
  }

  function onAlarm() {
    if (userMarketingTerms?.response.length && userMarketingTerms?.response[0].agreement) {
      // 광고성 수신 동의한 상태일때
      mutate();
      setToastList([...toastList, { visible: true, key: 'reservationOpenAlarm' }]);
      setAlertModal({ visible: false, key: alarmOnModalKey });
    } else {
      // 광고성 수신 철회한 상태일때 (혹은 처음 가입할 때 광고성 수신 동의를 하지 않아서 데이터가 없는 경우)
      setAlertModal({ visible: true, key: adAgreementKey });
    }
  }

  function offAlarm() {
    mutate();
    setAlertModal({ visible: false, key: alarmOffModalKey });
    setToastList([...toastList, { visible: true, key: 'reservationCloseAlarm' }]);
  }

  const moveToReservation = () => {
    if (LocalStorage.getItem('loginType') === 'none') {
      setOpenGlobalConfirmModal({
        visible: true,
        title: '알림',
        message: '로그인 해야 사용할 수 있는 기능입니다. 에그에 로그인하시겠어요?',
        closeText: '닫기',
        okayText: '로그인',
        callback: () => {
          navigate('/auth/signin');
        },
      });
    } else {
      navigate(`/reservation/${id}/step/info`);
    }
  };

  const makeFooterText = () => {
    if (mall.storeState === '09') {
      return '예약마감';
    }
    if (mall.storeState === '01') {
      return '오픈예정';
    }
    return '예약하기';
  };

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setStoreUri(id);
    localStorage.setItem('storeUri', id);
    dispatchBookingInfo({ type: 'CLEAR' });
    clear();
  }, [id]);

  useEffect(() => {
    if (data) {
      data.store.storeMembershipPeriod.current.closeDateTime = dayjs(data.store.storeMembershipPeriod.current.closeDateTime);
      data.store.storeMembershipPeriod.current.openDateTime = data.store.storeMembershipPeriod.current.openDateTime && dayjs(data.store.storeMembershipPeriod.current.openDateTime);
      data.store.storeMembershipPeriod.current.commonOpenTime =
        data.store.storeMembershipPeriod.current.commonOpenTime && dayjs(data.store.storeMembershipPeriod.current.commonOpenTime);
      data.store.storeMembershipPeriod.current.reserveOpenDateTime = dayjs(data.store.storeMembershipPeriod.current.reserveOpenDateTime);
      data.store.storeMembershipPeriod.current.reserveCloseDateTime = dayjs(data.store.storeMembershipPeriod.current.reserveCloseDateTime);
      setMall(data.store);
    }
    if (isError) {
      setOpenGlobalErrorModal({ visible: true, message: '오류가 발생하였습니다. 다시 시도해주세요.', title: '알림', closeText: '닫기', callback: () => navigate(-1) });
    }
  }, [data, isError]);

  useEffect(() => {
    if (!isFacilityViewAll && !isCarouselViewAll && !isMenuListViewAll) {
      setHeaderState({
        title: mall.storeName,
        handleGoToBack: () => navigate('/main'),
        handleGoToHome: () => navigate('/main'),
        handleOnAlarm: () => setMallAlarm(),
        handleOnAlarmOff: () => setMallAlarmOff(),
        handleOnClose: null,
        handleOnShare: null,
        alarmOn: notificationData?.isApplyNotification ?? false,
      });
    }
  }, [isFacilityViewAll, isCarouselViewAll, isMenuListViewAll, notificationData, mall]);

  useEffect(() => {
    if (notificationUpdateSuccess) {
      notificationRefetch();
    }
  }, [notificationUpdateSuccess]);

  useEffect(() => {
    const address: IAddressData = {
      latitude: storeAddress.lat,
      longitude: storeAddress.lng,
      address: storeAddress.road,
      pastAddress: storeAddress.jibun,
      phone: mall.storeContact,
    };
    setAddressData(address);
    setHeaderState({
      title: mall.storeName,
      handleGoToBack: () => navigate('/main'),
      handleGoToHome: () => navigate('/main'),
      handleOnAlarm: () => setMallAlarm(),
      handleOnAlarmOff: () => setMallAlarmOff(),
      handleOnClose: null,
      handleOnShare: null,
      alarmOn: notificationData?.isApplyNotification,
    });
  }, [mall]);

  useEffect(() => {
    return () => {
      setToastList([]);
    };
  }, []);

  if (!mall || isLoading) {
    return null;
  }
  if (isLoading && isError) {
    return null;
  }
  if (isCarouselViewAll) {
    return <CarouselViewer items={carouselItems} />;
  }
  if (isFacilityViewAll) {
    return <FacilityViewer icons={facilityItems} />;
  }
  if (isMenuListViewAll) {
    return <MenuListViewer menus={menuItems} type="viewer" />;
  }

  return (
    <>
      <DetailContainer>
        <ImageCarousel
          onClickItem={() => setIsCarouselViewAll(!isCarouselViewAll)}
          infiniteLoop={true}
          autoPlay={false}
          interval={3000}
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          swipeable={true}
          emulateTouch={true}
          items={carouselItems}
        />
        <MallTitle />
        {Boolean(mall.storeUsingGuide) && (
          <>
            <Seperator type="line" inline={true} />
            <Notice />
          </>
        )}
        <Seperator />
        <ExpressInfo />
        <DetailAdditionalInformation>
          {Boolean(facilityItems.length) && <Facility icons={facilityItems} setViewAll={() => setIsFacilityViewAll(!isFacilityViewAll)} />}
          <Address store={addressData} />
          <BusinessHours />
          {Boolean(menuItems.length) && <MenuList menus={menuItems} setViewAll={() => setIsMenuListViewAll(!isMenuListViewAll)} />}
          {data?.store?.storeHasDeposit ? <DepositInfo /> : null}
          {Boolean(storeInformation) && <MallDescription />}
        </DetailAdditionalInformation>
      </DetailContainer>
      <Button colorLevel="primary" type="sticky" onClick={() => moveToReservation()} disabled={mall.storeState && mall.storeState !== '05'}>
        {makeFooterText()}
      </Button>

      <ModalPortal>
        <Modal.Alert key={alarmOnModalKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">{mall.storeName}</h2>
            <p className="desc">일반 예약, 익스프레스 예약이 열리는 시간에 푸시 알림을 수신합니다.</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: alarmOnModalKey })}>아니요</button>
            <button
              onClick={() => {
                onAlarm();
              }}
            >
              알림 받기
            </button>
          </section>
        </Modal.Alert>
        <Modal.Alert key={alarmOffModalKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">{mall.storeName}</h2>
            <p className="desc">알림을 정말 해제할까요? 예약이 열려도 푸시 알림이 가지 않습니다.</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: alarmOffModalKey })}>아니요</button>
            <button
              onClick={() => {
                offAlarm();
              }}
            >
              알림 해제
            </button>
          </section>
        </Modal.Alert>
        <Modal.Alert key={adAgreementKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">광고성 정보 알림 수신 동의</h2>
            <section className="desc">
              광고성 정보 알림 수신에 동의하고 레스토랑 알림을 받으시겠어요?
              <div className="desc-in-padding">
                <CheckBox
                  onChange={() => {
                    setIsCheckedLateNightMarketing((prev) => !prev);
                  }}
                  alignType="fit"
                  checkType="small"
                >
                  <div>21시~08시 사이에도 알림받기</div>
                </CheckBox>
              </div>
            </section>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: adAgreementKey })}>취소</button>
            <button
              onClick={() => {
                changeUserTerms({
                  servicePolicyType: 'MARKETING',
                  agreement: true,
                });
                if (isCheckedLateNightMarketing) {
                  // 늦은시간 약관 동의 체크
                  changeUserTerms({
                    servicePolicyType: 'LATE_NIGHT_MARKETING',
                    agreement: true,
                  });
                }
                mutate();
                setAlertModal({ visible: false, key: adAgreementKey });
                setToastList([...toastList, { visible: true, key: 'marketingInfo' }, { visible: true, key: 'reservationOpenAlarm' }]);
              }}
            >
              동의
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
      <ToastPortal>
        <Modal.Toast key="marketingInfo" toastKey="marketingInfo" modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <div>광고성 정보 알림 수신 동의({dayjs().format('YYYY.MM.DD')})</div>
        </Modal.Toast>
        <Modal.Toast key="reservationOpenAlarm" toastKey="reservationOpenAlarm" modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <div>예약 오픈 알림을 받습니다.</div>
        </Modal.Toast>
        <Modal.Toast key="reservationCloseAlarm" toastKey="reservationCloseAlarm" modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <div>예약 오픈 알림을 받지 않습니다.</div>
        </Modal.Toast>
      </ToastPortal>
    </>
  );
};

export default Detail;
