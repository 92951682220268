// import './UserInfo.scss';
import React from 'react';

import { UserInfo as UserInfoEmotion } from './UserInfo.constants';

import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import { hypenPhoneNum } from '@/shared/libs/function/auto-hypen';
//import classnames from 'classnames';

const UserInfo = ({ reservation, visitor }): React.ReactElement => {
  return (
    <UserInfoEmotion>
      <MainSubtitle subtitle="예약자 정보" />
      <div className="info-box">
        <div className="label">예약자</div>
        <div className="value">{visitor.name}</div>
      </div>
      <div className="info-box">
        <div className="label">연락처</div>
        <div className="value">{hypenPhoneNum(visitor.phone, 'hide')}</div>
      </div>
      <div className="info-box">
        <div className="label">요청사항</div>
        <div className="value">{reservation.request?.length ? reservation.request : '없음'}</div>
      </div>
    </UserInfoEmotion>
  );
};

export default UserInfo;
