import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { useMembershipSubscriptionPrices } from '../ChooseSubscription/ChooseSubscription.hooks';
import { useUserMembership } from './ManageMembership.hooks';

import { ReactComponent as Arrow } from '@/assets/img/icon/arrowRightGray.svg';
import { ReactComponent as BenefitExpress } from '@/assets/img/icon/benefit_express.svg';
import { ReactComponent as MembershipBadge } from '@/assets/img/icon/membership-badge.svg';
import { ManageMembershipContainer } from '@/components/ManageMembership/ManageMembership.styles';
import { useFetchCardData } from '@/hooks/useFetchCardData';
import { AddComma } from '@/shared/libs/functions';

const ManageMembership = (): React.ReactElement => {
  const { data: userMembership, isLoading: userMembershipLoading } = useUserMembership();
  const navigate = useNavigate();
  const { data: membershipPriceData, isLoading: membershipPriceLoading } = useMembershipSubscriptionPrices();
  const { data: paymentCardData, isLoading: fetchCardLoading } = useFetchCardData(true);

  const viewMoreBenefitDetail = () => {
    navigate('/membership/entry?member=2', { state: { prevPath: location.pathname } });
  };

  const findMembership = useMemo(() => {
    if (userMembership && membershipPriceData) {
      return membershipPriceData.prices.find((member) => member.subscriptionType === userMembership.membership.subscriptionType);
    }
  }, [membershipPriceData, userMembership]);

  if (userMembershipLoading || fetchCardLoading || membershipPriceLoading) {
    return null;
  }

  return (
    <ManageMembershipContainer isYolkMember={userMembership.membership.membershipGrade === '02'}>
      <div className="membership-header">
        <div className="badge">
          <MembershipBadge width="160" height="160" />
        </div>
        <div className="comment">
          <div className="title">YOLK 멤버십</div>
          <div className="description">{userMembership.membership.membershipGrade === '02' ? '에그 백퍼센트 활용중!' : '에그 백퍼센트 활용해보세요!'}</div>
        </div>
      </div>
      {userMembership.membership.membershipGrade === '02' ? (
        <div className="manage-membership-info">
          <div className="title">
            <div>멤버십 관리</div>
            <p>이용중</p>
          </div>
          <div className="membership-info">
            <div className="payment-info">
              <div>결제 수단</div>
              <div>구독료</div>
              <div>최근결제</div>
              <div>다음결제</div>
            </div>
            <div className="payment-data">
              <div>{paymentCardData ? paymentCardData.cardCompanyName : '카드 정보 없음'}</div>
              <div>
                {findMembership.subscriptionType === '01' ? '연' : '월'} {AddComma(findMembership.actualPrice)}(VAT 포함)
              </div>
              <div>{dayjs(userMembership.membership.paymentDate).format('YYYY년 MM월 DD일')}</div>
              <div>
                {userMembership.membership.cancelDate
                  ? `해지(${dayjs(userMembership.membership.applyEndDate).format('YYYY년 MM월 DD일')} 까지 이용가능)`
                  : dayjs(userMembership.membership.applyEndDate).format('YYYY년 MM월 DD일')}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="general-member">
          <div>
            연 330,000원 <span>(-66,000원 할인)</span>
          </div>
          <div>*VAT(부가가치세) 포함</div>
        </div>
      )}
      <div className="benefit">
        <div>
          <div>혜택 안내</div>
          <p onClick={viewMoreBenefitDetail}>
            자세히보기 <Arrow />
          </p>
        </div>
        <div className="event-description">
          <div className="description">
            <div>욜크회원 전용 우선예약</div>
            <div>익스프레스 예약 제공</div>
          </div>
          <div className="express-img">
            <BenefitExpress />
          </div>
        </div>
      </div>
      {!userMembership.membership.cancelDate && userMembership.membership.membershipGrade === '02' ? (
        <div className="rescission-membership">
          <Link to="/membership/cancel">
            <button>멤버십 해지</button>
          </Link>
        </div>
      ) : null}
    </ManageMembershipContainer>
  );
};

export default ManageMembership;
