import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const NoReservationHistoryContainer = styled.div<React.CSSProperties>`
  min-height: calc(100vh - (96px * 2));
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    ${Typography.title_32}
    color: ${Colors.gray9};
  }
`;
