import React from 'react';

import { IMenuList } from './MenuList.types';

import { MenuListContainer, MenuListTitle } from '@/components/MenuList/MenuList.styles';
import MenuListViewer from '@/components/MenuListViewer/MenuListViewer';

const MenuList = ({ menus, setViewAll }: IMenuList): React.ReactElement => {
  return (
    <MenuListContainer>
      <MenuListTitle>
        메뉴<label>{menus.length}</label>
        {menus.length > 4 ? <span onClick={setViewAll}>전체보기</span> : null}
      </MenuListTitle>
      <MenuListViewer setViewAll={setViewAll} menus={menus.slice(0, 4)} type="slice" />
    </MenuListContainer>
  );
};

export default MenuList;
