import React, { useEffect, useId, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

// import EventPopupIcon from '@/assets/img/event/sungi/event_popup.png';
// import { ReactComponent as EventPopup } from '@/assets/img/icon/event_popup.svg';
import SampleIcon from '@/assets/img/event/yowa/newEvent.png';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel';
import { ImageMainCarouselItem } from '@/components/ImageCarousel/ImageCarousel.styles';
import { ICarouselItem } from '@/components/ImageCarousel/ImageCarousel.types';
import MainMalls from '@/components/MainMalls/MainMalls';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { useConfirmLoginBeforeMoving } from '@/hooks/confirmLoginBeforeMoving';
import { useQueryBanner } from '@/hooks/mainPage.hook';
import { CookieStorage, LocalStorage } from '@/shared/configs/storage';
import { MainContainer } from '@/shared/styles/pages/main';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useUserInfoStore } from '@/stores/useUserInfoStore';

const Main = (): React.ReactElement => {
  const setIsNavigationVisible = useNavigationStore((state) => state.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const { data, isLoading, isFetching, isError } = useQueryBanner();
  const { setProvider } = useUserInfoStore();
  const { setAlertModal, setOpenGlobalConfirmModal, alertModal } = useModalStore();
  const { confirmLoginBeforeMoving } = useConfirmLoginBeforeMoving();

  const navigate = useNavigate();

  const { data: _ } = useUserMembership();

  const carouselHandleClick = (url, item) => {
    const type = url !== '#' ? url.split(',')[0].replace('@', '') : '';
    const value = url !== '#' ? url.split(',')[1] : '';
    if (type === 'LINK') {
      window.location.href = value;
    }
    if (type === 'moveGallery') {
      navigate(`/booking/mall-detail/${value}/gallery`);
    }
    if (type === 'popMall') {
      navigate(`/booking/mall-detail/${value}`);
    }
    if (type === 'popEx') {
      window.open(value, 'Ex');
    }
    if (type === 'popEvent') {
      navigate(`/${value}`);
    }
    if (type === 'loinCheckPopEvent') {
      confirmLoginBeforeMoving(`/${value}`);
    }
  };

  const carouselItems: ICarouselItem[] = useMemo(() => {
    if (!data?.list) {
      return null;
    }
    return data.list.map((item, index) => ({
      key: `carousel-${index}`,
      name: `carousel-${index}`,
      render: () => <ImageMainCarouselItem background={item.img_url} onClick={() => carouselHandleClick(item.url, item)}></ImageMainCarouselItem>,
    }));
  }, [data?.list]);

  const hourNoneDisplay = () => {
    const todayDate = new Date();
    todayDate.setHours(1);
    // eslint-disable-next-line no-useless-concat
    CookieStorage.setCustomDayItem('event-modal', 'event-modal', 1);
  };

  const openPopupTimer = (open: string | number | dayjs.Dayjs | Date, close: string | number | dayjs.Dayjs | Date) => {
    const nowDate = dayjs();
    const openDate = dayjs(open);
    const closeDate = dayjs(close);
    const diffTime = openDate.diff(nowDate, 's');
    const closeTime = closeDate.diff(nowDate, 's');

    if (diffTime <= 0 && closeTime >= 0) {
      if (!CookieStorage.getItem('event-modal')) {
        setAlertModal({ visible: true, key: 'alertEventPopupKey' });
      }
    }
  };

  useEffect(() => {
    const open = '2024-03-01 14:00:00';
    const close = '2024-03-25 18:00:00';
    setIsNavigationVisible(true);
    setIsFooterVisible(true);
    setProvider();
    openPopupTimer(open, close);
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <MainContainer>
      <ImageCarousel
        infiniteLoop={true}
        autoPlay={true}
        interval={4000}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        swipeable={true}
        emulateTouch={true}
        items={carouselItems}
      />
      <MainMalls />
      <ModalPortal>
        <Modal.EventPopup key={'alertEventPopupKey'} modal="alert" isDim={true} isAnimation={true}>
          <section
            className="content-wrapper"
            onClick={() => {
              confirmLoginBeforeMoving('/detail/newtm');
            }}
          >
            <img src={SampleIcon} />
          </section>
          <div className="event-modal-bottom">
            <div
              onClick={() => {
                setAlertModal({ visible: false, key: 'alertEventPopupKey' });
                hourNoneDisplay();
              }}
            >
              오늘 하루 보지 않기
            </div>
            <div onClick={() => setAlertModal({ visible: false, key: 'alertEventPopupKey' })}>닫기</div>
          </div>
        </Modal.EventPopup>
      </ModalPortal>
    </MainContainer>
  );
};

export default Main;
