import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles';

export const PartySizeSelectorContainer = styled.div<React.CSSProperties>`
  margin: 48px 32px 40px;
  display: flex;
  justify-content: space-between;

  .people-text {
    ${Typography.title_36_sb}
    line-height: 72px;
  }

  .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .people-value {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 119px;
      font-weight: 400;
      ${Typography.title_32_m}
    }

    .icon {
      svg {
        width: 72px;
        height: 72px;
        vertical-align: middle;
      }
    }
  }
`;
