import React, { useEffect, useId } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from '@emotion/styled';

import Address from '@/components/Address/Address';
import Button from '@/components/Button/Button';
import { ButtonContainer } from '@/components/Button/Button.styles';
import CompleteHeader from '@/components/CompleteHeader/CompleteHeader';
import CompleteReservationInfo from '@/components/CompleteReservationInfo/CompleteReservationInfo';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import RefundPolicy from '@/components/RefundPolicy/RefundPolicy';
import ToastPortal from '@/components/ToastPortal/ToastPortal';
import UserInfo from '@/components/UserInfo/UserInfo';
import { useReservationHistoryDetailData } from '@/hooks/reservationHistory.hook';
import { copyMessage, makeShareText } from '@/shared/libs/function/shreText';
import { Colors } from '@/shared/styles';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const BookingStepCompleteviewEmotion = styled.div<React.CSSProperties>`
  height: calc(100% - 104px - 96px);
  overflow: auto;
  .refund-policy-container {
    padding: 0 32px 80px;
  }
`;

const ButtonFooterContainer = styled.div<React.CSSProperties>`
  position: sticky;
  bottom: 0;
  display: flex;
  z-index: 1;
  > ${ButtonContainer} {
    width: 50% !important;
    &.secondary {
      border-left: 0;
      border-bottom: 0;
      border-color: ${Colors.gray2};
    }
  }
`;

const AddressWrapper = styled.section`
  width: 100%;
  padding-inline: 32px;
  margin-bottom: 100px;
`;

const BookingStepCompleteview = (): React.ReactElement => {
  const navigate = useNavigate();
  const shareReservationHistoryKey = useId();
  const successCopyTextKey = useId();
  const { toastList, setToastList, setAlertModal } = useModalStore();
  const { reservationId } = useParams();

  const { data: completeDetailData, isLoading } = useReservationHistoryDetailData(reservationId);

  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '예약 완료',
      handleGoToBack: () => navigate('/main', { replace: true }),
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: () => {
        setAlertModal({ visible: true, key: shareReservationHistoryKey });
      },
    });
  }, []);

  if (isLoading) {
    return null;
  }
  const { reservation, store, visitor } = completeDetailData;

  makeShareText({ reservation, store, visitor });

  return (
    <>
      <BookingStepCompleteviewEmotion>
        <CompleteHeader name={store.name} />
        <CompleteReservationInfo reservation={reservation} />
        <UserInfo reservation={reservation} visitor={visitor} />
        <AddressWrapper>
          <Address store={store} />
        </AddressWrapper>
        <div className="refund-policy-container">
          <MainSubtitle subtitle="취소 수수료 안내" />
          <RefundPolicy policies={store.depositRefunds} />
        </div>
      </BookingStepCompleteviewEmotion>
      <ButtonFooterContainer>
        <Button colorLevel="secondary" type="sticky" onClick={() => navigate('/main', { replace: true })}>
          홈 화면 가기
        </Button>
        <Button colorLevel="primary" type="sticky" onClick={() => navigate('/reservation/history')}>
          예약 내역 확인
        </Button>
      </ButtonFooterContainer>
      <ModalPortal>
        <Modal.Alert key={shareReservationHistoryKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">공유하기</h2>
            <p className="desc">
              예약 내역을 다른 사람에게 <br />
              공유하시겠습니까?
            </p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: shareReservationHistoryKey })}>아니요</button>
            <button
              onClick={() => {
                setAlertModal({ visible: false, key: shareReservationHistoryKey });
                copyMessage();
                setToastList([...toastList, { visible: true, key: successCopyTextKey }]);
              }}
            >
              텍스트 복사
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
      <ToastPortal>
        <Modal.Toast key={successCopyTextKey} toastKey={successCopyTextKey} modal="toast" autoHideDuration={3000} isAnimation={true} position="bottom">
          <div>텍스트가 복사되었습니다</div>
        </Modal.Toast>
      </ToastPortal>
    </>
  );
};

export default BookingStepCompleteview;
