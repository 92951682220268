import React from 'react';

import styled from '@emotion/styled';

export const ProfileContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 28px;
  .user-photo {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-bottom: 28px;
    img {
      width: 160px;
      height: 160px;
    }
  }
  .user-name {
    font-weight: 700;
    font-size: 44px;
    line-height: 50px;
    color: #272a2d;
  }
`;
