import { useEffect, useState } from 'react';
import type { NavigateFunction } from 'react-router-dom';

import { TUseMenuListViewer } from './MenuListViewer.types';

import { useHeaderStore } from '@/stores/common/useHeaderStore';

export const useStateMenuListViewer = (navigate: NavigateFunction): TUseMenuListViewer => {
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  const [isMenuListViewAll, setIsMenuListViewAll] = useState(false);

  useEffect(() => {
    if (isMenuListViewAll) {
      setHeaderState({
        title: '매장 상세보기',
        handleGoToBack: () => setIsMenuListViewAll(false),
        handleGoToHome: () => navigate('/main'),
        handleOnAlarm: null,
        handleOnClose: null,
        handleOnShare: null,
      });
      setIsMenuListViewAll(true);
    }
  }, [isMenuListViewAll]);

  return [isMenuListViewAll, setIsMenuListViewAll];
};
