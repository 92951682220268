import React from 'react';

import styled from '@emotion/styled';

import { IMenuListViewerContainer } from './MenuListViewer.types';

import { Colors, Typography } from '@/shared/styles';

export const MenuListViewerItem = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
  border-bottom: 2px solid ${Colors.gray3};
  & > h2 {
    ${Typography.body_26};
  }
  & > h2:first-of-type {
    color: ${Colors.gray7};
    font-weight: 400;
    margin-bottom: 4px;
  }
  & > h2:last-child {
    color: ${Colors.gray9};
    ${Typography.body_28_m};
    font-weight: 500;
  }
`;

export const MenuListViewerContainer = styled.div<IMenuListViewerContainer>`
  display: flex;
  flex-direction: column;
  border: 2px solid ${Colors.gray3};
  border-radius: 8px;
  padding: 36px 32px;
  gap: 28px;
  margin: ${(props) => (props.type === 'viewer' ? '64px 32px' : 0)};
  ${MenuListViewerItem} {
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
`;
