import React from 'react';

import Lottie from 'lottie-react';

import YolkSpinnerIcon from '@/assets/img/icon/yolk-spinner.json';
import YolkJumping from '@/assets/img/icon/yolk_loading.json';
import { YolkSpinnerContainer } from '@/components/YolkSpinner/YolkSpinner.styles';
import { useEggLoadingStore } from '@/stores/useEggLoadingStore';

const YolkSpinner = (): React.ReactElement => {
  const { isOpenIconLoading } = useEggLoadingStore();

  return (
    <YolkSpinnerContainer isOpenIconLoading={isOpenIconLoading}>
      {isOpenIconLoading ? <Lottie animationData={YolkJumping} /> : <Lottie animationData={YolkSpinnerIcon} />}
    </YolkSpinnerContainer>
  );
};

export default YolkSpinner;
