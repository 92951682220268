/* eslint-disable no-prototype-builtins */
import React, { useEffect, useId, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import hasIn from 'lodash/hasIn';

import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import { EmailNotice, UserInfoForm as UserInfoFormEmotion } from './UserInfoForm.constants';
import { ICheckPhoneResult } from './UserInfoForm.type';

import { ReactComponent as InputClear } from '@/assets/img/icon/input_clear.svg';
import Button from '@/components/Button/Button';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { ControlledInput } from '@/shared/hook-form/ControlledInput';
import { autoHypenPhoneNum, hypenPhoneNum } from '@/shared/libs/function/auto-hypen';
import { HookFormProps } from '@/shared/types/common.types';
import { useModalStore } from '@/stores/common/useModalStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const UserInfoForm = (): React.ReactElement => {
  const { provisionalToken, phoneAuth, phoneAuthCode, phoneAuthToken, dispatchBookingInfo } = useBookingInfoStore();
  const { name, phone } = useUserProfileStore();
  const { setAlertModal, setOpenGlobalErrorModal } = useModalStore();
  const methods = useFormContext();
  const sendPhoneAuthKey = useId();

  const [emailLength, setEmailLength] = useState(0);

  const {
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  }: HookFormProps = methods;

  const sendPhoneAuthCode = () => {
    dispatchBookingInfo({ type: 'SET_STATE', value: { user: getValues('user') } });
    if (!hasIn(errors, 'user.phoneNumber') && getValues('user.phoneNumber')) {
      eggdiningApi
        .post<ICheckPhoneResult>('/reservations/check', { phone: getValues('user.phoneNumber').replaceAll('-', ''), token: provisionalToken })
        .then((res) => {
          if (res.isPhoneAuth) {
            setAlertModal({ visible: true, key: sendPhoneAuthKey });
            dispatchBookingInfo({ type: 'SET_PHONE_AUTH_TOKEN', value: res.token });
            dispatchBookingInfo({ type: 'SET_EXPIRY_TIME', value: res.date_expire });
          } else {
            dispatchBookingInfo({ type: 'AUTH' });
          }
        })
        .catch((e) => {
          setOpenGlobalErrorModal({ visible: true, message: e.response.data.response.message, title: '알림', closeText: '닫기' });
        });
    }
  };

  const phoneAuthFunction = () => {
    if (phoneAuth || !getValues('phoneAuthCode')) {
      return;
    }
    eggdiningApi
      .get<any>('/reservations/sms', { params: { phone_code: getValues('phoneAuthCode'), phone_token: phoneAuthToken, token: provisionalToken } })
      .then((res) => {
        if (res.result) {
          dispatchBookingInfo({ type: 'AUTH' });
          setAlertModal({ notice: '휴대폰 인증이 완료되었습니다.' });
          dispatchBookingInfo({ type: 'SET_STATE', value: { user: getValues('user') } });
          dispatchBookingInfo({ type: 'SET_PHONE_AUTH_CODE', value: getValues('phoneAuthCode') });
          setValue('phoneAuth', true);
        } else {
          setOpenGlobalErrorModal({ visible: true, message: res.message, title: '알림', closeText: '닫기' });
        }
      })
      .catch((e) => {
        if (e.response.data.response.message === '5회 이상 인증번호 확인 실패하여 토큰이 삭제되었습니다.') {
          dispatchBookingInfo({ type: 'CLEAR_AUTH' });
          setValue('user.phoneNumber', '');
        } else {
          setOpenGlobalErrorModal({ visible: true, message: e.response.data.response.message, title: '알림', closeText: '닫기' });
        }
      });
  };

  const clearAuthPhone = () => {
    dispatchBookingInfo({ type: 'CLEAR_AUTH' });
    setValue('user.phoneNumber', '');
  };

  const resetAuth = () => {
    dispatchBookingInfo({ type: 'CLEAR_AUTH' });
    setValue('phoneAuthCode', '');
  };

  useEffect(() => {
    if (getValues('user.name') === '') {
      setValue('user.name', name);
    }
    if (getValues('user.phoneNumber') === '') {
      setValue('user.phoneNumber', hypenPhoneNum(phone));
      dispatchBookingInfo({ type: 'AUTH' });
      setValue('phoneAuth', true);
    }
  }, []);

  return (
    <UserInfoFormEmotion>
      <MainSubtitle subtitle="예약자 정보" />
      <div className="input-box">
        <ControlledInput
          control={methods.control}
          type="text"
          label="이름"
          placeholder="이름을 입력해주세요"
          value={getValues('user.name')}
          clearicon={<InputClear onClick={() => setValue('user.name', '')} />}
          name="user.name"
          required={true}
        />
      </div>
      {phoneAuth ? (
        <div className="input-box">
          <ControlledInput
            control={methods.control}
            type="text"
            label="휴대폰 번호"
            placeholder="010-0000-0000"
            name="user.phoneNumber"
            disabled={Boolean(phoneAuthToken || phoneAuth)}
            maxLength={13}
            required={true}
            className="clear-auth"
          />
          <div className="phone-change-button" onClick={clearAuthPhone}>
            변경
          </div>
        </div>
      ) : (
        <>
          <div className="input-box">
            <div className="phone-auth-box">
              <ControlledInput
                control={methods.control}
                value={getValues('user.phoneNumber')}
                type="text"
                label="휴대폰 번호"
                placeholder="010-0000-0000"
                onChange={(e) => setValue('user.phoneNumber', autoHypenPhoneNum(e.target.value))}
                name="user.phoneNumber"
                disabled={Boolean(phoneAuthToken)}
                maxLength={13}
                inputMode="numeric"
                required={true}
                error={hasIn(errors, 'user.phoneNumber')}
                className="input auth-number"
                clearicon={<InputClear onClick={() => setValue('user.phoneNumber', '')} />}
              />
              {phoneAuthToken ? (
                <Button colorLevel="secondary" type="large" onClick={resetAuth} classKey="phone-auth-button">
                  다시 인증
                </Button>
              ) : (
                <Button
                  colorLevel="secondary"
                  disabled={Boolean(phoneAuthToken) || hasIn(errors, 'user.phoneNumber') || !dirtyFields.user?.phoneNumber}
                  type="large"
                  onClick={sendPhoneAuthCode}
                  classKey={hasIn(errors, 'user.phoneNumber') ? 'error phone-auth-button' : 'phone-auth-button'}
                >
                  인증번호 받기
                </Button>
              )}
            </div>
          </div>
          <div className="input-box certification-number">
            <div className="phone-auth-box">
              {phoneAuthCode}
              <ControlledInput
                name="phoneAuthCode"
                control={methods.control}
                type="text"
                value={getValues('phoneAuthCode')}
                placeholder="인증번호 입력"
                disabled={Boolean(!phoneAuthToken)}
                maxLength={6}
                inputMode="numeric"
                required={true}
                error={hasIn(errors, 'phoneAuth') ? errors.phoneAuth.message : null}
                className="input auth-number"
                onChange={(e) => setValue('phoneAuthcode', e.target.value)}
              />
              <Button colorLevel="secondary" disabled={!phoneAuthToken} type="large" onClick={phoneAuthFunction} classKey="phone-auth-button">
                인증번호 확인
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="input-box">
        <ControlledInput
          onChange={(e) => setEmailLength(e.target.value.length)}
          control={methods.control}
          type="text"
          label="이메일"
          placeholder="이메일을 입력해주세요."
          name="user.email"
          clearicon={<InputClear onClick={() => setValue('user.email', '')} />}
          error={hasIn(errors, 'user.email') ? errors.user.email.message : null}
        />
      </div>
      <EmailNotice textLength={emailLength}>이메일 주소 입력하시면 결제 내역을 발송해드립니다.</EmailNotice>

      <ModalPortal>
        <Modal.Alert key={sendPhoneAuthKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">
              인증번호를 전송했습니다. 인증번호가 도착하지
              <br />
              않을 경우 다시 인증 버튼을 눌러 다시 시도해 주<br />
              세요.
            </p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: sendPhoneAuthKey })}>닫기</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </UserInfoFormEmotion>
  );
};

export default UserInfoForm;
