import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const MainMallContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 64px;
  & > a {
    .mall-img-wrapper {
      height: 212px;
      margin-bottom: 16px;
      border-radius: 8px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & > h2 {
      max-width: 100%;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      ${Typography.body_28_sb}
    }
    & > span {
      display: block;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      ${Typography.small_1}
      color:${Colors.gray6}
    }
  }
`;
export const MainMallFullBadge = styled.div<React.CSSProperties>`
  &::after {
    content: 'FULL';
  }
  position: absolute;
  left: 0;
  top: 0;
  padding: 6px 12px;
  border-radius: 8px 0px;
  background: ${Colors.yellow1};
  color: ${Colors.white};
  ${Typography.small_b};
`;

export const MainMallNewBadge = styled.div<React.CSSProperties>`
  &::after {
    content: 'COMING\\ASOON';
    white-space: pre-wrap;
    text-align: center;
  }
  position: absolute;
  left: 0;
  top: 0;
  padding: 6px 12px;
  border-radius: 8px;
  color: ${Colors.white};
  ${Typography.body_28_m};
  width: 100%;
  height: 212px;
  background: rgba(39, 42, 45, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;
