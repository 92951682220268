import React from 'react';

import styled from '@emotion/styled';

import { Subtitle } from '@/components/Subtitle/Subtitle.constants';
import { Typography } from '@/shared/styles';

export const BookingSubtitleContainer = styled.div<React.CSSProperties>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .left {
    display: flex;
    ${Typography.title_36_sb}
    ${Subtitle} {
      margin-bottom: 0;
    }
  }
  .count {
    background-color: #e9ebee;
    padding: 4px 16px;
    border-radius: 28px;
    font-size: 28px;
    font-weight: 500;
    margin-left: 12px;
    ${Typography.body_28}
  }
  .move {
    color: #f6c944;
    display: flex;
    align-items: center;
    ${Typography.small_2}
    .icon {
      width: 18px;
      height: 18px;
    }
  }
`;
