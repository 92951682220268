import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const MallDescriptionContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 32px 80px 32px;
  & > h1 {
    ${Typography.title_36_sb};
    color: ${Colors.gray9};
  }
`;
export const MallDescriptionTextContainer = styled.div<React.CSSProperties>`
  ${Typography.body_28};
  color: ${Colors.gray9};
`;
