import React, { useEffect } from 'react';

import { PartySizeSelectorContainer } from './PartySizeSelector.constants';

import { ReactComponent as Minus } from '@/assets/img/icon/minusRect.svg';
import { ReactComponent as DeactiveMinus } from '@/assets/img/icon/minusRectGray.svg';
import { ReactComponent as Plus } from '@/assets/img/icon/plusRect.svg';
import { ReactComponent as DeactivePlus } from '@/assets/img/icon/plusRectGray.svg';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useBookingStore } from '@/stores/useBookingStore';
import { useInfoStore } from '@/stores/useInfoStore';

const PartySizeSelector = (): React.ReactElement => {
  const mall = useBookingStore((store) => store.mall);
  const partySize = useBookingInfoStore((store) => store.partySize);
  const dispatchBookingInfo = useBookingInfoStore((store) => store.dispatchBookingInfo);
  const { setCalendarDirty } = useInfoStore();
  const calcPeople = (index) => {
    dispatchBookingInfo({ type: 'CHANGE_PARTY_SIZE', value: partySize + index });
    setCalendarDirty(false);
  };

  useEffect(() => {
    if (!partySize && mall) {
      if (mall.storePartySize.min <= 1 && mall.storePartySize.max >= 1) {
        dispatchBookingInfo({ type: 'CHANGE_PARTY_SIZE', value: 1 });
      } else {
        dispatchBookingInfo({ type: 'CHANGE_PARTY_SIZE', value: mall.storePartySize.min });
      }
    }
  }, [mall]);

  if (!mall) {
    return <></>;
  }

  return (
    <PartySizeSelectorContainer>
      <span className="people-text">인원</span>
      <div className="icon-box">
        <div className="icon minus">{partySize > mall.storePartySize.min && partySize > 1 ? <Minus onClick={() => calcPeople(-1)} /> : <DeactiveMinus />}</div>
        <span className="people-value">{partySize}명</span>
        <div className="icon plus">{partySize < mall.storePartySize.max ? <Plus onClick={() => calcPeople(1)} /> : <DeactivePlus />}</div>
      </div>
    </PartySizeSelectorContainer>
  );
};

export default PartySizeSelector;
