import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MYPAGE, RESERVATION } from './Navigation.constants';
import { Nav, NavButton, NavPadding, NavWrapper } from './Navigation.styles';

import { LocalStorage } from '@/shared/utils/storage';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const Navigation = (): React.ReactElement => {
  const isNavigationVisible = useNavigationStore((store) => store.isNavigationVisible);
  const navigation = useNavigate();
  const { setOpenGlobalConfirmModal } = useModalStore();
  const { pathname } = useLocation();

  const goToMenu = (path: string) => {
    navigation(path);
  };

  const isMyPage = (path: string) => pathname.includes(path);

  const reservationLink = () => {
    goToMenu(`/${RESERVATION}`);
  };

  const mypageLink = () => {
    if (LocalStorage.getItem('loginType') === 'none') {
      setOpenGlobalConfirmModal({
        visible: true,
        title: '알림',
        message: '로그인 해야 사용할 수 있는 기능입니다. 에그에 로그인하시겠어요?',
        closeText: '닫기',
        okayText: '로그인',
        callback: () => {
          navigation('/auth/signin');
        },
      });
    } else {
      goToMenu(`/${MYPAGE}`);
    }
  };
  if (!isNavigationVisible) {
    return <></>;
  }

  return (
    <NavWrapper>
      <Nav>
        <ul>
          <NavButton className="reserv-btn" active={isMyPage(RESERVATION)} onClick={reservationLink}>
            예약
          </NavButton>
          <NavButton className="mypage-btn" active={isMyPage(MYPAGE)} onClick={mypageLink}>
            마이페이지
          </NavButton>
        </ul>
      </Nav>
      <NavPadding />
    </NavWrapper>
  );
};

export default Navigation;
