import React from 'react';

import { CompleteHeaderContainer } from '@/components/CompleteHeader/CompleteHeader.styles';

const CompleteHeader = ({ name }): React.ReactElement => {
  return (
    <CompleteHeaderContainer>
      <div className="status-info-text">예약이 완료되었습니다.</div>
      <div className="mall-name">{name}</div>
    </CompleteHeaderContainer>
  );
};

export default CompleteHeader;
