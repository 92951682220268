import React from 'react';

import { MallTitleContainer } from './MallTitle.constants';

import { useBookingStore } from '@/stores/useBookingStore';

const MallTitle = (): React.ReactElement => {
  const { mall } = useBookingStore();

  return (
    <MallTitleContainer>
      <span>{mall.storeFoods.join('∙')}</span>
      <h1>{mall.storeName}</h1>
      {mall.storeDescription && <h2>{mall.storeDescription}</h2>}
    </MallTitleContainer>
  );
};

export default MallTitle;
