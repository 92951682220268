import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const AddressContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > h1 {
    ${Typography.title_36_sb};
    color: ${Colors.gray9};
    padding-bottom: 20px;
  }
  .phone-box {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .phone-number {
      ${Typography.body_28}
      color: ${Colors.blue}
    }
  }
`;

export const AddressMapInfoContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  background: ${Colors.gray0};
  gap: 12px;
  border-radius: 8px;
  & > h2 {
    ${Typography.body_28};
    color: ${Colors.gray8};
    word-break: keep-all;
  }
  & > span {
    ${Typography.body_28};
    color: ${Colors.gray6};
  }
  .phone-box {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .phone-number {
      ${Typography.body_28}
      color: ${Colors.blue}
    }
  }
`;
