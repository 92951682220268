import create from 'zustand';

interface IDetailInfoStore {
  saveTerms: any[];
  setSaveTerms: (saveTerms) => void;
}

export const useSaveTermsStore = create<IDetailInfoStore>((set) => ({
  saveTerms: [],
  setSaveTerms: (saveTerms) => set(() => ({ saveTerms })),
}));

interface IChooseSubscription {
  saveSubscriptionType: string;
  setSaveSubscriptionType: (saveSubscriptionType) => void;
}

export const useSaveSubscriptionStore = create<IChooseSubscription>((set) => ({
  saveSubscriptionType: '01',
  setSaveSubscriptionType: (saveSubscriptionType) => set(() => ({ saveSubscriptionType })),
}));
