import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const RequestFormContainer = styled.section<React.CSSProperties>`
  > div {
    padding: 0 32px 80px;
    .request-form-box {
      .textarea-wrapper {
        position: relative;

        .textarea-resize-icon {
          display: block;
          width: 18px;
          height: 18px;
          padding: 7px;
          box-sizing: content-box;
          position: absolute;
          z-index: 2;
          // border size 만큼 빼준 값
          bottom: 2px;
          right: 2px;
          ////////////////////////
          border-radius: 4px;
          background: ${Colors.white};
          pointer-events: none;
        }

        .request-textarea {
          resize: vertical;
          box-sizing: border-box;
          padding: 25px 28px;
          width: 100%;
          height: 216px;
          min-height: 100px;
          border: 2px solid ${Colors.gray4};
          border-radius: 4px;
          font-size: 28px;
          transition: border-color 0.2s;
          background-color: ${Colors.white};

          &::placeholder {
            color: ${Colors.gray4};
          }

          &:focus {
            background: ${Colors.white};

            & + .clear-button {
              opacity: 1;
            }
          }

          &:placeholder-shown {
            & + .clear-button {
              display: none;
            }
          }

          &:focus {
            border: 2px solid ${Colors.gray9};
            outline: none;
          }

          &:focus-within {
            border: 2px solid ${Colors.gray9};
            outline: none;
            background-color: white;
          }

          &:focus + .textarea-resize-icon {
            line {
              stroke: ${Colors.gray9};
            }
          }
        }
      }

      .clear-button {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        opacity: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        transition-duration: 1s;
        transition-property: all;

        .icon {
          margin: 28px 24px;
          width: 40px;
          height: 40px;
        }
      }
    }

    .description {
      padding-top: 28px;
      display: flex;
      ${Typography.small_2}
      .notice {
        margin-left: 16px;
        ${Typography.small_1}
        color: ${Colors.gray6}
      }

      .icon {
        min-width: 26px;
        height: 26px;
      }
    }
  }
`;
