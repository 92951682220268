import React from 'react';
import { useNavigate } from 'react-router-dom';

import BookingFooter from '@/components/BookingFooter/BookingFooter';
import { useBookingStore } from '@/stores/useBookingStore';
import { useInfoStore } from '@/stores/useInfoStore';

const InfoFooter = (): React.ReactElement => {
  const navigate = useNavigate();
  const { storeUri } = useBookingStore();
  const { calendarDirty, timeDirty } = useInfoStore();

  const moveCategory = () => navigate(`/reservation/${storeUri}/step/category`);
  return <BookingFooter text="확인" action={moveCategory} active={calendarDirty && timeDirty} />;
};

export default InfoFooter;
