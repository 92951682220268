import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const DepositNoticeContainer = styled.div<React.CSSProperties>`
  padding: 64px 32px;
  .noshow-container {
    display: flex;
    padding: 32px 24px;
    color: ${Colors.gray8};
    ${Typography.small_1};
    background-color: ${Colors.gray1};
    .noshow {
      margin-right: 24px;
      color: ${Colors.red2};
      font-weight: 500;
    }
    .description {
      > div {
        color: ${Colors.gray7};
      }
      ${Typography.small_1}
      span {
        color: ${Colors.red2};
      }
    }
  }
  hr {
    margin: 48px 0px;
    border: solid 1px ${Colors.gray3};
    background: ${Colors.gray3};
  }
`;

export const PaymentInDepositNotice = styled.div`
  padding: 64px 32px;
  .noshow-container {
    margin-top: 40px;
    color: ${Colors.gray8};
    ${Typography.body_28};

    .noshow {
      color: ${Colors.red2};
      font-weight: 500;
    }
  }

  .deposit-container {
    .title {
      ${Typography.title_36_sb};
      margin-bottom: 40px;
    }
  }
`;

export const DepositCategoryWrapper = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Typography.body_36_sb};

    .money {
      color: ${Colors.red2};
      ${Typography.body_36_sb}
    }
  }
`;

export const DepositListWrapper = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Typography.body_28_sb};

    .money {
      color: ${Colors.red2};
      ${Typography.title_36_sb}
    }
  }
`;
