import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

interface IManageMembershipStyle extends React.CSSProperties {
  isYolkMember?: boolean;
}

export const ManageMembershipContainer = styled.div<IManageMembershipStyle>`
  width: 100%;
  margin: 80px 32px;
  .membership-header {
    text-align: center;
    margin-bottom: ${(props) => (props.isYolkMember ? '100px' : '40px')};
    .badge {
      margin-bottom: 28px;
    }
    .comment {
      .title {
        font-weight: 700;
        font-size: 44px;
        line-height: 50px;
        color: ${Colors.gray9};
        mix-blend-mode: normal;
        margin-bottom: 20px;
      }
      .description {
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        color: ${Colors.gray8};
      }
    }
  }
  .manage-membership-info {
    margin-bottom: 100px;
    .title {
      margin-bottom: 40px;
      align-items: center;
      ${Typography.title_36_sb}
      color: ${Colors.gray9};
      > div {
        margin-right: 8px;
      }
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 40px;
        background-color: ${Colors.yellow0};
        border-radius: 4px;
        ${Typography.s_small_sb}
        color: ${Colors.yellow3};
      }
    }
    > div {
      display: flex;
    }
    .membership-info {
      .payment-info {
        min-width: 97px;
        margin-right: 40px;
        > div {
          color: ${Colors.gray6};
        }
      }
      .payment-data {
        > div {
          color: ${Colors.gray8};
        }
      }
      > div {
        > div {
          ${Typography.body_28}
          margin-bottom: 12px;
        }
      }
    }
  }
  .general-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0px;
    margin-bottom: 100px;
    background: ${Colors.gray0};
    border-radius: 8px;
    ${Typography.body_28_sb}
    div:first-of-type {
      margin-bottom: 12px;
      > span {
        color: ${Colors.red2};
      }
    }
    div:last-of-type {
      ${Typography.small_1}
      color: ${Colors.gray6};
    }
  }
  .benefit {
    padding-bottom: 40px;
    > :first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      div {
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        color: ${Colors.gray9};
      }
      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: ${Colors.gray6};
      }
    }
    .event-description {
      display: flex;
      justify-content: space-between;
      height: 160px;
      background: ${Colors.white};
      border: 2px solid ${Colors.gray3};
      border-radius: 8px;
      padding: 40px 32px;
      .description {
        > :first-of-type {
          margin-bottom: 4px;
          ${Typography.small_m}
          color: ${Colors.gray8};
        }
        div:last-of-type {
          ${Typography.body_28_b}
          color: ${Colors.gray8};
        }
      }
      .express-img {
        width: 200px;
        height: 80px;
      }
    }
  }
  .rescission-membership {
    padding-bottom: 80px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 184px;
      height: 62px;
      background: ${Colors.white};
      border: 2px solid ${Colors.gray3};
      border-radius: 31px;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: ${Colors.gray9};
    }
  }
`;

export const ButtonWrapper = styled.div<React.CSSProperties>`
  position: sticky;
  bottom: 0;
`;
