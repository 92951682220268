import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from '@emotion/styled';

import { IMakePreReservationResult } from '../../../../stores/useBookingStore';

import BookingInfoView from '@/components/BookingInfoView/BookingInfoView';
import Button from '@/components/Button/Button';
import CategorySelector from '@/components/CategorySelector/CategorySelector';
import DepositNotice from '@/components/DepositNotice/DepositNotice';
import Seperator from '@/components/Seperator/Seperator';
import { useDeleteProvisionalReservation } from '@/hooks/deleteProvisionalReservation.hooks';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { Variables } from '@/shared/styles';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { IBookingInfo, useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { IUseBookingStore, useBookingStore } from '@/stores/useBookingStore';
import { ICategoryResult, useCategoryStore } from '@/stores/useCategoryStore';

const CategoryContainer = styled.div<React.CSSProperties>`
  ${Variables.calcFullHeight};
  padding-bottom: 80px;
  overflow: auto;
`;

const getBookingState = (store: IUseBookingStore) => ({
  mall: store.mall,
  storeUri: store.storeUri,
  reservationInfo: store.reservationInfo,
  setReservationInfo: store.setReservationInfo,
});
const getBookingInfoState = (store: IBookingInfo) => ({
  partySize: store.partySize,
  dateTime: store.dateTime,
  categoryId: store.categoryId,
  provisionalToken: store.provisionalToken,
  deposit: store.deposit,
});

const BookingStepCategory = (): React.ReactElement => {
  const navigate = useNavigate();
  const query = useParams();
  const { storeUri, reservationInfo, setReservationInfo } = useBookingStore(getBookingState);
  const setStoreUri = useBookingStore((store) => store.setStoreUri);

  const { partySize, dateTime, categoryId, provisionalToken, deposit } = useBookingInfoStore(getBookingInfoState);
  const dispatchBookingInfo = useBookingInfoStore((store) => store.dispatchBookingInfo);

  const setOpenGlobalErrorModal = useModalStore((store) => store.setOpenGlobalErrorModal);
  const setCategories = useCategoryStore((store) => store.setCategories);
  const { mutate: delProvisionalReservationMutate } = useDeleteProvisionalReservation({ token: provisionalToken, storeUri });
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const [goDetail, setGoDetail] = useState(false);
  const makeProvisionalReservation = () => {
    eggdiningApi
      .post<IMakePreReservationResult>(`/eggdining/stores/${storeUri}/reservation/pre`, {
        totalPartySize: partySize,
        date: dateTime.format('YYYY-MM-DD'),
        time: dateTime.hour() * 60 + dateTime.minute(),
        categoryId,
      })
      .then((res) => {
        dispatchBookingInfo({ type: 'SET_PROVISIONAL_TOKEN', value: res.preReservation.token });
        dispatchBookingInfo({ type: 'SET_EXPIRY_TIME', value: res.preReservation.expireTimestamp });
        dispatchBookingInfo({ type: 'SET_DEPOSIT', value: res.preReservation.paymentAmount });
        setReservationInfo(res.store);
      })
      .catch((e) => {
        setOpenGlobalErrorModal({ visible: true, message: e.response.data.response.message, title: '알림', closeText: '닫기' });
      });
  };

  const startProvisionalReservation = () => {
    dispatchBookingInfo({ type: 'SET_DETAIL_PARTY_SIZE', value: new Array(reservationInfo.partyPolicies.length).fill(0) });
    if (provisionalToken) {
      eggdiningApi.post('eggdining/reservations/pre/extend', { token: provisionalToken }).then((res: any) => {
        if (res.date_expire) {
          dispatchBookingInfo({ type: 'SET_EXPIRY_TIME', value: res.date_expire });
        }
        setGoDetail(true);
      });
    }
  };

  useEffect(() => {
    const fetchCategories = () =>
      eggdiningApi
        .get<ICategoryResult>(`/malls/${storeUri}/reservation/category`, {
          params: {
            count_people: partySize,
            date_y: dateTime.year(),
            date_m: dateTime.month() + 1,
            date_d: dateTime.date(),
            date_h: dateTime.hour() + Math.floor((dateTime.minute() / 60) * 10000) / 10000,
          },
        })
        .then((res) => {
          const reservableList = res.list.filter((item) => item.reservable);
          setCategories(reservableList);
          if (reservableList.length > 0) {
            dispatchBookingInfo({ type: 'SELECT_CATEGORY', value: reservableList[0].category_id });
          } else {
            dispatchBookingInfo({ type: 'SELECT_CATEGORY', value: null });
            setOpenGlobalErrorModal({
              visible: true,
              message: '현재 예약 가능한 좌석이 없습니다. 다른 날짜를 선택해주세요.',
              title: '알림',
              closeText: '닫기',
              callback: navigate(-1),
            });
          }
        })
        .catch((e) => {});
    fetchCategories();

    setIsFooterVisible(false);
    setIsNavigationVisible(false);
  }, []);

  useEffect(() => {
    setHeaderState({
      title: '예약하기',
      handleGoToBack: () => {
        delProvisionalReservationMutate();
        navigate(-1);
      },
      handleGoToHome: () => {
        delProvisionalReservationMutate();
        navigate('/main');
      },
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
  }, [provisionalToken]);

  useEffect(() => {
    if (query.storeUri) {
      setStoreUri(query.storeUri);
    }
  }, [query.storeUri]);

  useEffect(() => {
    if (provisionalToken) {
      delProvisionalReservationMutate();
    }
    if (categoryId) {
      makeProvisionalReservation();
    }
  }, [categoryId]);

  useEffect(() => {
    if (goDetail) {
      navigate(`/reservation/${storeUri}/step/detail-info?token=${provisionalToken}`, { replace: true });
    }
  }, [goDetail]);

  return (
    <>
      <CategoryContainer>
        <BookingInfoView />
        <CategorySelector />
        <Seperator />
        {deposit > 0 ? <DepositNotice /> : null}
      </CategoryContainer>

      <Button type="sticky" colorLevel="primary" isActive={categoryId ? true : false} onClick={startProvisionalReservation}>
        확인
      </Button>
    </>
  );
};

export default BookingStepCategory;
