import React from 'react';

import { UserInformationContainer } from '@/components/EditProfile/UserInformation/UserInformation.styles';
import { useUserProfileQuery } from '@/components/MyPage/Profile/Profile.hooks';
import { hypenPhoneNum } from '@/shared/libs/function/auto-hypen';
import { useUserInfoStore } from '@/stores/useUserInfoStore';

const loginProvider = {
  kakao: '카카오',
  google: '구글',
  apple: '애플',
};

const UserInformation = (): React.ReactElement => {
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfileQuery();
  const { provider } = useUserInfoStore();

  if (userProfileLoading) {
    return (
      <>
        <></>
      </>
    );
  }
  const { idP, name, phone } = userProfile.user;
  return (
    <UserInformationContainer>
      <div className="information-title">로그인 정보</div>
      <div className="information">
        <div>
          <div>로그인 수단</div>
          <div>휴대폰 번호</div>
        </div>
        <div>
          <div>{loginProvider[provider] ?? '알 수 없음'}</div>
          <div>{hypenPhoneNum(phone)}</div>
        </div>
      </div>
    </UserInformationContainer>
  );
};

export default UserInformation;
