import React from 'react';

import { BusinessHoursContainer } from './BusinessHours.styles';

import { useBookingStore } from '@/stores/useBookingStore';

const BusinessHours = (): React.ReactElement => {
  const { storeOpenTimes } = useBookingStore().mall;
  return (
    <BusinessHoursContainer>
      <h1>영업시간</h1>
      <ul>
        {storeOpenTimes.map((item, idx) => (
          <li key={idx}>
            {item.type === 'CLOSE' ? (
              <>{item.target} 휴무</>
            ) : (
              <>
                {item.target}{' '}
                {Math.floor(item.start / 60)
                  .toString()
                  .padStart(2, '0')}
                :{(item.start % 60).toString().padStart(2, '0')}~
                {Math.floor(item.end / 60)
                  .toString()
                  .padStart(2, '0')}
                :{(item.end % 60).toString().padStart(2, '0')} {item.etc ? `(${item.etc})` : null}
              </>
            )}
          </li>
        ))}
      </ul>
    </BusinessHoursContainer>
  );
};

export default BusinessHours;
