import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

interface ISave extends React.CSSProperties {
  isSave?: boolean;
}

export const ChooseSubscriptionContainer = styled.div<React.CSSProperties>`
  padding-inline: 32px;
  padding-top: 80px;

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 64px;
  }

  .radio-custom {
    background: ${Colors.white};
    border: 2px solid ${Colors.gray3};
    border-radius: 4px;

    label {
      align-items: center;
      gap: 20px;
      padding: 24px 32px 25px;
    }
  }
`;

export const Title = styled.h1`
  ${Typography.title_44}
`;

export const SubscriptionContent = styled.section`
  flex: 1;

  h5 {
    ${Typography.body_28_sb}
  }
  div {
    ${Typography.small_1}
    color: ${Colors.gray6};
    padding-top: 8px;
  }
`;
export const Saving = styled.section<ISave>`
  ${Typography.small_b}
  color: ${(props) => (props.isSave ? Colors.red2 : Colors.gray6)};
  align-self: flex-start;
`;
