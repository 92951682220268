import React, { useId } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../Button/Button';
import { AgreeMentToast } from '../EditProfile/AgreeTerms/AgreeTerms.styles';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import ToastPortal from '../ToastPortal/ToastPortal';

import { ReactComponent as PlusCircle } from '@/assets/img/icon/plusCircle.svg';
import { CardContent, JoinComponentsContainer, SignUpCelebration } from '@/components/JoinComponents/JoinComponents.styles';
import { useModalStore } from '@/stores/common/useModalStore';

const JoinComponents = (): React.ReactElement => {
  const { setMenuModal, setToastModal, setAlertModal, setToastList, toastList } = useModalStore();
  // menu
  const paymentAvailable = useId();
  const noPayment = useId();

  // alert
  const confirmPayment = useId();
  const faildPayment = useId();

  // toast
  const successModal = useId();
  const agreeTermsModal = useId();

  const navigate = useNavigate();

  return (
    <>
      <JoinComponentsContainer>
        <ul>
          <li>
            <h2>결제수단 선택</h2>
            <button onClick={() => setMenuModal({ visible: true, key: paymentAvailable })}>등록한 결제 수단 없는 경우</button>
          </li>
          <li>
            <h2>결제수단 선택 - 등록한 결제 수단 있는 경우</h2>
            <button onClick={() => setMenuModal({ visible: true, key: noPayment })}>등록한 결제 수단 있는 경우</button>
          </li>
          <li>
            <h2>결제정보 확인</h2>
            <button onClick={() => setAlertModal({ visible: true, key: confirmPayment })}>error 2 입력 조건은 충족했으나 결제 정보가 조회되지 않을 경우</button>
          </li>
          <li>
            <h2>결제</h2>
            <button onClick={() => setAlertModal({ visible: true, key: faildPayment })}>error 결제에 실패할 경우</button>
          </li>
          <li>
            <h2>가입완료</h2>
            <button onClick={() => setToastList([...toastList, { visible: true, key: successModal }, { visible: true, key: agreeTermsModal }])}>토스트</button>
          </li>
          <li>
            <h2>마이페이지 약관 동의</h2>
            <button onClick={() => setToastList([...toastList, { visible: true, key: agreeTermsModal }])}>토스트</button>
          </li>
        </ul>
      </JoinComponentsContainer>
      <ModalPortal>
        <Modal.Alert key={confirmPayment} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">유효하지 않은 카드입니다. 카드 정보를 다시 확인해주세요.</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: confirmPayment })}>확인</button>
          </section>
        </Modal.Alert>
        <Modal.Alert key={faildPayment} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">결제에 실패했습니다. 사용할 수 있는 카드인지 다시 확인해주세요.</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: faildPayment })}>확인</button>
          </section>
        </Modal.Alert>
        <Modal.Menu key={paymentAvailable} modal="menu" isDim={true} isAnimation={true}>
          <section className="header-wrapper">
            <h2 className="title">결제수단을 확인해주세요</h2>
          </section>
          <section className="content-wrapper">
            <CardContent onClick={() => navigate('/membership/payment/add')}>
              <PlusCircle className="plus-icon" />
              카드 1개 등록 가능
            </CardContent>
          </section>
          <section className="button-wrapper">
            <Button classKey="premium" disabled={true} colorLevel="primary" type="sticky">
              욜크 멤버십 시작
            </Button>
          </section>
        </Modal.Menu>
        <Modal.Menu key={noPayment} modal="menu" isDim={true} isAnimation={true}>
          <section className="header-wrapper">
            <h2 className="title">결제수단을 확인해주세요</h2>
          </section>
          <section className="content-wrapper">
            <CardContent visibleCard={true}>
              <section>
                KB 국민카드<span>************1234</span>
              </section>
              <section>
                <Button colorLevel="secondary" type="small" isActive={true}>
                  변경
                </Button>
              </section>
            </CardContent>
          </section>
          <section className="button-wrapper">
            <Button classKey="premium" disabled={false} colorLevel="primary" type="sticky">
              욜크 멤버십 시작
            </Button>
          </section>
        </Modal.Menu>
      </ModalPortal>
      <ToastPortal>
        <Modal.Toast key={successModal} toastKey={successModal} modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <SignUpCelebration>욜크회원이 되신것을 환영합니다!</SignUpCelebration>
        </Modal.Toast>
        <Modal.Toast key={agreeTermsModal} toastKey={agreeTermsModal} modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <AgreeMentToast>
            <div>야간시간 광고성 정보 알람 수신 동의</div>
            <div>(2022.09.27)</div>
          </AgreeMentToast>
        </Modal.Toast>
      </ToastPortal>
    </>
  );
};

export default JoinComponents;
