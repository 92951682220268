import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const VisitCancelAndNoShowContainer = styled.div<React.CSSProperties>`
  display: flex;
  padding: 40px 32px;
  flex-direction: column;
  gap: 48px;
`;
export const VisitCancelAndNoShowItemContainer = styled.div<React.CSSProperties>`
  display: flex;
  gap: 16px;
  & > div:first-of-type {
    max-width: 208px;
    min-height: 208px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    img {
      max-width: 100%;
      min-height: 100%;
    }
    & > span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      ${Typography.body_28_m};
      color: ${Colors.white};
      background: ${Colors.gray9_opacity40};
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > h3 {
      ${Typography.subtitle_m};
      font-weight: 600;
      color: ${Colors.gray9};
    }
    & > span {
      ${Typography.body_26}
      color: ${Colors.gray6};
    }
  }
`;
