import * as React from 'react';

import styled from '@emotion/styled';

import ActiveMyPage from '@/assets/img/icon/activeMyPage.svg';
import ActiveReservation from '@/assets/img/icon/activeReservation.svg';
import MyPage from '@/assets/img/icon/myPage.svg';
import Reservation from '@/assets/img/icon/Reservation.svg';
import { Typography, Variables } from '@/shared/styles';
import { Colors } from '@/shared/styles/colors';

export const NavWrapper = styled.div`
  width: 100%;
`;

export const Nav = styled.nav`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  ${Variables.maxWidth};
  margin: 0 auto;

  padding-inline: 33px;
  background: ${Colors.white};
  border-top: 2px solid ${Colors.gray2};

  ul {
    display: flex;
    align-items: center;
  }
`;

interface INavButton extends React.CSSProperties {
  active?: boolean;
}

export const NavButton = styled.li<INavButton>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-block: 14px;
  text-align: center;
  cursor: pointer;
  ${Typography.ss_small};
  color: ${(props) => (props.active ? Colors.gray9 : Colors.gray5)};

  &::before {
    content: '';
    width: 48px;
    height: 48px;
    display: block;
  }

  &.reserv-btn::before {
    background: ${(props) => (props.active ? `url(${ActiveReservation})` : `url(${Reservation})`)} no-repeat center center;
  }

  &.mypage-btn::before {
    background: ${(props) => (props.active ? `url(${ActiveMyPage})` : `url(${MyPage})`)} no-repeat center center;
  }
`;

export const NavPadding = styled.div`
  height: 104px;
`;
