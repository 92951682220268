import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const BusinessHoursContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > h1 {
    ${Typography.title_36_sb};
    color: ${Colors.gray9};
    padding-bottom: 20px;
  }
  & > ul {
    & > li {
      ${Typography.body_28};
      color: ${Colors.gray8};
    }
  }
`;
