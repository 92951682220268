import React from 'react';

import styled from '@emotion/styled';

import { Typography, Colors } from '@/shared/styles';

export const CalendarContainer = styled.div<React.CSSProperties>`
  padding: 40px 0 40px;
  .month-handler {
    display: flex;
    justify-content: space-between;
    padding: 0 32px 28px;
    .date-text {
      font-weight: 600;
      ${Typography.title_32_sb}
      line-height: 72px;
    }

    .action-container {
      display: flex;

      .prev-button {
        margin-right: 56px;
      }

      .icon {
        width: 72px;
        height: 72px;
        vertical-align: middle;
      }
    }
  }

  .picker-container {
    position: relative;
    .month-container {
      padding: 0 11px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .week-list {
        display: grid;
        margin-top: 12px;
        grid-template-columns: repeat(7, auto);
        justify-content: space-between;
        .day {
          font-size: 28px;
          display: flex;
          justify-content: center;
          text-align: center;
          color: #464647;
          border: 2px solid transparent;
          padding: 28px 0;
          position: relative;
          min-width: 92px;
          max-width: 92px;
          height: 92px;
          @media (min-width: 640px) and (max-width: 719px) {
            min-width: 80px;
            max-width: 80px;
            height: 80px;
          }
          *.text {
            color: #e9ebee;
          }

          &.deactive {
            color: rgba(51, 51, 51, 0.15) !important;
          }

          &.selected {
            margin: 0;
            border: 2px solid #f6c944;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #f6c944;
            border-radius: 0.5rem;
            color: #272a2d;
          }

          &.sunday {
            color: #ff471e;
          }

          &.saturday {
            color: #2e81f8;
          }
        }
      }
    }
  }
`;

export const ExpressPeriodContainer = styled.div<React.CSSProperties>`
  padding: 0 11px;
  top: 0;
  left: 0;
  width: 100%;
  .week-list {
    display: grid;
    padding-top: 12px;
    grid-template-columns: repeat(7, 1fr);
    justify-content: space-between;
    .day {
      width: 100%;
      height: 92px;
      @media (min-width: 640px) and (max-width: 719px) {
        height: 80px;
      }
      &.express {
        background: ${Colors.blue};
        opacity: 0.2;
        &.first {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &.last {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const ExpressInfoText = styled.div<React.CSSProperties>`
  position: absolute;
  ${Typography.small_b};
  color: ${Colors.blue};
  top: -38px;
  left: -2px;
  width: 104px;
`;
