import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';
import { Typography } from '@/shared/styles/typography';

export const BookingAgreeCheckboxContainer = styled.section<React.CSSProperties>`
  display: flex;
  .expand {
    color: #242424;
    display: flex;
    align-items: center;
    padding-right: 64px;
    .expand-icon {
      width: 36px;
      height: 36px;
    }
  }
  .checkbox-label {
    margin-left: 24px;
    margin-right: 0;
    ${Typography.body_28}

    span:first-of-type {
      color: ${Colors.yellow3};
    }
  }
`;
