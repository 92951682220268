import styled from '@emotion/styled';

import { ButtonContainer } from '@/components/Button/Button.styles';
import { Colors } from '@/shared/styles';

// 기획이 정해지지 않아 임시. isSticky필요 없음
export const MainHeaderContainer = styled.nav<{ scroll: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 17px 32px 0;
  padding-bottom: 0;
  background: ${Colors.white};
  gap: 97px;
  flex-direction: column-reverse;
  justify-content: space-between flex-end;
  ${ButtonContainer} {
    width: fit-content;
    cursor: pointer;
  }
  & > svg {
    width: 100%;
    opacity: ${(props) => 1.5 - props.scroll * 0.01};
  }
  z-index: 2;
`;

export const SmallMainHeaderContainer = styled.nav<{ scroll: number }>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  margin-bottom: 146px;

  padding: 17px 32px 0;
  padding-bottom: 17px;
  background: ${Colors.white};
  gap: 0;
  flex-direction: row;
  justify-content: space-between;
  ${ButtonContainer} {
    width: fit-content;
    cursor: pointer;
  }
  & > svg {
    width: auto;
    opacity: ${(props) => (props.scroll - 90) * 0.01};
  }
`;

export const TopButtonContainer = styled.div`
  z-index: 9999;

  ${ButtonContainer} {
    width: fit-content;
    cursor: pointer;
  }
  & > svg {
    width: auto;
  }
`;
