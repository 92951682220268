import { useNavigate } from 'react-router-dom';

import { useUserProfileQuery } from './Profile.hooks';
import { ProfileContainer } from './Profile.styles';

import MemberShipEgg from '@/assets/img/membership-egg.png';
import NormalEgg from '@/assets/img/normal-egg.png';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';

function Profile() {
  const navigate = useNavigate();
  const { data: userMembership, isLoading: userMembershipLoading } = useUserMembership();
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfileQuery();
  const goToProfilePage = () => {
    navigate('/mypage/edit');
  };

  if (userMembershipLoading || userProfileLoading) {
    return (
      <div>
        <div>loading</div>
      </div>
    );
  }

  if (!userMembership) {
    return null;
  }

  return (
    <ProfileContainer>
      <div onClick={goToProfilePage}>
        <div className="user-photo">
          <img src={userMembership.membership.membershipGrade === '01' ? NormalEgg : MemberShipEgg} alt="에그 프로필 이미지" />
        </div>
        <div className="user-name">{userProfile.user.name}</div>
      </div>
    </ProfileContainer>
  );
}

export default Profile;
