import 'dayjs/locale/ko';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { VisitCompletedContainer, VisitCompletedItemContainer } from '@/components/VisitCompleted/VisitCompleted.styles';
import { goToDetailPage } from '@/hooks/reservationHistory.hook';
import { IReservationHistoryItem, IReservationHistoryItems } from '@/shared/types/ReservationHistory.types';

dayjs.locale('ko');

const VisitCompleted = ({ items }: IReservationHistoryItems): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <VisitCompletedContainer>
      {items.map((item: IReservationHistoryItem) => {
        const { store, reservation } = item;
        const visitDetail = `${dayjs(reservation.visitAt).format('YYYY.MM.DD(dd) ∙ A h:mm ∙ ')}${reservation.totalPartySize}명`;

        return (
          <VisitCompletedItemContainer key={reservation.id} onClick={() => goToDetailPage(navigate, reservation.id)}>
            <div className="img-wrapper">
              <img src={store.imageUrl} alt={`${store.name}이미지`} />
              <span>방문완료</span>
            </div>
            <div className="text-wrapper">
              <h3>{store.name}</h3>
              <span>{visitDetail}</span>
            </div>
          </VisitCompletedItemContainer>
        );
      })}
    </VisitCompletedContainer>
  );
};

export default VisitCompleted;
