import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const UserInfoForm = styled.div<React.CSSProperties>`
  padding: 0 32px 100px;
  .input-label {
    margin-bottom: 8px;
    color: #272a2d;
    ${Typography.small_2}
    .firebrick {
      color: ${Colors.red2};
    }
  }
  /* .certified {
    padding-top: 40px;
      .input-wrapper {
        display: flex;
        .phone-auth-button {
          border-radius: 4px;
          border-color: ${Colors.gray2};
          min-width: 243px;
          padding-inline: 40px;
        }
        > div:first-of-type {
          flex: auto;
          margin-right: 24px;
        }
      }
    } */
  .input-box {
    position: relative;

    &:not(&:first-of-type) {
      margin-top: 40px;
    }

    &.certification-number {
      margin-top: 20px !important;
    }

    &.phone-auth-success input {
      background-color: ${Colors.white};
      border-color: ${Colors.gray4};
    }

    &.email {
      margin-bottom: 20px;
    }
    .input {
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      outline: none;
      ${Typography.body_28}
      &.error {
        border: 2px solid ${Colors.red2};
      }
      &:focus {
        & + .clear-button {
          opacity: 1;
        }
      }
    }
    .phone-change-button {
      position: absolute;
      top: 58px;
      right: 28px;
      padding: 12px 28px;
      border: 2px solid #f6c944;
      border-radius: 48px;
      color: black;
      ${Typography.body_28}
    }
  }
  .phone-auth-button {
    width: 243px;
    padding: 0 !important;
    border-radius: 4px;
    margin-left: 24px;
    &.error {
      margin-bottom: 42px;
    }
  }
  .input-box {
    input.clear-auth {
      border: 2px solid ${Colors.gray4};
      &:disabled {
        background: ${Colors.white};
      }
    }
  }

  input:disabled {
    background: rgba(239, 239, 239, 0.3);
  }
  .phone-auth-box {
    display: flex;
    align-items: flex-end;
  }
`;

interface IEmailNotice extends React.CSSProperties {
  textLength?: number;
}

export const EmailNotice = styled.p<IEmailNotice>`
  display: ${(props) => (props.textLength ? 'none' : '')};
  padding-top: 12px;
  ${Typography.small_1};
  color: ${Colors.gray6};
`;
