import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';

import Lottie from 'lottie-react';

import GlobalConfirmModal from '../GlobalConfirmModal/GlobalConfirmModal';
import GlobalErrorModal from '../GlobalErrorModal/GlobalErrorModal';
import GlobalToastWrapper from '../GlobalToastWrapper/GlobalToastWrapper';
import Navigation from '../Navigation/Navigation';
import SpinnerPotal from '../SpinnerPortal/SpinnerPortal';
import YolkSpinner from '../YolkSpinner/YolkSpinner';
import { useAxiosInterceptors, useCustomHistoryBackHooks } from './Layout.hooks';
import { LayoutContainer, PullRefreshContainer } from './Layout.styles';

import YolkJumping from '@/assets/img/icon/yolk_loading.json';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import { useUserProfileQuery } from '@/components/MyPage/Profile/Profile.hooks';
import { useQueryMallList } from '@/hooks/mainPage.hook';
import { LocalStorage } from '@/shared/configs/storage';
import { useLilloSetPage, useLilloSignUp } from '@/shared/utils/useLilloSdk';
import { useScrollStore } from '@/stores/common/useScrollStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const Layout = ({ children }): React.ReactElement => {
  const [isFullScreen, setIsFullscreen] = useState(true);
  const { isOpenyEggLoading, isOpenIconLoading } = useAxiosInterceptors();
  const _ = useCustomHistoryBackHooks();
  const layoutRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const { memberId, name, phone, setMemberId } = useUserProfileStore();
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfileQuery();
  const { lastScrollY, setLastScrollY } = useScrollStore();
  const navigate = useNavigate();
  const { refetch } = useQueryMallList();

  useEffect(() => {
    if (lastScrollY && location.pathname === '/main') {
      layoutRef?.current?.scrollTo(0, lastScrollY);
      setLastScrollY(0);
    } else {
      layoutRef?.current?.scrollTo(0, 0);
    }

    useLilloSetPage(location);
    setIsFullscreen(location.pathname === '/' || location.pathname === '/auth/signin' || location.pathname === '/login/social');
    const moveLogin =
      !LocalStorage.getItem('jwtToken') && LocalStorage.getItem('loginType') !== 'none' && !(location.pathname.includes('auth') || location.pathname === '/login/social');
    if (moveLogin) {
      navigate('/auth/signin');
    }
  }, [location.pathname]);

  useEffect(() => {
    const storageMemberId = LocalStorage.getItem('memberId');
    if (storageMemberId) {
      setMemberId(storageMemberId);
    }
  }, []);

  useEffect(() => {
    if (userProfile) {
      const data = {
        memberId: memberId,
        email: memberId,
        name: userProfile.user.name,
        phone: userProfile.user.phone,
        // email: userProfile.user
      };
      useLilloSignUp(data);
    }
  }, [userProfile]);

  const pullRefreshStoreList = () => {
    return refetch();
  };

  return (
    <>
      <PullToRefresh
        onRefresh={pullRefreshStoreList}
        isPullable={location.pathname === '/main'}
        pullingContent=""
        refreshingContent={
          <PullRefreshContainer>
            <Lottie animationData={YolkJumping} />
          </PullRefreshContainer>
        }
      >
        <>
          <LayoutContainer ref={layoutRef}>
            {!isFullScreen ? <Header containerRef={layoutRef} /> : null}
            {children}
            {!isFullScreen ? <Footer /> : null}
            {!isFullScreen ? <Navigation /> : null}
          </LayoutContainer>
          <GlobalToastWrapper />
          <GlobalErrorModal></GlobalErrorModal>
          <GlobalConfirmModal></GlobalConfirmModal>
          <SpinnerPotal>{isOpenyEggLoading || isOpenIconLoading ? <YolkSpinner /> : null}</SpinnerPotal>
        </>
      </PullToRefresh>
    </>
  );
};
export default Layout;
