import styled from '@emotion/styled';

import CircleCheck from '@/assets/img/membership/circle_check.svg';
import CircleCheckReverse from '@/assets/img/membership/circle_check_reverse.svg';
import { Colors, Typography } from '@/shared/styles';

export const LargeTitle = styled.h2`
  align-items: center;
  text-align: center;
  ${Typography.title_44}
  color: ${Colors.gray9};

  > span {
    ${Typography.small_1};
    color: ${Colors.gray6};
    display: block;
    padding-top: 28px;
  }
`;

export const SmallTitle = styled.h4`
  ${Typography.title_32_b};
  color: ${Colors.gray9};
  text-align: center;

  .yellow {
    color: ${Colors.yellow3};
  }
`;

export const SectionTitle = styled.strong`
  display: inline-block;
  min-width: 120px;
  margin: 0 auto 28px;
  padding: 3px 16px 5px;
  background: ${Colors.gray9};
  border-radius: 50px;
  ${Typography.small_m};
  color: ${Colors.white};
`;

export const MembershipHeader = styled.header`
  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }

  p {
    padding: 20px;
    background: ${Colors.gray0};
    text-align: center;
    ${Typography.body_26};
    color: ${Colors.gray6};

    b {
      ${Typography.body_26_sb};
      color: ${Colors.gray6};
    }
  }
`;

export const DiscountPricesInfo = styled.div`
  text-decoration: line-through;
  color: ${Colors.gray6};
  ${Typography.body_28_b};
  padding-bottom: 16px;

  em {
    padding-left: 8px;
    display: inline-block;
    font-style: normal;
    text-decoration: none;
    color: ${Colors.red2};
  }
`;

export const DescWrapper = styled.article`
  padding-inline: 32px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
`;

export const Desc = styled.section`
  width: 100%;
  padding: 40px 32px;

  &.apply-desc {
    margin-bottom: -40px;
  }

  h3 {
    padding-bottom: 28px;
    text-align: center;
    border-bottom: 2px solid ${Colors.gray1};
    ${Typography.title_32_sb}
    @media (min-width: 640px) and (max-width:719px) {
      font-size: 30px;
    }

    b {
      color: ${Colors.yellow3};
    }
  }

  p {
    padding-top: 28px;
    text-align: center;
    ${Typography.body_28}
    color: ${Colors.gray8};

    @media (min-width: 640px) and (max-width: 719px) {
      font-size: 26px;
    }
    b {
      display: block;
      padding-top: 4px;
      font-weight: 600;
    }
  }
`;

export const BorderLine = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background: ${Colors.white};
  margin-top: 40px;
  border: 4px solid ${Colors.yellow1};
  border-radius: 20px;
  min-height: 256px;

  &::after {
    content: url(${CircleCheckReverse});
    position: absolute;
    bottom: calc(-48px / 2);
    width: 48px;
    height: 48px;
    display: block;
  }
`;

export const HowToUse = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 32px;
  padding-top: 64px;

  ol {
    padding-top: 80px;

    li {
      padding-bottom: 100px;

      &:last-of-type {
        padding-bottom: 80px;
      }

      img {
        max-width: 100%;
      }
    }
  }
`;

export const HowToApply = styled.article`
  display: flex;
  flex-direction: column;
  background: ${Colors.gray0};
  padding: 80px 32px;
`;

export const CheckSection = styled.section`
  width: 100%;
  margin-block: 40px;
  background: ${Colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  ul {
    @media (min-width: 640px) and (max-width: 719px) {
      padding: 40px 52px;
    }
    padding: 40px 72px;
  }
`;

export const InvitationDesc = styled.div`
  ${Typography.body_28};
  @media (min-width: 640px) and (max-width: 719px) {
    ${Typography.body_26}
  }
  text-align: center;
  padding-bottom: 80px;

  strong {
    ${Typography.title_32_b};
    @media (min-width: 640px) and (max-width: 719px) {
      font-size: 30px;
    }
    display: block;
    padding-top: 12px;
  }
`;

export const Notice = styled.section`
  border-top: 2px solid ${Colors.gray2};
  padding-top: 40px;
`;

export const NoticeListWrap = styled.ul`
  padding-top: 40px;

  li {
    display: flex;
    ${Typography.small_1};
    color: ${Colors.gray7};

    &:not(&:first-of-type) {
      padding-top: 16px;
    }

    &:before {
      content: '•';
      display: block;
      margin-right: 8px;
    }
  }
`;

export const AgonizeList = styled.li`
  display: flex;
  align-items: center;
  ${Typography.body_28};
  @media (min-width: 640px) and (max-width: 719px) {
    font-size: 26px;
  }
  color: ${Colors.gray9};

  .red {
    color: ${Colors.red2};
    font-style: normal;
    font-weight: 600;
  }

  div b {
    color: ${Colors.blue};
    font-weight: 600;
  }

  &::before {
    content: url(${CircleCheck});
    width: 48px;
    height: 48px;
    display: inline-block;
    margin-right: 20px;
  }

  &:not(&:first-of-type) {
    margin-top: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  order: 1;
  .primary.sticky {
    ${Typography.title_44}
    height: 156px;
  }
`;
