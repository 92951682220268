import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NoReservationHistory from '@/components/NoReservationHistory/NoReservationHistory';
import Tabs from '@/components/Tabs/Tabs';
import VisitCancelAndNoShow from '@/components/VisitCancelAndNoShow/VisitCancelAndNoShow';
import VisitCompleted from '@/components/VisitCompleted/VisitCompleted';
import VisitScheduled from '@/components/VisitScheduled/VisitScheduled';
import { useReservationHistoryData } from '@/hooks/reservationHistory.hook';
import { INITIAL_TABS_ITEMS } from '@/shared/constants/reservation-history.constants';
import { HistoryAllContainer, HistoryContainer, HistoryTitle } from '@/shared/styles/pages/reservation/history';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

interface IReservationHistory {
  store: {
    name: string;
    imageUrl: string;
  };
  reservation: {
    id: number;
    visitAt: string;
    totalPartySize: number;
    state: number;
  };
}

const History = (): React.ReactElement => {
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('전체');
  const { data: reservationHistoryData, isLoading } = useReservationHistoryData();
  const [planToVisit, setPlanToVisit] = useState<IReservationHistory[]>([]);
  const [visitComplete, setVisitComplete] = useState<IReservationHistory[]>([]);
  const [cancelOrNoShow, setCancelOrNoShow] = useState<IReservationHistory[]>([]);

  useEffect(() => {
    if (reservationHistoryData) {
      setPlanToVisit(reservationHistoryData.list?.filter((item) => item.reservation.state === 0));
      setVisitComplete(reservationHistoryData.list?.filter((item) => item.reservation.state === 1));
      setCancelOrNoShow(reservationHistoryData.list?.filter((item) => item.reservation.state === (2 || 4)));
    }
  }, [reservationHistoryData]);

  const handleOnTabClick = (e) => {
    const selectedId = e.target.getAttribute('data-value');
    setActiveTab(selectedId);
  };

  useEffect(() => {
    setHeaderState({
      title: '예약 내역',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: () => navigate('/main'),
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <HistoryContainer>
      <Tabs onClick={handleOnTabClick}>
        {INITIAL_TABS_ITEMS.map((tab) => {
          return (
            <Tabs.Item key={tab.title} value={tab.title.replace(' ', '')} active={activeTab === tab.title.replace(' ', '')}>
              {tab.title}
            </Tabs.Item>
          );
        })}
      </Tabs>
      {activeTab === '전체' &&
        (reservationHistoryData.list?.length ? (
          <HistoryAllContainer>
            {planToVisit.length ? (
              <>
                <HistoryTitle>
                  방문 예정 <span onClick={() => setActiveTab('방문예정')}>전체보기</span>
                </HistoryTitle>
                <VisitScheduled items={planToVisit.slice(0, 3)} />
              </>
            ) : null}
            {visitComplete.length ? (
              <>
                <HistoryTitle>
                  방문 완료 <span onClick={() => setActiveTab('방문완료')}>전체보기</span>
                </HistoryTitle>
                <VisitCompleted items={visitComplete.slice(0, 3)} />
              </>
            ) : null}
            {cancelOrNoShow.length ? (
              <>
                <HistoryTitle>
                  취소/노쇼 <span onClick={() => setActiveTab('취소/노쇼')}>전체보기</span>
                </HistoryTitle>
                <VisitCancelAndNoShow items={cancelOrNoShow.slice(0, 3)} />
              </>
            ) : null}
          </HistoryAllContainer>
        ) : (
          <NoReservationHistory />
        ))}
      {activeTab === '방문예정' && (planToVisit.length ? <VisitScheduled items={planToVisit} /> : <NoReservationHistory />)}
      {activeTab === '방문완료' && (visitComplete.length ? <VisitCompleted items={visitComplete} /> : <NoReservationHistory />)}
      {activeTab === '취소/노쇼' && (cancelOrNoShow.length ? <VisitCancelAndNoShow items={cancelOrNoShow} /> : <NoReservationHistory />)}
    </HistoryContainer>
  );
};

export default React.memo(History);
