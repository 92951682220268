import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from '@emotion/styled';

import Calendar from '@/components/Calendar/Calendar';
import InfoFooter from '@/components/InfoFooter/InfoFooter';
import PartySizeSelector from '@/components/PartySizeSelector/PartySizeSelector';
import Seperator from '@/components/Seperator/Seperator';
import TimeSelector from '@/components/TimeSelector/TimeSelector';
import { Variables } from '@/shared/styles';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useBookingStore } from '@/stores/useBookingStore';
import { useBookingValidationStore } from '@/stores/useBookingValidationStore';
import { useInfoStore } from '@/stores/useInfoStore';

const InfoPageContainer = styled.div<React.CSSProperties>`
  ${Variables.calcFullHeight};
  overflow: auto;
`;

const BookingStepInfoTime = (): React.ReactElement => {
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const { clear: bookingValidationClear } = useBookingValidationStore();
  const { clear: bookingInfoClear } = useBookingInfoStore();
  const { clear: infoStoreClear } = useInfoStore();

  const navigate = useNavigate();
  const { storeUri: paramUri } = useParams();
  const { setStoreUri, storeUri } = useBookingStore();
  useEffect(() => {
    if (paramUri) {
      setStoreUri(paramUri);
    }
  }, [paramUri]);
  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '예약하기',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: () => navigate('/main', { replace: true }),
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
    bookingValidationClear();
    bookingInfoClear();
    infoStoreClear();
  }, []);
  if (!storeUri) {
    return null;
  }
  return (
    <>
      <InfoPageContainer>
        <PartySizeSelector />
        <Seperator type="line" inline={true} />
        <Calendar />
        <TimeSelector />
      </InfoPageContainer>
      <InfoFooter />
    </>
  );
};

export default BookingStepInfoTime;
