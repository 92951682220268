import React from 'react';

import styled from '@emotion/styled';

export const MembershipContainer = styled.section<React.CSSProperties>`
  width: 100%;
  padding: 21px 32px;
  margin-bottom: 64px;
  background: #ffffff;
  box-shadow: 0px 4px 16px -4px rgba(39, 42, 45, 0.16);
  border-radius: 8px;
  .content {
    display: flex;
    justify-content: space-between;
    .user-name {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      color: #272a2d;
      span {
        margin-left: 8px;
      }
    }
  }
  .membership-status {
    display: flex;
    align-items: center;
    height: 62px;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #272a2d;
    padding: 13px 28px;
    background: #ffffff;
    border: 2px solid #f6c944;
    border-radius: 31px;
  }
`;
