import React, { useEffect, useId, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AdditionalInfoForm from '@/components/AdditionalInfoForm/AdditionalInfoForm';
import BookingInfo from '@/components/BookingInfo/BookingInfo';
import Button from '@/components/Button/Button';
import DetailPartySize from '@/components/DetailPartySize/DetailPartySize';
import MenuSelector from '@/components/MenuSelector/MenuSelector';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import NavigationModal from '@/components/NavigationModal/NavigationModal';
import RequestForm from '@/components/RequestForm/RequestForm';
import UserInfoForm from '@/components/UserInfoForm/UserInfoForm';
import { useDeleteProvisionalReservation } from '@/hooks/deleteProvisionalReservation.hooks';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { ReservationType } from '@/shared/constants/reservationType';
import { validationEmail, validationName, validationPhoneNumber } from '@/shared/validation';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { IFetchPreReservationResult, useBookingStore } from '@/stores/useBookingStore';
import { useBookingValidationStore } from '@/stores/useBookingValidationStore';
import { useDetailInfoStore } from '@/stores/useDetailInfoStore';

const BookingStepDetailinfo = (): React.ReactElement => {
  const [isBlocked, setIsBlocked] = useState(true);
  const { mall, storeUri, reservationInfo, setReservationInfo } = useBookingStore();
  const { provisionalToken, dispatchBookingInfo } = useBookingInfoStore();
  const { policyMenus, setPolicyMenus } = useDetailInfoStore();
  const { setAlertModal } = useModalStore();
  const { validations } = useBookingValidationStore();
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const queryToken = new URLSearchParams(search).get('token');
  const queryReservationType = new URLSearchParams(search).get('reservationType');
  const invalidAdditionalModalKey = useId();
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const { mutate: delProvisionalReservationMutate } = useDeleteProvisionalReservation({ token: provisionalToken, storeUri });

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      user: yup.object({
        name: validationName,
        email: validationEmail,
        phoneNumber: validationPhoneNumber,
      }),
      phoneAuth: yup.boolean().test('PHONE_AUTH_INVALID', '휴대폰 인증을 진행해주세요.', (value) => value),
      phoneAuthCode: yup.string().test('', '', (value) => true),
      detailPartySize: yup.boolean().test('DETAIL_PARTY_SIZE_INVALID', '상세 인원을 인원 수 만큼 선택해주세요.', (value) => {
        if (reservationInfo.partyPolicies.length > 0) {
          return value;
        }
        return true;
      }),
      menuSelector: yup.boolean().test('BOOKING_MENUS_INVALID', '인원 수 만큼 메뉴를 선택해주세요.', (value) => {
        if (policyMenus.length > 0) {
          return value;
        }
        return true;
      }),
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      user: { name: '', email: '', phoneNumber: '' },
      certifiedNumber: '',
      phoneAuth: false,
      detailPartySize: false,
      menuSelector: false,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = methods;

  const handleSubmitNext = (form) => {
    if (validations.additionalInfoError) {
      setAlertModal({ visible: true, key: invalidAdditionalModalKey });
    } else {
      dispatchBookingInfo({ type: 'SET_STATE', value: { user: getValues('user') } });
      setIsBlocked(false);
    }
  };

  const handleOnError = (err) => {
    console.log('err: ', err);
  };

  useEffect(() => {
    setPolicyMenus([]);
    if (!queryToken || provisionalToken) {
      return;
    }
    eggdiningApi
      .authGet<IFetchPreReservationResult>(`/eggdining/reservations/pre?token=${queryToken}`)
      .then((res) => {
        if (queryReservationType) {
          dispatchBookingInfo({ type: 'RAFFLE_PRE_RESERVATION', value: (res as any).bookingInfo });
          dispatchBookingInfo({ type: 'SET_DETAIL_PARTY_SIZE', value: new Array(reservationInfo.partyPolicies.length).fill(0) });
          dispatchBookingInfo({ type: 'CHANGE_PARTY_SIZE', value: 2 });
        } else {
          dispatchBookingInfo({ type: 'SET_STATE', value: JSON.parse(sessionStorage.getItem('bookingInfo')) });
        }
        dispatchBookingInfo({ type: 'SET_EXPIRY_TIME', value: res.preReservation.expireDate * 1000 });
        setReservationInfo(res.store);
      })
      .catch(() => {
        dispatchBookingInfo({ type: 'CLEAR' });
        if (queryReservationType) {
          navigate(`/detail/${storeUri}`);
        } else {
          navigate(`/reservation/${storeUri}/step/info`);
        }
      });
  }, []);

  useEffect(() => {
    if (isBlocked === false) {
      if (queryReservationType === ReservationType.RAFFLE) {
        navigate(`/reservation/${storeUri}/step/payment?token=${queryToken}&reservationType=RAFFLE`);
      } else {
        navigate(`/reservation/${storeUri}/step/payment?token=${provisionalToken}`);
      }
    }
  }, [isBlocked]);

  useEffect(() => {
    setHeaderState({
      title: '예약하기',
      handleGoToBack: () => {
        if (!queryReservationType) {
          delProvisionalReservationMutate();
        }
        navigate(-1);
      },
      handleGoToHome: () => {
        if (!queryReservationType) {
          delProvisionalReservationMutate();
        }
        navigate('/main');
      },
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
  }, []);

  if (!mall) {
    return <></>;
  }

  return (
    <FormProvider {...methods}>
      <BookingInfo queryReservationType={queryReservationType} />
      <UserInfoForm />
      {reservationInfo.partyPolicies.length > 0 ? <DetailPartySize /> : null}
      {policyMenus.length > 0 ? <MenuSelector /> : null}
      {reservationInfo.additionalInfo.filter((item) => item.isEnabled).length > 0 ? <AdditionalInfoForm /> : null}
      <RequestForm />
      <Button
        colorLevel="primary"
        type="sticky"
        onClick={handleSubmit(handleSubmitNext, handleOnError)}
        disabled={Object.keys(errors).length > 0 || validations.additionalInfoError || (reservationInfo.partyPolicies.length && !getValues('detailPartySize'))}
      >
        다음
      </Button>
      <NavigationModal isBlocked={isBlocked} okayText="네" cancelText="아니오" header={true}>
        진행중인 예약이 취소되며
        <br />
        매장상세 페이지로 돌아갑니다.
        <br />
        취소하시겠습니까?
      </NavigationModal>
      <ModalPortal>
        <Modal.Alert key={invalidAdditionalModalKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">{validations.additionalInfoError}</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: invalidAdditionalModalKey })}>확인</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </FormProvider>
  );
};

export default BookingStepDetailinfo;
