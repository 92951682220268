/* eslint-disable comma-dangle */
import React, { useEffect, useId, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as InputClear } from '@/assets/img/icon/input_clear.svg';
import Limited from '@/assets/img/membership/img_invitation.png';
import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { putApplyJoinMembershipData } from '@/hooks/join.hook';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import {
  CompleteDesc,
  InvitationCode,
  InvitationCodeContainer,
  InvitationContainer,
  LimitedImg,
  SuccessCompleteContainer,
  Title,
  TitleSuper,
} from '@/shared/styles/pages/membership/invitation';
import { IErrorInvitationCode } from '@/shared/types/Invitation.type';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useInvitationCodeStore } from '@/stores/useMembershipJoinStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const Invitation = (): React.ReactElement => {
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const navigate = useNavigate();
  const invitationCodeErrorKey = useId();
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>(null);
  const { memberId } = useUserProfileStore();
  const { data: userMembershipState } = useUserMembership();
  const { refetch: checkUserMembershipGrade } = putApplyJoinMembershipData();
  const { setInvitationCode, invitationCode } = useInvitationCodeStore();
  const { setAlertModal } = useModalStore();
  const location = useLocation();

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '욜크 멤버십',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });

    const getCode = location.pathname.split('/');
    if (getCode[getCode.length - 1] !== 'invitation') {
      setInvitationCode(getCode[getCode.length - 1]);
    } else if (!invitationCode.length) {
      setInvitationCode('');
    }
  }, []);

  useEffect(() => {
    if (userMembershipState) {
      checkUserMembershipGrade();
    }
  }, [userMembershipState]);

  const { data: validInvitationCodeData, refetch } = useQuery(
    ['INVITATION_CODE'],
    () => eggdiningApi.get(`/eggdining/users/${memberId}/membership/invite-ticket/validate?code=${invitationCode}`),
    {
      onSuccess: () => {
        navigate('/membership/join');
      },
      onError: (error: IErrorInvitationCode) => {
        console.log(error, 'error');
        setCodeErrorMessage(error.response.data.message);
        setAlertModal({ visible: true, key: invitationCodeErrorKey });
      },
      enabled: false,
      retry: 0,
    }
  );

  const onSubmit = () => {
    refetch();
  };

  const acceptOnlyEngNum = (e) => {
    const regExp = /^[A-Za-z0-9]+$/;
    if (!regExp.test(e.key)) {
      e.preventDefault();
    }
    if (e.code === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <>
      <InvitationContainer>
        <SuccessCompleteContainer>
          <TitleSuper>신청이 완료되었습니다.</TitleSuper>
          <Title>
            모실 수 있게 되면, 문자로
            <br />
            초대코드를 보내드려요.
          </Title>
          <LimitedImg src={Limited} alt="limited 우편 이미지" />
          <CompleteDesc>
            좋은 서비스를 위해 한정인원으로 운영 중입니다.
            <br />
            많은 분을 모시지 못하는 점, 양해 부탁드려요.
          </CompleteDesc>
        </SuccessCompleteContainer>
        <InvitationCodeContainer>
          <h3>초대코드가 있으세요?</h3>
          <InvitationCode>
            <Input
              value={invitationCode}
              onKeyPress={acceptOnlyEngNum}
              onChange={(e) => {
                if (invitationCode.length < 10 || e.target.value.length < invitationCode.length) {
                  setInvitationCode(e.target.value);
                }
              }}
              type="text"
              maxLength={10}
              className="code-input"
              placeholder="초대코드를 입력해주세요."
              clearicon={<InputClear onClick={() => setInvitationCode('')} />}
            />
            <Button
              classKey="code-submit-btn"
              onClick={onSubmit}
              defaultType="button"
              colorLevel="secondary"
              type="large"
              disabled={!/^[A-Za-z0-9]*$/.test(invitationCode) || invitationCode?.length !== 10}
            >
              초대코드 입력
            </Button>
          </InvitationCode>
        </InvitationCodeContainer>
      </InvitationContainer>
      <ModalPortal>
        <Modal.Alert key={invitationCodeErrorKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">{codeErrorMessage}</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: invitationCodeErrorKey })}>닫기</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Invitation;
