import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const ExpressInfoContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding: 64px 32px;
  gap: 20px;
  & > h1 {
    ${Typography.title_36_sb};
    color: ${Colors.gray9};
    padding-bottom: 20px;
  }
  & > h2 {
    ${Typography.subtitle_m};
    color: ${Colors.gray9};
    margin-top: 20px;
  }
  & > ul {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 4px;
    & > li {
      ${Typography.small_2};
      color: ${Colors.gray8};
      list-style: disc;
      & > span {
        color: ${Colors.blue};
      }
    }
  }
`;

export const ExpressOpeningHoursContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background: ${Colors.gray0};
  border-radius: 8px;
  & > h1 {
    text-align: center;
    ${Typography.title_32_b};
    color: ${Colors.blue};
    padding-bottom: 20px;
    &.opened {
      ${Typography.title_36_b}
    }
  }
  & > h3 {
    text-align: center;
    ${Typography.body_28};
    color: ${Colors.gray8};
    padding-bottom: 12px;
  }
  & > span {
    text-align: center;
    ${Typography.small_1};
    color: ${Colors.gray6};
  }
`;
