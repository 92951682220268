import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EgglogoMain } from '@/assets/img/egg_logo_main.svg';
import { ReactComponent as RightArrowGray } from '@/assets/img/icon/rightArrowGray.svg';
import { SOCIAL_LOGIN } from '@/components/GlobalToastWrapper/GlobalToastWrapper.constants';
import { LocalStorage } from '@/shared/configs/storage';
import { SignInPageContainer } from '@/shared/styles/pages/auth/signin';
import { isApp } from '@/shared/utils';
import { useAppleLoginHook } from '@/shared/utils/useAppleSignin';
import { useGoogleLoginHook } from '@/shared/utils/useGoogleSignin';
import { useKakaoSignin } from '@/shared/utils/useKakaoSignin';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const signin = (): React.ReactElement => {
  const navigate = useNavigate();
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const { handleGoogleLogin } = useGoogleLoginHook();
  const { handleAppleLogin } = useAppleLoginHook();
  const { handleKakaoLogin } = useKakaoSignin();
  const [toggleLoaded, setToggleLoaded] = useState(false);
  const { setMemberId } = useUserProfileStore();
  const { setToastList, toastList } = useModalStore();

  const versionCheck = () => {
    const userAgent = window.navigator.userAgent.toLowerCase().split('/');
    const currentVersion = userAgent[userAgent.length - 1];
    const previousVersion = '2.0.0';
    if (currentVersion > previousVersion) {
      return true;
    }
    return false;
  };

  const handleSocialLogin = async (e) => {
    const { name } = e.target;

    const handleNoneLogin = () => {
      LocalStorage.setItem('loginType', 'none');
      navigate('/main');
    };

    await LocalStorage.setItem('social', name);
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isios = /iphone|ipod|ipad/.test(userAgent);
    const versionValidation = isApp() && versionCheck();

    if (name === 'kakao') {
      if (versionValidation) {
        if (isios) {
          window.webkit.messageHandlers.kakaoLogin.postMessage('');
        } else {
          window.Eggapp.kakaoLogin();
        }
      }
      handleKakaoLogin(versionValidation);
    }
    if (name === 'apple') {
      handleAppleLogin();
    }
    if (name === 'google') {
      if (versionValidation) {
        if (isios) {
          window.webkit.messageHandlers.googleLogin.postMessage('');
        } else {
          window.Eggapp.googleLogin();
        }
      }
      handleGoogleLogin();
    }
    if (name === 'none-login') {
      handleNoneLogin();
    }
  };

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setTimeout(() => {
      setToggleLoaded(true);
    }, 100);
    LocalStorage.removeItem('signInName');
    LocalStorage.removeItem('loginType');
  }, []);

  useEffect(() => {
    const memberId = LocalStorage.getItem('memberId');
    const token = LocalStorage.getItem('jwtToken');

    if (memberId && token) {
      setMemberId(memberId);
      setToastList([...toastList, { visible: true, key: SOCIAL_LOGIN }]);
      navigate('/main');
    }
  }, []);

  return (
    <SignInPageContainer className={toggleLoaded ? 'siginin-loaded' : ''}>
      <EgglogoMain className="egglogomain" />
      <section
        className="signin-button-wrapper"
        onClick={(e) => {
          handleSocialLogin(e);
        }}
      >
        <button className="signin-with-kakao" name="kakao">
          카카오톡으로 계속하기
        </button>
        <button className="signin-with-apple" name="apple">
          Apple로 계속하기
        </button>
        <button className="signin-with-google" name="google">
          Google로 계속하기
        </button>
        <button className="none-login-button" name="none-login">
          로그인 없이 둘러보기
          <RightArrowGray className="icon" />
        </button>
      </section>
    </SignInPageContainer>
  );
};

export default signin;
