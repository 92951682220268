import React from 'react';

import { MallDescriptionContainer, MallDescriptionTextContainer } from './MallDescription.styles';

import { useBookingStore } from '@/stores/useBookingStore';

const MallDescription = (): React.ReactElement => {
  const { storeInformation } = useBookingStore().mall;
  return (
    <MallDescriptionContainer>
      <h1>매장안내</h1>
      <MallDescriptionTextContainer>{storeInformation}</MallDescriptionTextContainer>
    </MallDescriptionContainer>
  );
};

export default MallDescription;
