import React from 'react';

import styled from '@emotion/styled';

export const MembershipcancelSuccessContainer = styled.div<React.CSSProperties>`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 80px 32px 0;
  .cancel-success-img {
    margin-bottom: 40px;
  }
  .cancel-success-descrip {
    .comment-name {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 44px;
      line-height: 50px;
      letter-spacing: -0.02em;
      & > span {
        color: #2e81f8;
      }
    }
    .comment-date {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: #393d42;
    }
    .duration {
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: #878d94;
    }
  }
  .bottom-rayout {
    width: 100%;
    bottom: 0px;
    position: fixed;
  }
`;

export const CancelImageWrapper = styled.div<React.CSSProperties>`
  margin-bottom: 40px;
`;
