import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import dayjs from 'dayjs';

import { getBookingInfoState, getInfoState, setInfoState } from './Calendar.constants';
import { CalendarContainer, ExpressInfoText, ExpressPeriodContainer } from './Calendar.styles';
import { CalendarDayBoxProps, ICalendar, ICalendarDate, IReservationDateResult } from './Calendar.type';

import { ReactComponent as ChevronLeft } from '@/assets/img/icon/chevronLeft.svg';
import { ReactComponent as DeactiveChevronLeft } from '@/assets/img/icon/chevronLeftGray.svg';
import { ReactComponent as ChevronRight } from '@/assets/img/icon/chevronRight.svg';
import { ReactComponent as DeactiveChevronRight } from '@/assets/img/icon/chevronRightGray.svg';
import { ReactComponent as Close } from '@/assets/img/icon/Close.svg';
import Banner from '@/assets/img/membership/express.png';
import Button from '@/components/Button/Button';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/booking';
import { useModalStore } from '@/stores/common/useModalStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useBookingStore } from '@/stores/useBookingStore';
import { useInfoStore } from '@/stores/useInfoStore';
import { useMembershipInfoStore } from '@/stores/useMembershipInfoStore';

const CalendarDayBox = React.memo(({ data, index, setMonthGap, showExpressBadge, express, setOpenTime, expressReservationRestriction }: CalendarDayBoxProps) => {
  const { viewDate, calendarDirty } = useInfoStore(getInfoState);
  const { setViewDate, setCalendarDirty, setTimeDirty } = useInfoStore(setInfoState);
  const { setMenuModal, setOpenGlobalErrorModal } = useModalStore();
  const { membershipGrade } = useMembershipInfoStore();

  const cssClass = ['day', 'body_28'];

  if (index === 0) {
    cssClass.push('sunday');
  }
  if (index === 6) {
    cssClass.push('saturday');
  }
  if (viewDate.isSame(data.value, 'date') && calendarDirty) {
    cssClass.push('selected');
  }
  if (!data.selectable) {
    cssClass.push('deactive');
  }

  const handleOnDaySelect = () => {
    if (express && expressReservationRestriction) {
      setOpenGlobalErrorModal({ visible: true, message: '이번 달 해당 매장에서 사용할 수 있는 익스프레스 예약 횟수를 초과하였습니다.', title: '알림', closeText: '닫기' });
    } else if (membershipGrade !== '02' && express) {
      setOpenTime(data.openDate);
      setMenuModal({ visible: true, key: 'egg-reservation-info' });
    } else {
      setViewDate(data.value);
      setTimeDirty(false);
      setCalendarDirty(true);
    }
  };

  return (
    <div onClick={data.selectable ? handleOnDaySelect : null} className={cssClass.join(' ')} key={index}>
      {data.value ? data.value.format('D') : null}
      {showExpressBadge ? <ExpressInfoText>익스프레스</ExpressInfoText> : null}
    </div>
  );
});

const Calendar = (): React.ReactElement => {
  const { calendar, viewDate } = useInfoStore(getInfoState);
  const { setCalendar, setTimes, setCalendarDirty, setTimeDirty } = useInfoStore(setInfoState);
  const { partySize } = useBookingInfoStore(getBookingInfoState);
  const [watchDate, setWatchDate] = useState(dayjs());
  const { setMenuModal } = useModalStore();
  const navigate = useNavigate();
  const storeUri = useBookingStore((store) => store.storeUri);
  const setAlertModal = useModalStore((store) => store.setAlertModal);
  const { storeMembershipPeriod } = useBookingStore().mall;

  const [monthGap, setMonthGap] = useState(viewDate.diff(dayjs(), 'month'));
  const [rawDates, setRawDate] = useState<ICalendarDate[]>(null);
  const [openTime, setOpenTime] = useState<dayjs.Dayjs>();
  const [expressReservationRestriction, setExpressReservationRestriction] = useState(false);
  const { isFetching, refetch: dateRefetch } = useQuery(
    [QUERY_KEYS.MALLDETAIL_GETDATES, partySize, watchDate.clone().add(monthGap, 'M').format('YYYY-MM')],
    () =>
      eggdiningApi.authGet<IReservationDateResult>(
        `eggdining/stores/${storeUri}/reservation/date?partySize=${partySize}&baseMonth=${watchDate.clone().add(monthGap, 'M').format('YYYY-MM')}&duration=3`
      ),
    {
      onSuccess: (res) => {
        const data = res.schedules;
        const dates: ICalendarDate[] = [];
        setExpressReservationRestriction(res.expressReservationRestriction);
        data.forEach((item) => {
          const date = dayjs(item.date, 'YYYY-MM-DD');
          const selectable = item.state !== '00';
          const inExpressPeriod = item.state === '02';
          let openDate = null;
          if (inExpressPeriod) {
            openDate = dayjs(item.dateOpenToEveryone);
          }
          dates.push({
            date,
            selectable,
            inExpressPeriod,
            openDate,
          });
        });
        setRawDate(dates);

        const tmpCalendar: ICalendar = {
          data: [],
        };
        const dayOfMonth = dates[0].date.clone().endOf('month').date();
        const firstOfMonth = dates[0].date.clone().startOf('month');
        const countOfMonth = Math.ceil((firstOfMonth.day() + dayOfMonth) / 7);

        for (let j = 0; j < countOfMonth; j += 1) {
          tmpCalendar.data.push([]);
        }
        for (let j = 0; j < dates[0].date.day(); j += 1) {
          tmpCalendar.data[0].push({
            value: null,
            selectable: false,
            inExpressPeriod: false,
            openDate: null,
          });
        }
        for (let i = 0; i < dayOfMonth; i += 1) {
          const date = dates[i].date;
          const firstDay = date.clone().startOf('month').day();
          const weekOfMonth = Math.ceil((date.date() + firstDay) / 7) - 1;
          tmpCalendar.data[weekOfMonth].push({
            value: date,
            selectable: dates[i].selectable,
            inExpressPeriod: dates[i].inExpressPeriod,
            openDate: dates[i].openDate,
          });
        }

        while (tmpCalendar.data[tmpCalendar.data.length - 1].length < 7) {
          tmpCalendar.data[tmpCalendar.data.length - 1].push({
            value: null,
            selectable: false,
            inExpressPeriod: false,
            openDate: null,
          });
        }
        setCalendar(tmpCalendar);
      },
      onError: (res: any) => {
        if (res.result) {
          if (res.days.length === 0) {
            setAlertModal({ notice: '매장 사정으로 인해 현재 예약 가능한 일자가 없습니다. 양해 부탁드립니다.' });
            setCalendar(null);
            setTimes(null);
          }
        }
      },
      enabled: Boolean(storeUri && partySize && watchDate) && partySize > 0,
    }
  );

  const firstExpressDate = useMemo(() => {
    if (rawDates) {
      const firstDate = rawDates.find((item) => item.inExpressPeriod);
      if (firstDate) {
        return firstDate.date;
      }
    }
    return null;
  }, [rawDates]);

  const dateMinus = useMemo(() => {
    if (monthGap > 0) {
      return true;
    }
    return false;
  }, [calendar, monthGap]);

  const datePlus = useMemo(() => {
    if (rawDates) {
      const dayOfMonth = rawDates[0].date.clone().endOf('month').date();
      const targetItem = rawDates.find((item, idx) => item.selectable && idx >= dayOfMonth);
      if (targetItem) {
        return true;
      }
    }
    return false;
  }, [calendar, monthGap, rawDates]);

  const handleMoveMonth = (index) => {
    setCalendarDirty(false);
    setTimeDirty(false);
    setMonthGap(monthGap + index);
  };

  useEffect(() => {
    dateRefetch();
  }, [monthGap]);

  useEffect(() => {
    setMenuModal({ visible: false, key: 'egg-reservation-info' });
  }, []);

  if (!watchDate || !calendar) {
    return <></>;
  }
  return (
    <CalendarContainer>
      <div className="month-handler">
        <div className="date-text">{watchDate.clone().add(monthGap, 'month').format('YYYY년 M월')}</div>
        <div className="action-container">
          <div className="prev-button">{dateMinus ? <ChevronLeft className="icon" onClick={() => handleMoveMonth(-1)} /> : <DeactiveChevronLeft className="icon" />}</div>
          <div className="next-button">{datePlus ? <ChevronRight className="icon" onClick={() => handleMoveMonth(1)} /> : <DeactiveChevronRight className="icon" />}</div>
        </div>
      </div>
      <div className="picker-container">
        <ExpressPeriodContainer>
          <Fragment>
            <div className="week-list">
              <div className="day"></div>
              <div className="day"></div>
              <div className="day"></div>
              <div className="day"></div>
              <div className="day"></div>
              <div className="day"></div>
              <div className="day"></div>
            </div>
            {calendar.data.map((week, wIndex) => (
              <div className="week-list" key={wIndex}>
                {week.map((day, dIndex, arr) => (
                  <div
                    className={classNames('day', {
                      express: day.inExpressPeriod,
                      first: arr.findIndex((item) => item.inExpressPeriod) === dIndex,
                      last:
                        6 -
                          arr
                            .slice()
                            .reverse()
                            .findIndex((item) => item.inExpressPeriod) ===
                        dIndex,
                    })}
                    key={dIndex}
                  ></div>
                ))}
              </div>
            ))}
          </Fragment>
        </ExpressPeriodContainer>
        <div className="month-container">
          <div className="week-list">
            <div className="day text sunday body_28">일</div>
            <div className="day text body_28">월</div>
            <div className="day text body_28">화</div>
            <div className="day text body_28">수</div>
            <div className="day text body_28">목</div>
            <div className="day text body_28">금</div>
            <div className="day text saturday body_28">토</div>
          </div>
          {calendar.data.map((week, wIndex) => (
            <div className="week-list" key={wIndex}>
              {week.map((day, dIndex) => (
                <CalendarDayBox
                  key={dIndex}
                  data={day}
                  index={dIndex}
                  setMonthGap={setMonthGap}
                  showExpressBadge={firstExpressDate?.isSame(day.value, 'd')}
                  express={day.inExpressPeriod}
                  setOpenTime={setOpenTime}
                  expressReservationRestriction={expressReservationRestriction}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <ModalPortal>
        <Modal.Menu key="egg-reservation-info" modal="menu" isDim={true} isAnimation={true}>
          <section className="header-wrapper">
            <h2 className="title">
              {storeMembershipPeriod.current.commonOpenTime ? (
                <>
                  에그회원은 <b>{openTime?.diff(dayjs().startOf('d'), 'd') > 0 ? `${openTime?.diff(dayjs().startOf('d'), 'd')}일` : '잠시'}</b> 후에 <br />
                  예약할 수 있는 날짜예요.
                </>
              ) : (
                <>
                  에그회원은 <br /> 예약할 수 없는 매장이에요.
                </>
              )}
            </h2>
            <Close onClick={() => setMenuModal({ visible: false, key: 'egg-reservation-info' })} />
          </section>
          <section className="content-wrapper">
            지금 예약하고 싶으신 분~?
            <br />
            욜크 멤버십 둘러보고 가세요!<img className="alert-express-img" src={Banner} alt="익스프레스 이미지"></img>
          </section>
          <section className="button-wrapper">
            <Button colorLevel="secondary" type="sticky" onClick={() => setMenuModal({ visible: false, key: 'egg-reservation-info' })}>
              다른 날짜 선택
            </Button>
            <Button colorLevel="primary" type="sticky" onClick={() => navigate('/membership/entry', { replace: true })}>
              멤버십 가입
            </Button>
          </section>
        </Modal.Menu>
      </ModalPortal>
    </CalendarContainer>
  );
};

export default Calendar;
