import React from 'react';

import styled from '@emotion/styled';

import { ImageCarouselContainer, ImageMainCarouselItem } from '@/components/ImageCarousel/ImageCarousel.styles';

export const MainContainer = styled.section<React.CSSProperties>`
  padding: 64px 32px 0 32px;

  img {
    width: 576px;
    height: 768px;
  }

  & > ${ImageCarouselContainer} {
    ${ImageMainCarouselItem} {
      height: 312px;
      border-radius: 8px;
    }
  }
`;
