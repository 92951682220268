import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { deleteUserPayment, useAgreeTermsReactHookForm, useQueryChangeUserTerms, useQueryUserTerms } from './AgreeTerms.hooks';

import { ReactComponent as RightArrow } from '@/assets/img/icon/rightArrow.svg';
import { ReactComponent as RightArrowGray } from '@/assets/img/icon/rightArrowGray.svg';
import Button from '@/components/Button/Button';
import { AgreeMentToast, AgreeTermsContainer, AgreeTermsItemsWrapper } from '@/components/EditProfile/AgreeTerms/AgreeTerms.styles';
import { LOGOUT_KEY } from '@/components/GlobalToastWrapper/GlobalToastWrapper.constants';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import PolicyModal from '@/components/PolicyModal/PolicyModal';
import PolicyModalPortal from '@/components/PolicyModalPortal/PolicyModalPortal';
import ToastPortal from '@/components/ToastPortal/ToastPortal';
import { sortPolicies } from '@/hooks/sortPolicies';
import { useFetchPolicies } from '@/hooks/useFetchPolicies';
import { LocalStorage } from '@/shared/configs/storage';
import { ControlledCheckbox } from '@/shared/hook-form/ControlledCheckbox';
import { useModalStore } from '@/stores/common/useModalStore';

const requiredPolicies = ['MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT', 'EGG_SAVE_CARD_INFO'];

const AgreeTerms = (): React.ReactElement => {
  const navigate = useNavigate();
  const { data: userTermsList, isLoading } = useQueryUserTerms(requiredPolicies);
  const { control, handleSubmit, setValue, getValues, watch } = useAgreeTermsReactHookForm();
  const { setToastList, toastList, setAlertModal } = useModalStore();
  const { servicePolicies, loading, openServiceTerm, currentPolicy } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies });
  const { mutateAsync: changeUserTerms } = useQueryChangeUserTerms();
  const { mutate: deleteCardPaymentData, isLoading: deleteCardPaymentLoading } = deleteUserPayment();

  const handleLogout = () => {
    setToastList([...toastList, { visible: true, key: LOGOUT_KEY }]);
    LocalStorage.removeItem('memberId');
    LocalStorage.removeItem('jwtToken');
    LocalStorage.removeItem('provider');
    navigate('/auth/signin');
  };

  const showServiceTerm = (type) => {
    window.history.pushState(null, null, '/mypage/edit');
    openServiceTerm(type);
  };

  watch('agreements');
  useEffect(() => {
    const convertServicePolicies = servicePolicies.map((item) => {
      const find = userTermsList?.response?.find((userTerms) => userTerms.servicePolicyType === item.type);
      if (find) {
        item.agreement = find.agreement;
        item.changeAt = find.createdAt;
      }
      return { ...item };
    });
    if (servicePolicies.length) {
      setValue('agreements', sortPolicies(requiredPolicies, convertServicePolicies));
    }
  }, [servicePolicies, userTermsList]);

  useEffect(() => {
    const findSaveCardDataTermsState = getValues('agreements').find((item) => item.type === 'EGG_SAVE_CARD_INFO');
    if (!deleteCardPaymentLoading) {
      setToastList([...toastList, { visible: true, key: findSaveCardDataTermsState?.id.toString() }]);
    }
  }, [deleteCardPaymentLoading]);

  if (!getValues('agreements')?.length) {
    return;
  }
  if (isLoading || loading) {
    return <></>;
  }

  const submitAgreeTermsHandler = (agreementItem) => {
    if (agreementItem.type === 'EGG_SAVE_CARD_INFO' && agreementItem.agreement) {
      return setAlertModal({ visible: true, key: 'delete' });
    }

    setToastList([...toastList, { visible: true, key: String(agreementItem.id) }]);
    changeUserTerms({
      servicePolicyType: agreementItem.type,
      agreement: !agreementItem.agreement,
    });
  };

  const goWithdrawal = () => {
    navigate('/mypage/withdrawal');
  };

  return (
    <AgreeTermsContainer>
      <div className="agreeterms-title">약관 동의 내역(선택)</div>
      <AgreeTermsItemsWrapper>
        {getValues('agreements').map((agreementItem, i) => (
          <div className="agreeterms-items" key={i}>
            <ControlledCheckbox
              control={control}
              name={`agreements.${i}.agreement`}
              onClick={() => submitAgreeTermsHandler(agreementItem)}
              preventChange={agreementItem.type === 'EGG_SAVE_CARD_INFO' && agreementItem.agreement ? (e) => e.preventDefault() : null}
            >
              <div className="agreement-information">
                <div>{agreementItem.content.KO.name}</div>
                {agreementItem.changeAt && (
                  <div className="days">
                    {dayjs(agreementItem.changeAt).format('YYYY.MM.DD')} {agreementItem.agreement ? '동의' : '철회'}
                  </div>
                )}
              </div>
            </ControlledCheckbox>
            <span className="arrow-icon" onClick={() => showServiceTerm(agreementItem.type)}>
              {agreementItem.content.KO.contentUrl && <RightArrow />}
            </span>
          </div>
        ))}
      </AgreeTermsItemsWrapper>
      <Button classKey="logout" colorLevel="secondary" type="extra-large" onClick={handleLogout}>
        로그아웃
      </Button>
      <div className="withdrawal-button" onClick={goWithdrawal}>
        회원 탈퇴
        <RightArrowGray />
      </div>
      <ModalPortal>
        <Modal.Alert key={'delete'} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">
              철회하실 경우 저장된 카드정보가 삭제됩니다. <br />
              그래도 철회하시겠어요?
            </p>
          </section>
          <section className="button-wrapper">
            <button
              onClick={(e) => {
                setAlertModal({ visible: false, key: 'delete' });
                return e.preventDefault();
              }}
            >
              닫기
            </button>
            <button
              className="red"
              onClick={async () => {
                setAlertModal({ visible: false, key: 'delete' });
                await changeUserTerms({
                  servicePolicyType: 'EGG_SAVE_CARD_INFO',
                  agreement: false,
                });
                deleteCardPaymentData();
              }}
            >
              철회
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
      <ToastPortal>
        {getValues('agreements')?.map((agreement) => {
          return (
            <Modal.Toast key={String(agreement.id)} toastKey={String(agreement.id)} modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
              <AgreeMentToast>
                <div>
                  {agreement.content.KO.name} {agreement.agreement ? '동의' : '철회'}
                </div>
                <div>({dayjs().format('YYYY.MM.DD')})</div>
              </AgreeMentToast>
            </Modal.Toast>
          );
        })}
      </ToastPortal>
      <PolicyModalPortal>
        <PolicyModal openServiceTerm={openServiceTerm} url={currentPolicy}></PolicyModal>
      </PolicyModalPortal>
    </AgreeTermsContainer>
  );
};

export default AgreeTerms;
