import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { MainMallContainer, MainMallFullBadge, MainMallNewBadge } from '@/components/MainMall/MainMall.styles';
import { IMall } from '@/shared/types/MallList.type';
import { useScrollStore } from '@/stores/common/useScrollStore';

const MainMall = ({ mall }): React.ReactElement => {
  const { storeImages, storeFoods, storeName, storeUri, storeState }: IMall = mall;
  const navigate = useNavigate();
  const { setLastScrollY } = useScrollStore();
  const moveDetailMall = () => {
    const layoutRef = document.querySelector('main');
    setLastScrollY(layoutRef.scrollTop);
    navigate(`/detail/${storeUri}`);
  };

  return (
    <MainMallContainer onClick={moveDetailMall}>
      <a>
        {storeState === '09' ? <MainMallFullBadge /> : null}
        {storeState === '01' ? <MainMallNewBadge /> : null}
        <section className="mall-img-wrapper">
          <img src={storeImages[0]} />
        </section>
        <h2>{storeName}</h2>
        <span>{storeFoods.join('∙')}</span>
      </a>
    </MainMallContainer>
  );
};

export default MainMall;
