import React from 'react';

import styled from '@emotion/styled';

import { BookingSubtitleContainer } from '@/components/MainSubtitle/MainSubtitle.constants';
import { Typography } from '@/shared/styles/typography';

export const TimeSelectorContainer = styled.div<React.CSSProperties>`
  margin-top: 40px;
  padding: 0 0 128px;
  ${BookingSubtitleContainer} {
    margin-left: 32px;
  }
  .time-slider-container {
    display: flex;
    overflow: scroll;

    .time-box {
      border: 2px solid #cfd3d9;
      background: #f8f9fa;
      border-radius: 8px;
      color: #aeb4bb;
      height: 70px;
      min-width: 160px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      font-weight: 500;
      ${Typography.body_28}
      &:first-of-type {
        margin-left: 32px;
      }

      &:last-child {
        margin-right: 32px;
      }

      &.selected {
        color: #272a2d;
        border: 2px solid #f6c944;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #f6c944;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const getInfoState = (store) => ({ viewDate: store.viewDate, times: store.times, timeDirty: store.timeDirty, calendarDirty: store.calendarDirty });
export const setInfoState = (store) => ({ setViewDate: store.setViewDate, setTimes: store.setTimes, setTimeDirty: store.setTimeDirty });
