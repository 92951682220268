import React from 'react';

import { IButtonProps } from './Button.types';

import { ButtonContainer } from '@/components/Button/Button.styles';

const Button = ({ onClick, colorLevel, isActive, disabled, type, classKey, children, borderExists, defaultType }: IButtonProps): React.ReactElement => {
  return (
    <ButtonContainer
      disabled={disabled}
      onClick={onClick}
      type={defaultType}
      className={`${colorLevel} ${isActive ? 'active' : null} ${disabled ? 'disabled' : null} ${type} ${classKey} ${borderExists ? 'zero-border' : null}`}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;
