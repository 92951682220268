import React from 'react';

import { NoReservationHistoryContainer } from '@/components/NoReservationHistory/NoReservationHistory.styles';

const NoReservationHistory = (): React.ReactElement => {
  return (
    <NoReservationHistoryContainer>
      <h1>예약 내역이 없습니다</h1>
    </NoReservationHistoryContainer>
  );
};

export default NoReservationHistory;
