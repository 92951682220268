import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const AgreeTermsContainer = styled.div<React.CSSProperties>`
  .agreeterms-title {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -0.02em;
    color: ${Colors.gray9};
  }

  .logout {
    width: 100%;
    margin-bottom: 40px;
  }
  .withdrawal-button {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 40px;
    ${Typography.small_1}
    color: ${Colors.gray6}
  }
`;

export const AgreeMentToast = styled.div<React.CSSProperties>`
  ${Typography.title_32_sb}
  > div {
    color: ${Colors.white};
  }
`;

export const AgreeTermsItemsWrapper = styled.div<React.CSSProperties>`
  & > .agreeterms-items:last-of-type {
    margin-bottom: min(80px);
  }
  .agreeterms-items {
    display: flex;
    /* min-height: 86px; */
    margin-bottom: 54px;
    label {
      align-items: baseline;
    }
    .agreement-information {
      margin-left: 24px;
      > :first-of-type {
        margin-bottom: 8px;
        ${Typography.title_32_sb}
        color: ${Colors.gray9};
      }
    }
    .days {
      ${Typography.small_1}
      color: ${Colors.gray6};
    }
  }
  .arrow-icon {
    padding-top: 2px;
  }
`;
