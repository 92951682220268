import React from 'react';
import { useLocation } from 'react-router-dom';

import { HeaderVisibleExceptions } from './Header.constants';
import { HeaderContainer, LeftSection, RightSection } from './Header.styles';
import { IHeader } from './Header.types';

import { ReactComponent as Alarm } from '@/assets/img/icon/alarm.svg';
import { ReactComponent as AlarmOn } from '@/assets/img/icon/alarm_on.svg';
import { ReactComponent as Back } from '@/assets/img/icon/Back.svg';
import { ReactComponent as Close } from '@/assets/img/icon/Close.svg';
import { ReactComponent as Home } from '@/assets/img/icon/Home.svg';
import { ReactComponent as Share } from '@/assets/img/icon/Share.svg';
import MainHeader from '@/components/MainHeader/MainHeader';
import { useHeaderStore } from '@/stores/common/useHeaderStore';

const Header = ({ containerRef }: IHeader): React.ReactElement => {
  const { headerState } = useHeaderStore();
  const { title, handleGoToBack, handleGoToHome, handleOnAlarm: handleOnAlarm, handleOnAlarmOff, handleOnClose, handleOnShare, alarmOn } = headerState;

  const { pathname } = useLocation();

  if (HeaderVisibleExceptions.includes(pathname)) {
    return <></>;
  }
  if (pathname === '/main') {
    return <MainHeader containerRef={containerRef} />;
  }

  return (
    <HeaderContainer>
      <LeftSection>{handleGoToBack ? <Back onClick={handleGoToBack} /> : null}</LeftSection>
      <h1>{title}</h1>
      <RightSection>
        {handleOnAlarm ? <>{alarmOn ? <AlarmOn onClick={handleOnAlarmOff} /> : <Alarm onClick={handleOnAlarm} />}</> : null}
        {handleGoToHome ? <Home onClick={handleGoToHome} /> : null}
        {handleOnClose ? <Close onClick={handleOnClose} className="close-btn" /> : null}
        {handleOnShare ? <Share onClick={handleOnShare} /> : null}
      </RightSection>
    </HeaderContainer>
  );
};

export default Header;
