import { useNavigate } from 'react-router-dom';

import { AdditionalContent, AdditionalProfileContainer } from './AdditionalProfile.styles';

import { ReactComponent as Mail } from '@/assets/img/icon/mail.svg';
import { ReactComponent as History } from '@/assets/img/icon/reservHistory.svg';
import { ReactComponent as Arrow } from '@/assets/img/icon/rightArrow.svg';
import { mailService } from '@/shared/libs/function/mailService';

function AdditionalProfile() {
  const navigate = useNavigate();

  return (
    <AdditionalProfileContainer>
      <div
        className="additional-profile-content"
        onClick={() => {
          navigate('/reservation/history');
        }}
      >
        <AdditionalContent>
          <i>
            <History />
          </i>
          예약 내역
        </AdditionalContent>
        <Arrow />
      </div>
      <div className="additional-profile-content" onClick={() => mailService('egg@tablemanager.team')}>
        <AdditionalContent>
          <i>
            <Mail />
          </i>
          1:1 문의
        </AdditionalContent>
        <Arrow />
      </div>
    </AdditionalProfileContainer>
  );
}

export default AdditionalProfile;
