import React from 'react';

import styled from '@emotion/styled';

export const ToastPortalContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;
  z-index: 99;
  position: fixed;
  bottom: calc(104px + 40px);
  left: 0px;
`;
