import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BenefitExpress } from '@/assets/img/membership_cancel_express.svg';
import Button from '@/components/Button/Button';
import { STAY_YOLK_MEMBERSHIP } from '@/components/GlobalToastWrapper/GlobalToastWrapper.constants';
import { useMembershipCancel } from '@/hooks/useFetchCancelUserMembership';
import { MembershipCancelContainer } from '@/shared/styles/pages/membership/Cancel';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

interface IMembershipCancelDel {
  response: any;
}

const MembershipCancelPage = (): React.ReactElement => {
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const navigate = useNavigate();
  const { mutate } = useMembershipCancel();
  const { setToastList, toastList } = useModalStore();

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setHeaderState({
      title: '멤버십 해지',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
  }, []);

  return (
    <MembershipCancelContainer>
      <div className="membership-cancel-component">
        <div className="description">
          지금 해지하면 예약 오픈이 <br />
          훨씬 늦어져요
        </div>
        <div className="middle-section">
          <div className="benefit-img">
            <BenefitExpress />
          </div>
        </div>
        <div className="bottom-section">
          <div>그래도 해지하시겠어요?</div>
          <Button
            colorLevel="primary"
            type="extra-large"
            onClick={() => {
              setToastList([...toastList, { visible: true, key: STAY_YOLK_MEMBERSHIP }]);
              navigate('/mypage/membership');
            }}
          >
            아니요, 더 써볼게요
          </Button>
          <Button colorLevel="secondary" type="extra-large" onClick={mutate}>
            네, 해지할게요
          </Button>
        </div>
      </div>
    </MembershipCancelContainer>
  );
};

export default MembershipCancelPage;
