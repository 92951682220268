import {} from './BookingInfoView.type';
import 'dayjs/locale/ko';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { BookingInfoViewContainer } from './BookingInfoView.constants';

import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useBookingStore } from '@/stores/useBookingStore';

const BookingInfoView = (): React.ReactElement => {
  const { dateTime, partySize } = useBookingInfoStore((store) => ({ dateTime: store.dateTime, partySize: store.partySize }));
  const mall = useBookingStore((store) => store.mall);
  const navigate = useNavigate();
  dayjs.locale('ko');

  function goBack() {
    navigate(-1);
  }

  if (!mall || !dateTime) {
    return null;
  }

  return (
    <BookingInfoViewContainer>
      <div className="mall-name t2">{mall.storeName}</div>
      <div className="info-container" onClick={goBack}>
        <div className="reservation-info people">
          <div className="label">
            <span>인원</span>
          </div>
          <div className="value">총 {partySize}명</div>
        </div>
        <div className="reservation-info date">
          <div className="label">
            <span>날짜</span>
          </div>
          <div className="value">{dateTime.format('M월 D일')}</div>
        </div>
        <div className="reservation-info time">
          <div className="label">
            <span>시간</span>
          </div>
          <div className="value">{dateTime.format('LT')}</div>
        </div>
      </div>
    </BookingInfoViewContainer>
  );
};

export default BookingInfoView;
