import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';
import { Typography } from '@/shared/styles/typography';

export const TimerContainer = styled.div<React.CSSProperties>`
  width: 100%;
  height: 65px;
  background-color: #fff1f2;
  position: sticky;
  top: 96px;
  z-index: 10;
  font-size: 28px;
  ${Typography.body_26}
  &.none-header {
    top: 0;
  }
  div {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: ${Colors.red1};

    > b {
      padding-right: 16px;
      color: ${Colors.red1};
      font-weight: 500;
    }
  }
`;

export const TimerPlaceholder = styled.div<React.CSSProperties>`
  width: 100%;
  height: calc(80px);
`;
