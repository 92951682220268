import React, { useEffect, useMemo, useState } from 'react';

import CheckBox from '@/components/CheckBox/CheckBox';
import MainMall from '@/components/MainMall/MainMall';
import { MainMallsContainer } from '@/components/MainMalls/MainMalls.styles';
import { useQueryMallList } from '@/hooks/mainPage.hook';
import { IMallMain } from '@/stores/useBookingStore';

const MainMalls = (): React.ReactElement => {
  const { data, isLoading, isFetching, isError } = useQueryMallList();
  const [reservableFilter, setReservableFilter] = useState<boolean>(false);

  const handleCheckReservationPossible = (e) => {
    setReservableFilter(e.target.checked);
  };

  const malls: IMallMain[] = useMemo(() => {
    if (!data?.stores) {
      return null;
    }
    const tempMalls = JSON.parse(JSON.stringify(data.stores));
    return tempMalls.sort((a, b) => {
      if (b.storeState === '01') {
        return -1;
      }
      if (reservableFilter) {
        return Number(b.isReservable) - Number(a.isReservable);
      }
      return 0;
    });
  }, [data?.stores, reservableFilter]);

  if (isLoading) {
    return null;
  }
  return (
    <MainMallsContainer>
      <CheckBox defaultChecked={false} onChange={handleCheckReservationPossible} checkType="small">
        예약 가능한 매장
      </CheckBox>
      {malls.map((mall, i) => (
        <MainMall key={i} mall={mall} />
      ))}
    </MainMallsContainer>
  );
};

export default MainMalls;
