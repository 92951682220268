import React from 'react';

import styled from '@emotion/styled';

export const CategorySelectorContainer = styled.div<React.CSSProperties>`
  padding: 0 32px;
  font-size: 28px;

  .title {
    margin-bottom: 28px;
  }
  .radio-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    > div {
      margin-bottom: 40px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .radio-custom {
    label {
      align-items: center;
      gap: 16px;
    }
  }
`;
