import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const AdditionalProfileContainer = styled.section<React.CSSProperties>`
  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    ${Typography.body_28};
    color: ${Colors.gray9};
  }
`;

export const AdditionalContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  > i {
    min-width: 40px;
    min-height: 40px;

    > svg {
      vertical-align: middle;
    }
  }
`;
