import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MembershipContainer } from './Membership.styles';

import { ReactComponent as EggBadge } from '@/assets/img/icon/egg_badge.svg';
import { ReactComponent as MembershipBadge } from '@/assets/img/icon/membership-badge.svg';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';

function MemberShip(): React.ReactElement {
  const navigate = useNavigate();
  const { data: userMembership, isLoading } = useUserMembership();

  const handleGoToMembershipManagement = () => {
    if (userMembership.membership.membershipGrade === '01') {
      navigate('/membership/entry', { state: { prevPath: location.pathname } });
    } else {
      navigate('membership');
    }
  };

  if (isLoading) {
    return (
      <div>
        <div>loading</div>
      </div>
    );
  }

  if (!userMembership) {
    return null;
  }

  return (
    <MembershipContainer onClick={handleGoToMembershipManagement}>
      <div className="content">
        <div className="user-name">
          {userMembership.membership.membershipGrade === '01' ? (
            <>
              <EggBadge />
              <span>EGG 회원</span>
            </>
          ) : (
            <>
              <MembershipBadge width="48" height="48" />
              <span>YOLK 회원</span>
            </>
          )}
        </div>
        <div className="membership-status">{userMembership.membership.membershipGrade === '01' ? '업그레이드' : '멤버십 관리'}</div>
      </div>
    </MembershipContainer>
  );
}

export default MemberShip;
