/* eslint-disable comma-dangle */
import { Fragment, useEffect, useId } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Banner from '@/assets/img/membership/membership_banner.jpg';
import Step01 from '@/assets/img/membership/step01.png';
import Step02 from '@/assets/img/membership/step02.png';
import Step03 from '@/assets/img/membership/step03.png';
import Button from '@/components/Button/Button';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import {
  AgonizeList,
  BorderLine,
  ButtonWrapper,
  CheckSection,
  Desc,
  DescWrapper,
  DiscountPricesInfo,
  HowToApply,
  HowToUse,
  InvitationDesc,
  LargeTitle,
  MembershipHeader,
  Notice,
  NoticeListWrap,
  SectionTitle,
  SmallTitle,
} from '@/shared/styles/pages/membership/entry';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useMembershipInfoStore } from '@/stores/useMembershipInfoStore';
import { useSaveEntryPathStore } from '@/stores/useMembershipJoinStore';

const Entry = () => {
  const yolkInvitationCodeAlert = useId();
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const { setEntryPath } = useSaveEntryPathStore();
  const { membershipGrade } = useMembershipInfoStore();
  const { setAlertModal } = useModalStore();

  const navigate = useNavigate();
  const { state: propsState, state: goToMypage } = useLocation();
  const [searchParams] = useSearchParams();
  useUserMembership();

  useEffect(() => {
    setIsNavigationVisible(false);
    setHeaderState({
      title: '욜크 멤버십',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => (goToMypage ? navigate('/mypage') : navigate(-1)),
      handleOnShare: null,
    });
  }, []);

  useEffect(() => {
    if (propsState?.prevPath) {
      const { prevPath } = propsState;
      setEntryPath(prevPath);
    }
  }, []);

  const applyJoinMembership = () => {
    navigate('/membership/invitation');
  };

  return (
    <Fragment>
      <MembershipHeader>
        <img src={Banner} alt="욜크 멤버십 배너" />
        <p>
          욜크 멤버십은 소수를 위한 특별 멤버십으로 <br />
          <b>한정된 인원을 선정</b>하여 운영됩니다.
        </p>
      </MembershipHeader>
      <DescWrapper>
        <SectionTitle>오픈 혜택</SectionTitle>
        <LargeTitle>
          <DiscountPricesInfo>
            정가 월 33,000원<em>약 80% 할인</em>
          </DiscountPricesInfo>
          월 6,900원으로 제공되는 <br />
          익스프레스 예약
          <span>*VAT(부가가치세) 포함</span>
        </LargeTitle>
        <BorderLine>
          <Desc>
            <h3>
              <b>익스프레스 예약</b>이란?
            </h3>
            <p>
              욜크회원에게 먼저 예약기간을 열어 주는 <b>우선예약 혜택이에요.</b>
            </p>
          </Desc>
        </BorderLine>
      </DescWrapper>
      <HowToUse>
        <SmallTitle>익스프레스 예약, 어떻게 사용할까요?</SmallTitle>
        <ol>
          <li>
            <img src={Step01} alt="예약 방법 1 - 이 표시를 찾아보세요! 익스프레스 예약을 운영하고 있는 매장이에요." />
          </li>
          <li>
            <img src={Step02} alt="예약 방법 2 - 캘린더에서 익스프레스 예약 기간을 찾아 예약을 진행해 보세요." />
          </li>
          <li>
            <img src={Step03} alt="예약 방법 3 - 익스프레스 예약이 열리지 않았다면 알림을 신청해보세요. 열리면 알려드릴게요!" />
          </li>
        </ol>
      </HowToUse>
      <HowToApply>
        <SectionTitle>신청 방법</SectionTitle>
        <LargeTitle>
          초대코드를 가진 <br />
          한정된 분들만 모실 수 있어요
        </LargeTitle>
        <CheckSection>
          <Desc className="apply-desc">
            <h3>
              <b>초대코드</b>를 받고 싶어요!
            </h3>
            <p>
              가입버튼을 누르시면 초대코드를 신청할 수 있어요.
              <b>대신 선정과정을 거쳐야 해요.</b>
            </p>
          </Desc>
          <ul>
            <AgonizeList>빨리 신청할수록 선정 확률이 높아져요.</AgonizeList>
            <AgonizeList>
              <div>
                예약내역에 <em className="red">노쇼나 취소</em>가 많은 분들은 거절될 확률이 높아요.
              </div>
            </AgonizeList>
          </ul>
        </CheckSection>
        <InvitationDesc>
          선정된 분들께는 문자메시지로 초대코드를 보내드릴게요.
          <strong>초대코드 입력 후 결제를 마치면 완료!</strong>
        </InvitationDesc>
        <Notice>
          <SmallTitle>유의사항 안내</SmallTitle>
          <NoticeListWrap>
            <li>익스프레스 예약의 양도 및 매매는 불가합니다. 적발 즉시 멤버십이 정지됩니다.(환불 불가)</li>
            <li>매장당 한 달에 최대 2번까지 예약할 수 있습니다. 예약을 취소해도 횟수는 복원되지 않습니다.</li>
            <li>예약의 양도 및 매매를 방지하는 차원에서 같은 날 동시간대 중복 예약은 불가합니다.</li>
            <li>멤버십 해지 시 결제 금액은 환불되지 않습니다.</li>
            <li>특정 레스토랑만을 위한 가입은 추천하지 않습니다.</li>
            <li>위약금 적용기간 내 예약 취소 3회 초과 시 멤버십 자동 해지됩니다.</li>
            <li>위약금 적용기간 외 예약 취소 10회 초과 시 멤버십 자동 해지됩니다.</li>
            <li>노쇼의 경우 조건 없이 멤버십 해지됩니다.</li>
            <li>위 규정 위반 시 재가입은 불가합니다.</li>
            <li>예약 시간 기준 30분 이상 지각 혹은 위반시에는 향후 예약에 대해 불이익을 받을 수 있습니다.</li>
          </NoticeListWrap>
        </Notice>
      </HowToApply>
      {membershipGrade === '02' ? null : (
        <ButtonWrapper>
          <Button classKey="premium" disabled={false} colorLevel="primary" type="sticky" onClick={() => setAlertModal({ visible: true, key: yolkInvitationCodeAlert })}>
            멤버십 가입 신청
          </Button>
        </ButtonWrapper>
      )}

      <ModalPortal>
        <Modal.Alert key={yolkInvitationCodeAlert} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">욜크 멤버십은 초대코드를 발급받고 결제한 후 이용하실 수 있어요. 초대코드 발급을 신청하시겠어요?</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: yolkInvitationCodeAlert })}>아니요</button>
            <button onClick={applyJoinMembership}>초대코드 신청</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </Fragment>
  );
};

export default Entry;
