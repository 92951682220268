import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const CountdownRendererContainer = styled.div<React.CSSProperties>`
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
`;
export const CountdownRendererItemContainer = styled.div<React.CSSProperties>`
  background: ${Colors.white};
  box-shadow: 0px 4px 12px ${Colors.gray2};
  border-radius: 8px;
  text-align: center;
  padding: 6px 16px;
  ${Typography.title_32_sb};
  color: ${Colors.gray8};
  & > span {
    color: ${Colors.blue};
  }
`;
