import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const NoticeContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding: 48px 32px;
  gap: 40px;
  & > h2 {
    ${Typography.subtitle_m};
    color: ${Colors.gray9};
  }
  & > p {
    ${Typography.small_1};
    color: ${Colors.gray8};
    white-space: pre-line;
  }
`;
