import React from 'react';

import { FacilityContainer, FacilityTitle } from './Facility.styles';
import { IFacility } from './Facility.type';

import FacilityViewer from '@/components/FacilityViewer/FacilityViewer';

const Facility = ({ icons, setViewAll }: IFacility): React.ReactElement => {
  return (
    <FacilityContainer>
      <FacilityTitle>
        편의시설<label>{icons.length}</label>
        {icons.length > 4 ? <span onClick={setViewAll}>전체보기</span> : null}
      </FacilityTitle>
      <FacilityViewer setViewAll={setViewAll} icons={icons.slice(0, 4)} />
    </FacilityContainer>
  );
};

export default Facility;
