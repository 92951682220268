import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '../../typography';

export const MembershipCancelContainer = styled.div<React.CSSProperties>`
  width: 100%;
  padding: 80px 32px 0;
  .membership-cancel-component {
    .description {
      ${Typography.title_44}
      margin-bottom: 100px;
    }
    .benefit-img {
      text-align: center;
      margin-bottom: 309px;
    }
    .bottom-section {
      > button {
        width: 100%;
        margin-bottom: 20px;
      }
      > div:first-of-type {
        ${Typography.title_36_sb}
        text-align: center;
        margin-bottom: 40px;
      }
      > :last-of-type {
        margin-bottom: 40px;
      }
    }
  }
`;
export const MaintainMembershipContainer = styled.div<React.CSSProperties>`
  display: flex;
  justify-content: center;
  i {
    margin-right: 12px;
  }
`;
