import React, { Fragment, useId } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Close } from '@/assets/img/icon/Close.svg';
import { ReactComponent as MaintainyolkMembership } from '@/assets/img/icon/maintainyolkMembership.svg';
import Banner from '@/assets/img/membership/express.png';
import Button from '@/components/Button/Button';
import CheckBox from '@/components/CheckBox/CheckBox';
import JoinComponents from '@/components/JoinComponents/JoinComponents';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import ToastPortal from '@/components/ToastPortal/ToastPortal';
import { MaintainMembershipContainer } from '@/shared/styles/pages/MemberShip';
import { useModalStore } from '@/stores/common/useModalStore';

// 512줄이 넘어가는 바람에..
// eslint-disable-next-line max-lines-per-function
const PopupModal = () => {
  const expressModalKey = useId();
  const adAgreementKey = useId();
  const reservCancelKey1 = useId();
  const reservCancelKey2 = useId();
  const reservCancelKey3 = useId();
  const reservCancelKey4 = useId();
  const networkKey = useId();
  const shareModalKey = useId();
  const reRegistration = useId();
  const alarmKey = useId();
  const logoutToastKey = useId();
  const kakaoLoginKey = 'kakao';
  const agreeTermsKey = useId();
  const cancelAgreeTermsKey = useId();
  const textCopySuccess = useId();
  const reservationTimeOut = useId();
  const reservationOpenAlarmCancel = useId();
  const alarmOffModalKey = useId();
  const impossibleReservation = useId();
  const impossibleExpressReservation = useId();
  const impossibleReservationSeat = useId();
  const maintainMembershipKey = useId();
  const reservationOpenAlarm = useId();
  const appleLoginKey = useId();
  const googleLoginKey = useId();
  const { setMenuModal, toastList, setToastList, setAlertModal } = useModalStore();
  const navigate = useNavigate();

  return (
    <Fragment>
      <ul>
        <li>
          <h1>2-2 멤버십 결제</h1>
          <JoinComponents />
        </li>
        <li>
          <h1>익스프레스 예약 진행중 나오는 멤버십 가입 바텀시트</h1>
          <button onClick={() => setMenuModal({ visible: true, key: expressModalKey })}>익스프레스 예약 진입 메뉴모달</button>
          <ModalPortal>
            <Modal.Menu key={expressModalKey} modal="menu" isDim={true} isAnimation={true}>
              <section className="header-wrapper">
                <h2 className="title">
                  에그회원은 <b>7일</b> 후에 <br />
                  예약할 수 있는 날짜예요.
                </h2>
                <Close onClick={() => setMenuModal({ visible: false, key: expressModalKey })} />
              </section>
              <section className="content-wrapper">
                지금 예약하고 싶으신 분~?
                <br />
                욜크 멤버십 둘러보고 가세요!<img src={Banner} alt="익스프레스 이미지"></img>
              </section>
              <section className="button-wrapper">
                <Button colorLevel="secondary" type="sticky">
                  다른 날짜 선택
                </Button>
                <Button onClick={() => navigate('/membership/entry', { replace: true })} colorLevel="primary" type="sticky">
                  멤버십 가입
                </Button>
              </section>
            </Modal.Menu>
          </ModalPortal>
        </li>
        <li>
          <h1>광고성 정보 알림 수신동의 팝업</h1>
          <button onClick={() => setAlertModal({ visible: true, key: adAgreementKey })}>광고성 정보 알림 수신동의 버튼</button>
          <ModalPortal>
            <Modal.Alert key={adAgreementKey} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">광고성 정보 알림 수신 동의</h2>
                <section className="desc">
                  광고성 정보 알림 수신에 동의하고 레스토랑 알림을 받으시겠어요?
                  <div className="desc-in-padding">
                    <CheckBox alignType="fit" checkType="small">
                      <div>21시~08시 사이에도 알림받기</div>
                    </CheckBox>
                  </div>
                </section>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: adAgreementKey })}>취소</button>
                <button onClick={() => setAlertModal({ visible: false, key: adAgreementKey })}>동의</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>예약 가능 일자가 없는 경우 모달 </h1>
          <button onClick={() => setAlertModal({ visible: true, key: impossibleReservation })}>예약 가능 일자가 없는 경우 모달 버튼</button>
          <ModalPortal>
            <Modal.Alert key={impossibleReservation} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">알림</h2>
                <section className="desc">
                  <div>매장 사정으로 인해 현재 예약가능한 일자가 없습니다. 양해 부탁드립니다.</div>
                </section>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: impossibleReservation })}>닫기</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>익스프레스 예약 횟수 초과 시 예약 불가 alert 로드 </h1>
          <button onClick={() => setAlertModal({ visible: true, key: impossibleExpressReservation })}>익스프레스 예약 횟수 초과 시 예약 불가 alert 로드 모달 버튼</button>
          <ModalPortal>
            <Modal.Alert key={impossibleExpressReservation} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">알림</h2>
                <section className="desc">
                  <div>이번 달 해당 매장에서 사용할 수 있는 익스프레스 예약 횟수를 초과하였습니다.</div>
                </section>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: impossibleExpressReservation })}>닫기</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>5-2(3-10 관련) 익스프레스 예약 취소 시 규정 안내</h1>
          <button style={{ display: 'block' }} onClick={() => setAlertModal({ visible: true, key: reservCancelKey1 })}>
            예약금 있는 익스프레스 예약 취소 alert
          </button>
          <button style={{ display: 'block' }} onClick={() => setAlertModal({ visible: true, key: reservCancelKey2 })}>
            예약금 없는 익스프레스 예약 취소 alert
          </button>
          <button style={{ display: 'block' }} onClick={() => setAlertModal({ visible: true, key: reservCancelKey3 })}>
            예약금 있는 일반 예약 취소 alert
          </button>
          <button style={{ display: 'block' }} onClick={() => setAlertModal({ visible: true, key: reservCancelKey4 })}>
            예약금 없는 일반 예약 취소 alert
          </button>
          <ModalPortal>
            <Modal.Alert key={reservCancelKey1} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper lots-of-content-wrapper">
                <h2 className="title">예약 취소 안내</h2>
                <section className="desc">
                  해당 예약은 익스프레스 예약입니다. 같은 매장 내 익스프레스 예약은 월 2회만 가능하며 예약을 취소해도 사용 횟수는 복구되지 않습니다.
                  <div className="refund-table">
                    <h4>환불 안내</h4>
                    <dl>
                      <dt>예약금</dt>
                      <dd>10,000원</dd>
                      <dt>취소 수수료</dt>
                      <dd>3,000원</dd>
                    </dl>
                    <div className="total-wrapper">
                      <em>환불 받을 금액</em>
                      <span>7,000원</span>
                    </div>
                  </div>
                </section>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: reservCancelKey1 })}>닫기</button>
                <button
                  className="red"
                  onClick={() => {
                    setAlertModal({ visible: false, key: reservCancelKey1 });
                  }}
                >
                  예약취소
                </button>
              </section>
            </Modal.Alert>
            <Modal.Alert key={reservCancelKey2} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper lots-of-content-wrapper">
                <h2 className="title">예약 취소 안내</h2>
                <section className="desc">
                  취소한 예약은 복구할 수 없으며, 결제하신 예약금은 취소 수수료 규정에 따라 환불됩니다.
                  <div className="refund-table">
                    <h4>환불 안내</h4>
                    <dl>
                      <dt>예약금</dt>
                      <dd>10,000원</dd>
                      <dt>취소 수수료</dt>
                      <dd>3,000원</dd>
                    </dl>
                    <div className="total-wrapper">
                      <em>환불 받을 금액</em>
                      <span>7,000원</span>
                    </div>
                  </div>
                </section>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>카카오 가입유저 로그인</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: kakaoLoginKey }]);
            }}
          >
            카카오로 로그인 되었다는 토스트
          </button>
          <ToastPortal>
            <Modal.Toast key={kakaoLoginKey} toastKey={kakaoLoginKey} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>Kakao로 로그인 되었습니다.</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <h1>애플 가입유저 로그인</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: appleLoginKey }]);
            }}
          >
            애플 로그인 되었다는 토스트
          </button>
          <ToastPortal>
            <Modal.Toast key={appleLoginKey} toastKey={appleLoginKey} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>Apple로 로그인 되었습니다.</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <h1>구글 가입유저 로그인</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: googleLoginKey }]);
            }}
          >
            Google 로그인 되었다는 토스트
          </button>
          <ToastPortal>
            <Modal.Toast key={googleLoginKey} toastKey={googleLoginKey} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>Google로 로그인 되었습니다.</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <h1>로그아웃 토스트</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: logoutToastKey }]);
            }}
          >
            로그아웃 되었습니다.
          </button>
          <ToastPortal>
            <Modal.Toast key={logoutToastKey} toastKey={logoutToastKey} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>로그아웃 되었습니다.</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <ModalPortal>
            <Modal.Alert key={reservCancelKey4} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">예약 취소하기</h2>
                <section className="desc">예약을 취소하시겠습니까?</section>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: reservCancelKey4 })}>닫기</button>
                <button
                  className="red"
                  onClick={() => {
                    setAlertModal({ visible: false, key: reservCancelKey4 });
                  }}
                >
                  예약취소
                </button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>네트워크 연결이 불안정합니다</h1>
          <button onClick={() => setAlertModal({ visible: true, key: networkKey })}>네트워크 연결이 불안정합니다.</button>
          <ModalPortal>
            <Modal.Alert key={networkKey} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">알림</h2>
                <section className="desc">네트워크 연결이 불안정합니다.</section>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: reservCancelKey4 })}>닫기</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>예약 알림 신청 해제 모달</h1>
          <button onClick={() => setAlertModal({ visible: true, key: alarmOffModalKey })}>알림 해제 버튼.</button>
          <ModalPortal>
            <Modal.Alert key={alarmOffModalKey} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">모퉁이 우 RIPE</h2>
                <p className="desc">
                  알림을 정말 해제할까요?
                  <br />
                  예약이 열려도 푸시 알림이 가지 않습니다.
                </p>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: alarmOffModalKey })}>아니요</button>
                <button onClick={() => {}}>알림 해제</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>예약가능한 좌석이 남아있지 않은 경우 모달</h1>
          <button onClick={() => setAlertModal({ visible: true, key: impossibleReservationSeat })}>예약가능한 좌석이 남아있지 않은 경우 버튼.</button>
          <ModalPortal>
            <Modal.Alert key={impossibleReservationSeat} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">알림</h2>
                <p className="desc">해당 일자에는 가능한 시간이 없습니다. 다른 날짜에 예약해주세요.</p>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: impossibleReservationSeat })}>확인</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>예약 내역 공유</h1>
          <button onClick={() => setAlertModal({ visible: true, key: shareModalKey })}>예약 내역 공유.</button>

          <ModalPortal>
            <Modal.Alert key={shareModalKey} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">공유하기</h2>
                <p className="desc">
                  예약 내역을 다른 사람에게 <br />
                  공유하시겠습니까?
                </p>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: shareModalKey })}>아니요</button>
                <button
                  onClick={() => {
                    setAlertModal({ visible: false, key: shareModalKey });
                  }}
                >
                  텍스트 복사
                </button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>2-4 멤버십 해지 후 재등록</h1>

          <button onClick={() => setAlertModal({ visible: true, key: reRegistration })}>2-4 멤버십 해지 후 재등록.</button>

          <ModalPortal>
            <Modal.Alert key={reRegistration} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">알림</h2>
                <p className="desc">이미 이용하시던 내역이 있어요. 이번에 결제하신 내역은 현재 이용내역이 끝난 시점부터 반영이 됩니다.</p>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: reRegistration })}>확인</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>기기의 알림 설정이 꺼져있어요 모달</h1>
          <button onClick={() => setAlertModal({ visible: true, key: alarmKey })}>기기의 알림 설정이 꺼져있어요 버튼</button>
          <ModalPortal>
            <Modal.Alert key={alarmKey} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">알림</h2>
                <section className="desc">
                  <div>기기의 알림 설정이 꺼져있어요.</div>
                  <div>
                    휴대폰 설정 {'>'} 알림 {'>'} 에그다이닝에서 설정을 변경해주세요.
                  </div>
                </section>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: alarmKey })}>닫기</button>
                <button onClick={() => setAlertModal({ visible: false, key: alarmKey })}>설정 변경하기</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>예약 유효시간이 만료되었습니다 모달</h1>
          <button onClick={() => setAlertModal({ visible: true, key: reservationTimeOut })}>예약 유효시간이 만료되었습니다 모달 버튼</button>
          <ModalPortal>
            <Modal.Alert key={reservationTimeOut} modal="alert" isDim={true} isAnimation={true}>
              <section className="content-wrapper">
                <h2 className="title">알림</h2>
                <section className="desc">
                  <div>예약유효시간이 만료되었습니다.</div>
                </section>
              </section>
              <section className="button-wrapper">
                <button onClick={() => setAlertModal({ visible: false, key: reservationTimeOut })}>닫기</button>
              </section>
            </Modal.Alert>
          </ModalPortal>
        </li>
        <li>
          <h1>약관 동의 토스트</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: agreeTermsKey }]);
            }}
          >
            약관 동의 토스트 버튼
          </button>
          <ToastPortal>
            <Modal.Toast key={agreeTermsKey} toastKey={agreeTermsKey} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>야간시간 광고성 정보 알림 수신 동의</div>
              <div>(2022.09.27)</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <h1>약관 동의 철회 토스트</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: cancelAgreeTermsKey }]);
            }}
          >
            약관 동의 토스트 철회 버튼
          </button>
          <ToastPortal>
            <Modal.Toast key={cancelAgreeTermsKey} toastKey={cancelAgreeTermsKey} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>야간시간 광고성 정보 알림 수신 철회</div>
              <div>(2022.09.27)</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <h1>텍스트 복사 완료 토스트</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: textCopySuccess }]);
            }}
          >
            텍스트 복사 완료 토스트 버튼
          </button>
          <ToastPortal>
            <Modal.Toast key={textCopySuccess} toastKey={textCopySuccess} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>텍스트가 복사되었습니다</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <h1>예약 오픈 알림을 받지 않습니다 토스트</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: reservationOpenAlarmCancel }]);
            }}
          >
            예약 오픈 알림을 받지 않습니다토스트 버튼
          </button>
          <ToastPortal>
            <Modal.Toast key={reservationOpenAlarmCancel} toastKey={reservationOpenAlarmCancel} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>예약 오픈 알림을 받지 않습니다.</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <h1>예약 오픈 알림을 받습니다 토스트</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: reservationOpenAlarm }]);
            }}
          >
            예약 오픈 알림을 받습니다. 토스트 버튼
          </button>
          <ToastPortal>
            <Modal.Toast key={reservationOpenAlarm} toastKey={reservationOpenAlarm} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <div>예약 오픈 알림을 받습니다.</div>
            </Modal.Toast>
          </ToastPortal>
        </li>
        <li>
          <h1>토스트_멤버십 해지 취소</h1>
          <button
            onClick={() => {
              setToastList([...toastList, { visible: true, key: maintainMembershipKey }]);
            }}
          >
            토스트_멤버십 해지 취소 토스트 버튼
          </button>
          <ToastPortal>
            <Modal.Toast key={maintainMembershipKey} toastKey={maintainMembershipKey} modal="toast" autoHideDuration={50000} isAnimation={true} position="bottom">
              <MaintainMembershipContainer>
                <i>
                  <MaintainyolkMembership />
                </i>
                <div>
                  <div>욜크 멤버십이 유지됩니다.</div>
                  <div>더 잘해볼게요!</div>
                </div>
              </MaintainMembershipContainer>
            </Modal.Toast>
          </ToastPortal>
        </li>
      </ul>
    </Fragment>
  );
};

export default PopupModal;
