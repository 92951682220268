import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { cloneDeep } from 'lodash';

import CheckBox from '../CheckBox/CheckBox';
import PolicyModal from '../PolicyModal/PolicyModal';
import PolicyModalPortal from '../PolicyModalPortal/PolicyModalPortal';
import { ITermsGroup } from './TermsGroup.types';

import { ReactComponent as RightArrow } from '@/assets/img/icon/rightArrow.svg';
import { TermsGroupContainer, TermsItem, TermsList } from '@/components/TermsGroup/TermsGroup.styles';
import { ControlledCheckbox } from '@/shared/hook-form/ControlledCheckbox';

const TermsGroup = ({ allCheckComment, onChange = () => {}, openServiceTerm, currentPolicy }: ITermsGroup): React.ReactElement => {
  const { getValues, control, setValue } = useFormContext();
  const allChecked = !getValues('agreements')?.some((agreement) => !agreement.value);
  const { pathname } = useLocation();

  const onChangeAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copyAgreements = cloneDeep(getValues('agreements'))?.map((item) => ({ ...item, value: e.target.checked }));
    setValue('agreements', copyAgreements, { shouldValidate: true, shouldDirty: true });
  };

  const showServiceTerm = (type) => {
    window.history.pushState(null, null, pathname);
    openServiceTerm(type);
  };

  return (
    <TermsGroupContainer className="terms-group-container">
      <div className="terms-group">
        <CheckBox checked={allChecked} onChange={onChangeAllChecked} className="allchecked-items">
          <div>
            <div>{allCheckComment}</div>
          </div>
        </CheckBox>
        <div className="break-bar"></div>
        <TermsList>
          {getValues('agreements')?.map((agreement, i) => {
            return (
              <TermsItem key={agreement.id}>
                <ControlledCheckbox className="checkbox-content-wrapper" key={agreement.id} checkType="empty" control={control} name={`agreements.${i}.value`} onChange={onChange}>
                  <div className="items">
                    {agreement.required ? <span>(필수)&nbsp;</span> : <span className="optional">(선택)&nbsp;</span>}
                    {agreement.content.KO.name}
                  </div>
                </ControlledCheckbox>
                {agreement.content.KO.contentUrl && (
                  <RightArrow
                    onClick={() => {
                      showServiceTerm(agreement.type);
                      return null;
                    }}
                  />
                )}
              </TermsItem>
            );
          })}
        </TermsList>
      </div>
      <PolicyModalPortal>
        <PolicyModal openServiceTerm={openServiceTerm} url={currentPolicy}></PolicyModal>
      </PolicyModalPortal>
    </TermsGroupContainer>
  );
};

export default TermsGroup;
