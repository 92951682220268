import React from 'react';

import { MenuListViewerContainer, MenuListViewerItem } from '@/components/MenuListViewer/MenuListViewer.styles';
import { AddComma } from '@/shared/libs/functions';

const MenuListViewer = ({ menus, type, setViewAll = () => {} }): React.ReactElement => {
  return (
    <MenuListViewerContainer onClick={setViewAll} menuLength={menus.length} type={type}>
      {menus.map((menu, i) => (
        <MenuListViewerItem key={`${menu.menuName}_${i}`}>
          <h2>{menu.menuName}</h2>
          <h2>{AddComma(menu.menuPrice)}원</h2>
        </MenuListViewerItem>
      ))}
    </MenuListViewerContainer>
  );
};

export default MenuListViewer;
