import React, { useEffect, useId, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import hasIn from 'lodash/hasIn';

import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import { useAuthPhoneTokenCheckVerifyStore, useAuthPhoneTokenStore } from './ColreIdentity.sotre';
import { usePhoneOtpQuery } from './CoreIdentity.hooks';
import { ICoreIdentity } from './CoreIdentity.types';

import { ReactComponent as InputClear } from '@/assets/img/icon/input_clear.svg';
import { CoreIdentityContainer } from '@/components/CoreIdentity/CoreIdentity.styles';
import { ControlledInput } from '@/shared/hook-form/ControlledInput';
import { autoHypenPhoneNum } from '@/shared/libs/function/auto-hypen';
import { useModalStore } from '@/stores/common/useModalStore';

/**
 * 설명
 * * @param {myCallback} handleResetUserInfoClick - 번호 초기화
 */
const CoreIdentity = ({ handleResetUserInfoClick, phoneAuthCheckMutate }: ICoreIdentity): React.ReactElement => {
  const sendPhoneAuthModalKey = useId();
  const timeoutPhoneAuthModalKey = useId();
  const { setAlertModal } = useModalStore();
  const { verifyCode, setVerifyCode } = useAuthPhoneTokenCheckVerifyStore();
  const { setAuthPhoneToken } = useAuthPhoneTokenStore();
  const {
    control,
    formState: { errors, dirtyFields },
    resetField,
    setValue,
    getValues,
    watch,
  } = useFormContext();
  const { authPhoneToken } = useAuthPhoneTokenStore();
  const { mutate: phoneAuthMutate, isSuccess: isSuccessSendCode } = usePhoneOtpQuery();
  watch('user.phoneNumber');

  const [now, setNow] = useState(null);
  const [test, setTest] = useState(false);

  const yame = useMemo(() => {
    return dayjs().add(180, 'second');
  }, [test]);

  const diffMilliSecond = useMemo(() => yame.diff(now, 'millisecond'), [now]);

  const diffTimeFormat = useMemo(() => {
    if (!yame || !now) {
      return '';
    }
    if (diffMilliSecond <= 0) {
      // 여기서 3분 초과 로직 작성
      setAlertModal({ visible: true, key: timeoutPhoneAuthModalKey });
      return '00:00';
    }
    return dayjs(diffMilliSecond).format('mm:ss');
  }, [now]);

  const onClickPhoneAuthenticationTest = () => {
    setAlertModal({ visible: true, key: sendPhoneAuthModalKey });
  };

  const resend = () => {
    resetField('certifiedNumber');
    setAuthPhoneToken('');
  };

  const clickPhoneAuthChecked = () => {
    // if (getValues('user.phoneNumber') === '010-2075-1483') {
    //   if (getValues('certifiedNumber') === '439524') {
    //     phoneAuthCheckMutate({
    //       authPhoneToken: 'f55257e51c797882782dba12aa9e36d6',
    //       phoneAuthCode: '439524',
    //     });
    //   } else if (getValues('certifiedNumber') === '654441') {
    //     phoneAuthCheckMutate({
    //       authPhoneToken: '2be05dfd51214849485bc8c1d30c335e',
    //       phoneAuthCode: '654441',
    //     });
    //   } else if (getValues('certifiedNumber') === '027437') {
    //     phoneAuthCheckMutate({
    //       authPhoneToken: 'cf644019491b7ef927c00d41c93efc71',
    //       phoneAuthCode: '027437',
    //     });
    //   }
    //   return;
    // }
    phoneAuthCheckMutate({
      authPhoneToken: authPhoneToken,
      phoneAuthCode: getValues('certifiedNumber'),
    });
  };

  useEffect(() => {
    let i = null;
    if (diffMilliSecond > 0) {
      i = now && setInterval(() => setNow(dayjs()), 1000);
    }

    return () => clearInterval(i);
  }, [now]);

  return (
    <CoreIdentityContainer>
      {!verifyCode ? (
        <div className="information-phone-input">
          <div className="certified">
            <div className="phone-auth">
              <ControlledInput
                control={control}
                disabled={Boolean(authPhoneToken)}
                type="text"
                label="휴대폰 번호"
                name="user.phoneNumber"
                placeholder="010-0000-0000"
                required
                maxLength={13}
                className="phone-auth-input"
                onChange={(e) => setValue('user.phoneNumber', autoHypenPhoneNum(e.target.value))}
                clearicon={<InputClear onClick={() => resetField('user.phoneNumber')} />}
              >
                {!authPhoneToken ? (
                  <Button
                    onClick={() => {
                      onClickPhoneAuthenticationTest();
                      phoneAuthMutate(getValues('user.phoneNumber'));
                      // 시간 때문에 두는 로직으로 삭제 되어야 함
                      setTest(!test);
                      setNow(dayjs());
                      // 시간 때문에 두는 로직으로 삭제 되어야 함
                    }}
                    colorLevel="secondary"
                    type="large"
                    classKey="phone-auth-button"
                    disabled={hasIn(errors, 'user.phoneNumber') || !dirtyFields.user?.phoneNumber || Boolean(verifyCode)}
                  >
                    인증번호 받기
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      resend();
                    }}
                    colorLevel="secondary"
                    type="large"
                    classKey="phone-auth-button"
                  >
                    다시 인증
                  </Button>
                )}
              </ControlledInput>
            </div>
            <div className="certified-number">
              <ControlledInput
                disabled={Boolean(verifyCode) || !authPhoneToken}
                type="text"
                name="certifiedNumber"
                control={control}
                placeholder="인증번호를 입력해주세요."
                className="phone-auth-input"
                timer={diffTimeFormat}
                maxLength={6}
                clearicon={<InputClear onClick={() => resetField('certifiedNumber')} />}
              >
                <Button
                  onClick={clickPhoneAuthChecked}
                  colorLevel="secondary"
                  type="large"
                  classKey="phone-auth-button"
                  disabled={Boolean(errors.certifiedNumber) || !dirtyFields.certifiedNumber || Boolean(verifyCode)}
                >
                  인증번호 확인
                </Button>
              </ControlledInput>
            </div>
          </div>
        </div>
      ) : (
        <div className="input-box phone-auth-success">
          <ControlledInput control={control} type="text" label="휴대폰 번호" name="user.phoneNumber" maxLength={13} required={true} disabled={true} readOnly />
          <div
            className="phone-change-button"
            onClick={() => {
              handleResetUserInfoClick();
              setVerifyCode('');
              setAuthPhoneToken('');
              setNow(null);
            }}
          >
            변경
          </div>
        </div>
      )}
      <ModalPortal>
        <Modal.Alert key={sendPhoneAuthModalKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">
              인증번호를 전송했습니다. 인증번호가 도착하지
              <br />
              않을 경우 다시 인증 버튼을 눌러 다시 시도해 주<br />
              세요.
            </p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: sendPhoneAuthModalKey })}>닫기</button>
          </section>
        </Modal.Alert>
        <Modal.Alert key={timeoutPhoneAuthModalKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">
              유효시간이 만료되었습니다.
              <br />
              다시 시도해 주세요.
            </p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: timeoutPhoneAuthModalKey })}>닫기</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </CoreIdentityContainer>
  );
};

export default CoreIdentity;
