export const Colors = {
  white: '#ffffff',
  black: '#0B0D0F',
  green: '#1BB52A',
  blue: '#2E81F8',
  gray0: '#f8f9fa',
  gray1: '#f1f3f5',
  gray2: '#e9ebee',
  gray3: '#dee1e5',
  gray4: '#cfd3d9',
  gray5: '#aeb4bb',
  gray6: '#878d94',
  gray7: '#4c5157',
  gray8: '#393d42',
  gray9: '#272A2D',
  gray9_opacity40: 'rgba(39, 42, 45, 0.4)',
  red0: '#FFF1F2',
  red1: '#FF5A5F',
  red2: '#FF471E',
  yellow0: '#FDF4DA',
  yellow1: '#F6C944',
  yellow2: '#EBC041',
  yellow3: '#EAB10B',
  yellow_gradation: 'linear-gradient(81.92deg, #F6C944 -6.07%, #F6A444 105.14%);',
};
