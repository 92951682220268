import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const WithdrawalContainer = styled.div<React.CSSProperties>`
  padding: 80px 32px;
  .withdrawal-header {
    text-align: center;
    margin-bottom: 120px;
    .title {
      margin-bottom: 20px;
      ${Typography.title_44}
      color: ${Colors.gray9}
    }
    .description {
      ${Typography.subtitle_m}
      color: ${Colors.gray8}
    }
  }
  .withdrawal-instructions {
    .title {
      margin-bottom: 40px;
      ${Typography.title_36_sb}
    }
    .content-wrapper {
      margin-bottom: 65px;
      ${Typography.body_26}
      > div {
        display: flex;
        margin-bottom: 36px;
        span {
          margin-right: 12px;
        }
      }
    }
    .checkbox-wrapper {
      margin-bottom: 121px;
      .checkbox-coment {
        margin-left: 14px;
        ${Typography.body_26_sb}
        color: ${Colors.gray9}
      }
    }
  }
  .bottom {
    > button {
      width: 100%;
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
`;
