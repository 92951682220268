import styled from '@emotion/styled';

import { Colors } from '../../colors';
import { Typography } from '../../typography';

export const InvitationContainer = styled.main`
  height: 100%;
`;

export const SuccessCompleteContainer = styled.article`
  padding: 80px 32px 100px;
`;

export const TitleSuper = styled.sup`
  ${Typography.title_32_sb};
  color: ${Colors.blue};
  padding-bottom: 8px;
`;

export const Title = styled.h1`
  ${Typography.title_44};
  color: ${Colors.gray9};
`;

export const LimitedImg = styled.img`
  display: block;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  padding-block: 100px;
`;

export const CompleteDesc = styled.section`
  text-align: center;
  ${Typography.body_28};
  color: ${Colors.gray8};

  b {
    display: block;
    padding-top: 20px;
    color: ${Colors.gray9};
  }
`;

export const InvitationCodeContainer = styled.article`
  padding-block: 100px;
  padding-inline: 32px;
  background: ${Colors.yellow0};

  h3 {
    text-align: center;
    ${Typography.title_32_b};
    color: ${Colors.gray9};
  }
`;

export const InvitationCode = styled.form`
  display: flex;
  gap: 24px;
  padding-top: 40px;

  .code-input {
    gap: 0px;
    border-color: ${Colors.gray4};
    &:focus {
      border-color: ${Colors.gray9};
    }
  }

  .large.code-submit-btn {
    border-radius: 4px;
    width: 243px;
    padding: 0;
  }
`;
