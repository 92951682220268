import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '../../colors';

import Applelogo from '@/assets/img/icon/applelogo.svg';
import Googlelogo from '@/assets/img/icon/googlelogo.svg';
import Kakaoballon from '@/assets/img/icon/kakaoballoon.svg';
import { Typography } from '@/shared/styles/typography';

export const SignInPageContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 1s background-color;
  &.yellowbg {
    background-color: #f2c74a;
    svg.egglogomain {
      .yellow-dot {
        transition: fill 1s;
        fill: #231815;
      }
    }
  }

  svg.egglogomain {
    transition: 1s all;
    margin-bottom: 0;
    .yellow-dot {
      fill: #ffc600;
    }
  }
  section.signin-button-wrapper {
    font-family: 'Apple SD Gothic Neo';
    opacity: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: 1s all, 1s opacity 1s;
    flex-direction: column;
    gap: 20px;
    pointer-events: none;
    position: absolute;
    & > button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 26px 0;
      gap: 12px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      letter-spacing: 0;
      width: 656px;
      &.signin-with-kakao {
        background-color: #f6df4c;
        &:before {
          display: block;
          content: '';
          width: 48px;
          height: 48px;
          background-size: 100%;
          background-image: url(${Kakaoballon});
          color: #34201c;
        }
      }
      &.signin-with-apple {
        background-color: #ffffff;
        border: 2px solid #272a2d;
        &:before {
          display: block;
          content: '';
          width: 48px;
          height: 48px;
          background-size: 100%;
          background-image: url(${Applelogo});
        }
      }
      &.signin-with-google {
        background-color: #ffffff;
        border: 2px solid #dee1e5;
        &:before {
          display: block;
          content: '';
          width: 48px;
          height: 48px;
          background-size: 100%;
          background-image: url(${Googlelogo});
        }
      }
      &.none-login-button {
        ${Typography.small_1};
        color: ${Colors.gray6};
        gap: 0;
      }
    }
  }
  &.siginin-loaded {
    svg.egglogomain {
      margin-bottom: 680px;
      .yellow-dot {
        fill: #ffc600;
      }
    }
    section.signin-button-wrapper {
      pointer-events: unset;
      opacity: 1;
    }
  }
`;
