import React from 'react';

import { getFacilityIcon } from './FacilityViewer.constants';

import { FacilityIconItem, FacilityViewerContainer } from '@/components/FacilityViewer/FacilityViewer.styles';

const FacilityViewer = ({ icons, setViewAll = () => {} }): React.ReactElement => {
  return (
    <FacilityViewerContainer onClick={setViewAll} iconLength={icons.length}>
      {icons.map((icon) => (
        <FacilityIconItem key={icon}>
          {getFacilityIcon(icon)}
          <span>{icon}</span>
        </FacilityIconItem>
      ))}
    </FacilityViewerContainer>
  );
};

export default FacilityViewer;
