import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const BookingInfoContainer = styled.section<React.CSSProperties>`
  > div {
    padding-top: 40px;
    margin: 0 32px;
    font-size: 30px;
    line-height: 40px;
    color: #575b63;
    .mall-name {
      margin-bottom: 8px;
      ${Typography.title_40}
    }
    .info-text {
      color: ${Colors.gray5};
      margin-bottom: 64px;
      ${Typography.body_28}
    }
  }
`;

export const BigSizeBookingInfoTimer = styled.div<React.CSSProperties>`
  margin: 0 32px;
  /* 큰 타이머 */

  background-color: ${Colors.red0};
  border-radius: 8px;
  padding: 28px 60px 28px 48px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;
  @media (min-width: 640px) and (max-width: 719px) {
    padding: 28px 32px;
  }
  .time {
    color: ${Colors.red1};
    font-weight: 500;
    ${Typography.title_32_m}
  }

  .text {
    color: ${Colors.red1};
    ${Typography.body_26}
  }
  .small-size {
    background-color: red;
  }
`;

export const SmallSizeBookingInfoTimer = styled.div<React.CSSProperties>`
  /* small 타이머 */
  position: sticky;
  top: 96px;
  z-index: 999;

  background-color: ${Colors.red0};
  padding: 16px 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media (min-width: 640px) and (max-width: 719px) {
    padding: 28px 32px;
  }
  .time {
    color: ${Colors.red1};
    font-weight: 500;
    ${Typography.body_26_m}
  }

  .text {
    color: ${Colors.red1};
    ${Typography.body_26}
  }
  .small-size {
    background-color: red;
  }
`;

export const TimerInterserction = styled.div<React.CSSProperties>`
  margin-top: -120px;
  margin-bottom: 220px;
`;
