import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EgglogoMain } from '@/assets/img/egg_logo_main.svg';
import { SignInPageContainer } from '@/shared/styles/pages/auth/signin';
import { useHeaderStore } from '@/stores/common/useHeaderStore';

const InitialPage = (): React.ReactElement => {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigation = useNavigate();
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  useEffect(() => {
    setHeaderState({
      title: null,
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
    setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    setTimeout(() => {
      navigation('/auth/signin', { replace: true });
    }, 1000);
  }, [isLoaded]);
  return (
    <>
      <SignInPageContainer className={!isLoaded ? 'yellowbg' : ''}>
        <EgglogoMain className="egglogomain" />
      </SignInPageContainer>
    </>
  );
};

export default InitialPage;
