import React, { Fragment } from 'react';

import { DepositCategoryWrapper, DepositListWrapper, DepositNoticeContainer, PaymentInDepositNotice } from './DepositNotice.constants';
import { IDepositNotice } from './DepositNotice.type';

import { ReactComponent as InfoIcon } from '@/assets/img/icon/info.svg';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';

const DepositNotice = (props): React.ReactElement => {
  const { type }: IDepositNotice = props;
  const deposit = useBookingInfoStore((state) => state.deposit);
  const menuPrice = useBookingInfoStore((state) => state.menuPrice);
  return (
    <Fragment>
      {type === 'payment' ? (
        <PaymentInDepositNotice>
          <div className="deposit-container">
            <div className="title">예약금</div>
            <DepositListWrapper>
              <li>
                총 결제 금액<span className="money">{(deposit + menuPrice).toLocaleString()}원</span>
              </li>
            </DepositListWrapper>
          </div>
          <div className="noshow-container">
            <div>
              <b className="noshow">No Show</b> (예약을 하고 나타나지 않는 행위) 방지를 위해 예약금 제도를 운영하는 매장입니다. 예약금은 예약 일시 및 인원에 따라 결정되며, 정확한
              금액은 예약 단계에서 확인할 수 있습니다.
            </div>
          </div>
        </PaymentInDepositNotice>
      ) : (
        <DepositNoticeContainer>
          <div className="deposit-container">
            <DepositCategoryWrapper>
              <li>
                총 예약금<span className="money">{(deposit + menuPrice).toLocaleString()}원</span>
              </li>
            </DepositCategoryWrapper>
          </div>
          <hr />

          <div className="noshow-container">
            <b className="noshow">
              <InfoIcon />
            </b>
            <div className="description">
              <div>
                해당 레스토랑은 <span>No show</span>로 인해 발생되는 매장의 피해를 예방하고자 예약금 제도를 운영합니다.
              </div>
            </div>
          </div>
        </DepositNoticeContainer>
      )}
    </Fragment>
  );
};

export default DepositNotice;
