import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';

export const TermsGroupContainer = styled.div<React.CSSProperties>`
  .terms-group {
    .allchecked-items {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      color: ${Colors.gray9};
      label {
        align-items: center;

        > div:last-of-type {
          margin-left: 24px;
        }
      }
    }

    .all-terms-agree {
      border-bottom: 1px;
      margin: 0 0 40px 24px;
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      color: ${Colors.gray9};
    }
    > div {
      > div {
        /* display: flex;
        align-items: center;
        margin-bottom: 32px; */
        /* & :last-of-type {
          background-color: red;
          margin-bottom: 0;
        } */
      }
    }
    .break-bar {
      margin-bottom: 40px;
      height: 2px;
      background: ${Colors.gray3};
    }
    span {
      color: #eab10b;
    }
  }
  .items {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
  }
`;

export const TermsList = styled.div<React.CSSProperties>``;

export const TermsItem = styled.div<React.CSSProperties>`
  display: table;
  margin-bottom: 32px;

  .checkbox-content-wrapper {
    width: fit-content;
    display: table-cell;
    vertical-align: middle;

    .check-img-wrapper {
      margin: -10px -4px;
    }
  }

  :last-of-type {
    margin-bottom: 0;
  }

  .items {
    margin-left: 24px;
    /* > .optional {
      color: ${Colors.gray6};
    } */
  }

  > svg {
    text-align: center;
    display: table-cell;
    vertical-align: top;
  }
`;
