import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';

export const UserInformationContainer = styled.div<React.CSSProperties>`
  .information-title {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #272a2d;
  }
  .information {
    display: flex;
    margin-bottom: 120px;
    > div {
      div:first-of-type {
        margin-bottom: 12px;
      }
    }
    > :first-of-type {
      margin-right: 40px;
      > div {
        color: ${Colors.gray6};
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
      }
    }
    > :not(:first-of-type) {
      > :first-of-type {
        font-weight: 600;
      }
      > div {
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: ${Colors.gray8};
      }
    }
  }
`;
