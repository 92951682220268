import React from 'react';

import classNames from 'classnames';

import { BookingAgreeCheckboxContainer } from './BookingAgreeCheckbox.styles';

import { ReactComponent as Arrow } from '@/assets/img/icon/rightArrow.svg';
import CheckBox from '@/components/CheckBox/CheckBox';

interface IBookingAgreeCheckbox {
  setValue?: any;
  required?: any;
  desc?: any;
  expand?: any;
  expandFunction?: any;
  value?: any;
}

const BookingAgreeCheckbox = ({ setValue, required, desc, expand, expandFunction, value }: IBookingAgreeCheckbox): React.ReactElement => {
  return (
    <BookingAgreeCheckboxContainer key={desc}>
      <CheckBox checkType="empty" checked={value} onChange={setValue}>
        <div className="checkbox-label">
          <span className={classNames('label-text', { required: required })}>{required ? '(필수)' : '(선택)'}</span> <span className={'label-text'}>{desc}</span>
        </div>
      </CheckBox>
      {expand ? (
        <div onClick={expandFunction} className="expand">
          <Arrow className="expand-icon" />
        </div>
      ) : null}
    </BookingAgreeCheckboxContainer>
  );
};

export default BookingAgreeCheckbox;
