import React from 'react';

import { SignUpCelebration } from '../JoinComponents/JoinComponents.styles';
import Modal from '../Modal/Modal';
import ToastPortal from '../ToastPortal/ToastPortal';
import { CONTINUE_KEY, LOGOUT_KEY, SOCIAL_LOGIN, STAY_YOLK_MEMBERSHIP, SUCCESSES_WITHDRAWAL, WELCOME_YOLK_TOAST_KEY } from './GlobalToastWrapper.constants';

import { ReactComponent as MaintainyolkMembership } from '@/assets/img/icon/maintainyolkMembership.svg';
import { GlobalToastWrapperContainer } from '@/components/GlobalToastWrapper/GlobalToastWrapper.styles';
import { MaintainMembershipContainer } from '@/shared/styles/pages/MemberShip';
import { useModalStore } from '@/stores/common/useModalStore';
import { useUserInfoStore } from '@/stores/useUserInfoStore';

const loginProvider = {
  kakao: 'Kakao',
  google: 'Google',
  apple: 'Apple',
};

const GlobalToastWrapper = (): React.ReactElement => {
  useModalStore();
  const { provider } = useUserInfoStore();

  return (
    <GlobalToastWrapperContainer>
      <ToastPortal>
        <Modal.Toast key={WELCOME_YOLK_TOAST_KEY} toastKey={WELCOME_YOLK_TOAST_KEY} modal="toast" autoHideDuration={3000} isAnimation={true} position="bottom">
          <SignUpCelebration>욜크회원이 되신것을 환영합니다!</SignUpCelebration>
        </Modal.Toast>
        <Modal.Toast key={LOGOUT_KEY} toastKey={LOGOUT_KEY} modal="toast" autoHideDuration={3000} isAnimation={true} position="bottom">
          <SignUpCelebration>로그아웃 되었습니다.</SignUpCelebration>
        </Modal.Toast>
        <Modal.Toast key={STAY_YOLK_MEMBERSHIP} toastKey={STAY_YOLK_MEMBERSHIP} modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <MaintainMembershipContainer>
            <i>
              <MaintainyolkMembership />
            </i>
            <div>
              <div>욜크 멤버십이 유지됩니다.</div>
              <div>더 잘해볼게요!</div>
            </div>
          </MaintainMembershipContainer>
        </Modal.Toast>
        <Modal.Toast key={SOCIAL_LOGIN} toastKey={SOCIAL_LOGIN} modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <div>{loginProvider[provider] + '로 로그인 되었습니다.' ?? '알 수 없음'}</div>
        </Modal.Toast>
        <Modal.Toast key={CONTINUE_KEY} toastKey={CONTINUE_KEY} modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <div>에그를 계속 이용합니다.</div>
        </Modal.Toast>
        <Modal.Toast key={SUCCESSES_WITHDRAWAL} toastKey={SUCCESSES_WITHDRAWAL} modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <div>그동안 에그를 이용해주셔서 감사합니다.</div>
        </Modal.Toast>
      </ToastPortal>
    </GlobalToastWrapperContainer>
  );
};

export default GlobalToastWrapper;
