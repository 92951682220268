import React from 'react';

import { AddressContainer, AddressMapInfoContainer } from './Address.styles';

import { ReactComponent as Phone } from '@/assets/img/icon/phone.svg';
import KakaoMap from '@/components/KakaoMap/KakaoMap';
import { callService } from '@/hooks/callService';

const Address = ({ store }): React.ReactElement => {
  return (
    <AddressContainer>
      <h1>주소</h1>
      <KakaoMap lat={store.latitude} lng={store.longitude} address={store.address} />
      <AddressMapInfoContainer>
        <h2>{store.address}</h2>
        <span>지번 : {store.pastAddress}</span>
        <div className="phone-box" onClick={() => callService(store.phone)}>
          <Phone />
          <span className="phone-number">{store.phone}</span>
        </div>
      </AddressMapInfoContainer>
    </AddressContainer>
  );
};

export default Address;
