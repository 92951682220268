import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

interface IInputContainer {
  error: string;
}
export const InputContainer = styled.div<React.CSSProperties | IInputContainer>`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > label {
    padding-bottom: 8px;
    ${Typography.small_2};
    color: ${Colors.gray9};
    & > span {
      color: ${Colors.red2};
    }
  }

  & > div > div {
    position: relative;
    & > input {
      ${Typography.body_28}
      display: flex;
      width: 100%;
      height: 92px;
      padding: 28px;
      gap: 10px;
      background: ${Colors.white};
      border: 2px solid ${(props: any) => (props.error ? Colors.red2 : Colors.gray4)};
      border-radius: 4px;
      color: ${Colors.gray9};

      &::placeholder {
        color: ${Colors.gray4};
      }
      &:focus {
        border: 2px solid ${(props: any) => (props.error ? Colors.red2 : Colors.gray9)};
        outline: none;

        & + svg {
          opacity: 1;
        }
      }
      &:disabled {
        background: ${Colors.gray0};
        border: 2px solid ${Colors.gray2};
        &::placeholder {
          color: ${Colors.gray2};
        }
      }
      &:placeholder-shown {
        & + svg {
          display: none;
        }
      }
    }
    & > svg {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      right: 28px;
      top: calc(50% - 13px);
    }
  }
  .error-wrapper {
    display: flex;
    justify-content: space-between;
    span {
      padding-top: 12px;
      ${Typography.small_1};
      color: ${Colors.red2};
    }
  }
`;
