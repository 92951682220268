import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ActiveRadio from '@/assets/img/icon/activeRadio.svg';
import DeActiveRadio from '@/assets/img/icon/deactiveRadio.svg';
import DisabledRadio from '@/assets/img/icon/disabledRadio.svg';
import { Colors } from '@/shared/styles';

interface IRadioContainer extends React.CSSProperties {
  disabled?: boolean;
  checked?: boolean;
}

export const RadioContainer = styled.div<IRadioContainer>`
  &.radio-custom {
    ${(props) =>
      props.checked &&
      css`
        border-color: ${Colors.yellow1};
      `}

    ${(props) =>
      props.disabled &&
      css`
        border-color: ${Colors.gray2};

        section {
          h5,
          span {
            color: ${Colors.gray2};
          }
        }
      `}
  }

  input {
    display: none;
  }

  label {
    display: flex;
  }

  .radio-img-wrapper {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: -16px;

    .radio-img {
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background: url(${DeActiveRadio});
    }
  }

  input:active + .radio-img-wrapper {
    background: ${(props) => (props.disabled ? null : 'rgba(246, 201, 68, 0.2)')};
  }

  input:checked + .radio-img-wrapper .radio-img {
    background: url(${ActiveRadio});
  }

  input:disabled + .radio-img-wrapper .radio-img {
    background: url(${DisabledRadio});
  }
`;
