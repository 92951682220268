import { Fragment } from 'react';

import { ReactComponent as Babyseat } from '@/assets/img/icon/facility/babyseat.svg';
import { ReactComponent as Bbq } from '@/assets/img/icon/facility/bbq.svg';
import { ReactComponent as Corkage } from '@/assets/img/icon/facility/corkage.svg';
import { ReactComponent as Dis } from '@/assets/img/icon/facility/dis.svg';
import { ReactComponent as Freeparking } from '@/assets/img/icon/facility/freeparking.svg';
import { ReactComponent as Groupseat } from '@/assets/img/icon/facility/groupseat.svg';
import { ReactComponent as Kidsmenu } from '@/assets/img/icon/facility/kidsmenu.svg';
import { ReactComponent as Kidszone } from '@/assets/img/icon/facility/kidszone.svg';
import { ReactComponent as Mechanicalparkinglot } from '@/assets/img/icon/facility/mechanicalparkinglot.svg';
import { ReactComponent as Night } from '@/assets/img/icon/facility/night.svg';
import { ReactComponent as Nursingroom } from '@/assets/img/icon/facility/nursingroom.svg';
import { ReactComponent as Parking } from '@/assets/img/icon/facility/parking.svg';
import { ReactComponent as Pet } from '@/assets/img/icon/facility/pet.svg';
import { ReactComponent as Private } from '@/assets/img/icon/facility/private.svg';
import { ReactComponent as Rooftop } from '@/assets/img/icon/facility/rooftop.svg';
import { ReactComponent as Smoking } from '@/assets/img/icon/facility/smoking.svg';
import { ReactComponent as Sommelier } from '@/assets/img/icon/facility/sommelier.svg';
import { ReactComponent as Spacerental } from '@/assets/img/icon/facility/spacerental.svg';
import { ReactComponent as Stroller } from '@/assets/img/icon/facility/stroller.svg';
import { ReactComponent as Terrace } from '@/assets/img/icon/facility/terrace.svg';
import { ReactComponent as Valetparking } from '@/assets/img/icon/facility/valetparking.svg';
import { ReactComponent as Waiting } from '@/assets/img/icon/facility/waiting.svg';
import { ReactComponent as Wifi } from '@/assets/img/icon/facility/wifi.svg';

export const getFacilityIcon = (icon) => {
  switch (icon) {
    case '아기의자':
      return <Babyseat />;
    case '베이비시트':
      return <Babyseat />;
    case '베이비 시트':
      return <Babyseat />;
    case '바베큐':
      return <Bbq />;
    case '루프탑 BBQ':
      return <Bbq />;
    case '콜키지 (유료)':
    case '코르키지 (유료)':
      return <Corkage />;
    case '콜키지 (무료)':
    case '코르키지 (무료)':
      return <Corkage />;
    case '장애인 시설':
      return <Dis />;
    case '휠체어 대여':
      return <Dis />;
    case '장애인 편의시설':
      return <Dis />;
    case '아동용 메뉴':
      return <Kidsmenu />;
    case '아동메뉴':
      return <Kidsmenu />;
    case '놀이방':
      return <Kidszone />;
    case '놀이터':
      return <Kidszone />;
    case '수유실':
      return <Nursingroom />;
    case '주차공간':
      return <Parking />;
    case '주차가능':
      return <Parking />;
    case '반려동물':
      return <Pet />;
    case '반려견 동반 가능':
      return <Pet />;
    case '프라이빗':
      return <Private />;
    case '프라이빗 룸':
      return <Private />;
    case 'Private Rooms':
      return <Private />;
    case 'Private Room':
      return <Private />;
    case '단체석':
      return <Groupseat />;
    case '루프탑':
      return <Rooftop />;
    case '테라스':
      return <Terrace />;
    case '레이크뷰 테라스(옥상)':
      return <Terrace />;
    case '흡연실':
      return <Smoking />;
    case '소믈리에':
      return <Sommelier />;
    case '발렛파킹':
      return <Valetparking />;
    case '발렛 파킹':
      return <Valetparking />;
    case '발렛파킹가능(입구주차)':
      return <Valetparking />;
    case 'Valet':
      return <Valetparking />;
    case '기계식 주차장':
      return <Mechanicalparkinglot />;
    case '무료주차':
      return <Freeparking />;
    case '대기공간':
      return <Waiting />;
    case '와이파이':
      return <Wifi />;
    case 'Wifi':
      return <Wifi />;
    case '유모차 대어':
      return <Stroller />;
    case '대관 서비스':
      return <Spacerental />;
    case '대관':
      return <Spacerental />;
    case '심야영업':
      return <Night />;
    default:
      return <Fragment />;
  }
};

export const getFacilityName = (icon) => {
  switch (icon) {
    case 'babyseat':
      return '아기의자';
    case 'bbq':
      return '바베큐';
    case 'corkage':
      return '콜키지 (유료)';
    case 'dis':
      return '장애인 시설';
    case 'kidsmenu':
      return '아동메뉴';
    case 'kidszone':
      return '놀이방';
    case 'nursingroom':
      return '수유실';
    case 'parking':
      return '주차공간';
    case 'pet':
      return '반려동물';
    case 'private':
      return '프라이빗';
    case 'rooftop':
      return '루프탑';
    case 'smoking':
      return '흡연실';
    case 'sommelier':
      return '소믈리에';
    case 'valetparking':
      return '발렛파킹';
    case 'waiting':
      return '대기공간';
    case 'wifi':
      return '와이파이';
    default:
      return '';
  }
};
