import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';

export const IdentityContainer = styled.div<React.CSSProperties>`
  padding: 80px 32px;

  .identify-description {
    margin-bottom: 100px;
    > :first-of-type {
      margin-bottom: 28px;
      font-weight: 700;
      font-size: 44px;
      line-height: 50px;
      color: ${Colors.gray9};
    }
    > :last-child {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: ${Colors.gray8};
    }
  }
  .core-information {
    margin-bottom: 100px;
    > :first-of-type {
      margin-bottom: 40px;
    }
  }
`;
export const ButtonWrapper = styled.div<React.CSSProperties>`
  position: sticky;
  bottom: 0;
`;
