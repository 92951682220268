import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles/typography';

export const BookingFooterContainer = styled.div<React.CSSProperties>`
  background: #f6c944;
  color: #ffffff !important;
  text-align: center;
  position: sticky;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  ${Typography.title_36_m}
  line-height: 104px;
  &.disabled {
    background: #f1f3f5;
  }
`;
