import React, { useEffect } from 'react';

import AdditionalProfile from '@/components/MyPage/AdditionalProfile/AdditionalProfile';
import MemberShip from '@/components/MyPage/Membership/Membership';
import Profile from '@/components/MyPage/Profile/Profile';
import { MyPageContainer } from '@/shared/styles/pages/mypage';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

function MyPage() {
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);

  useEffect(() => {
    setIsNavigationVisible(true);
    setIsFooterVisible(false);
  }, []);

  return (
    <MyPageContainer>
      <Profile />
      <MemberShip />
      <AdditionalProfile />
    </MyPageContainer>
  );
}

export default MyPage;
