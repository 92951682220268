import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const ButtonContainer = styled.button<React.CSSProperties>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &.primary {
    background: ${Colors.yellow1};
    color: ${Colors.white};

    &.premium {
      background: ${Colors.yellow_gradation};
    }
    &.yellow3 {
      background: ${Colors.yellow3};
      ${Typography.title_44};
      padding: 55px 0px 51px;
      min-height: 156px;
    }
    &.yellow-gradation {
      background: ${Colors.yellow_gradation};
      ${Typography.title_44};
      padding: 55px 0px 51px;
      min-height: 156px;
    }
    &:hover {
      background: ${Colors.yellow2};
    }
    &.disabled {
      background: ${Colors.gray2};
    }
  }
  &.secondary {
    background-color: ${Colors.white};
    color: ${Colors.gray9};
    border: 2px solid ${Colors.gray4};
    &:hover:not(.disabled) {
      background-color: ${Colors.gray1};
    }
    &.disabled {
      color: ${Colors.gray2};
      border-color: ${Colors.gray2};
    }
  }
  &.sticky {
    width: 100%;
    height: 104px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 5;
    bottom: env(safe-area-inset-bottom);
    ${Typography.title_36_m};
  }
  &.extra-large {
    height: 104px;
    padding: 28px 46px;
    ${Typography.title_32_m};
    border-radius: 8px;
  }
  &.large {
    height: 92px;
    padding: 28px 46px;
    ${Typography.body_28};
    border-radius: 8px;
  }
  &.medium {
    height: 72px;
    padding: 16px 24px;
    ${Typography.body_28};
    border-radius: 8px;
  }
  &.small {
    height: 62px;
    padding: 12px 28px;
    ${Typography.body_28};
    border-radius: 32px;
  }
  &.extra-small {
    height: 40px;
    padding: 4px 16px;
    border-radius: 32px;
    ${Typography.small_1};
  }
  &.active {
    border-color: ${Colors.yellow1};
  }
  &.zero-border {
    border-width: 0;
  }
`;
