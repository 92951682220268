import React from 'react';

import styled from '@emotion/styled';

import Tel from '@/assets/img/icon/tel.svg';
import { AddressMapInfoContainer } from '@/components/Address/Address.styles';
import { Colors, Typography } from '@/shared/styles';

export const HistoryDetailContainer = styled.div<React.CSSProperties>`
  padding: 80px 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  & > span {
    ${Typography.title_32_sb};
    color: ${Colors.gray5};
    padding-bottom: 8px;

    &.successCancel {
      color: ${Colors.red2};
    }
  }
  & > h1 {
    ${Typography.title_44};
    color: ${Colors.black};
  }
  & > h2 {
    ${Typography.title_36_sb};
    color: ${Colors.gray9};
    padding-top: 100px;
  }
  & > ${AddressMapInfoContainer} {
    margin-top: 20px;
    & > a {
      margin-top: 16px;
      ${Typography.body_28};
      color: ${Colors.blue};
      padding-left: 35px;
      background-size: 25px 25px;
      background-image: url(${Tel});
      background-repeat: no-repeat;
      background-position: 0;
    }
  }

  .kakao-address {
    margin-top: 40px;
  }
`;

export const ReservationDetailContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 12px;
`;

export const ReservationDetailItem = styled.div<React.CSSProperties>`
  display: flex;
  gap: 40px;
  & > span {
    flex-basis: 97px;
    flex-shrink: 0;
    ${Typography.body_28};
    color: ${Colors.gray6};
  }
  & > p {
    ${Typography.body_28};
    color: ${Colors.gray8};
  }
`;
