import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const MallTitleContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding: 40px 32px 48px 32px;
  & > span {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${Typography.small_1}
    color:${Colors.gray6}
  }
  & > h1 {
    max-width: 100%;
    margin-top: 8px;
    ${Typography.title_44};
    color: ${Colors.gray9};

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  & > h2 {
    ${Typography.body_28};
    color: ${Colors.gray8};
    margin-top: 28px;

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
